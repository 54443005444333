import { TextField } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { CountryDropdown } from './CountryDropdown';

export const AddressFields: React.FC<{ formik: any; addressAccessor: string }> = ({ formik, addressAccessor }) => {
  const { t } = useTranslation();
  return (
    <>
      <TextField
        fullWidth
        id="street"
        name={`${addressAccessor}.nameOfBuilding`}
        label={t('delivery.address.nameOfBuilding')}
        value={formik.values[addressAccessor]?.nameOfBuilding || ''}
        onChange={formik.handleChange}
        error={formik.touched[addressAccessor]?.nameOfBuilding && Boolean(formik.errors[addressAccessor]?.nameOfBuilding)}
        helperText={formik.touched[addressAccessor]?.nameOfBuilding && formik.errors[addressAccessor]?.nameOfBuilding}
      />
      <TextField
        fullWidth
        id="street"
        name={`${addressAccessor}.street`}
        label={t('delivery.address.street')}
        value={formik.values[addressAccessor]?.street}
        onChange={formik.handleChange}
        error={formik.touched[addressAccessor]?.street && Boolean(formik.errors[addressAccessor]?.street)}
        helperText={formik.touched[addressAccessor]?.street && formik.errors[addressAccessor]?.street}
      />
      <TextField
        fullWidth
        id="addressLine2"
        name={`${addressAccessor}.addressLine2`}
        label={t('delivery.address.addressLine2')}
        value={formik.values[addressAccessor]?.addressLine2}
        onChange={formik.handleChange}
        error={formik.touched[addressAccessor]?.addressLine2 && Boolean(formik.errors[addressAccessor]?.addressLine2)}
        helperText={formik.touched[addressAccessor]?.addressLine2 && formik.errors[addressAccessor]?.addressLine2}
      />
      <TextField
        fullWidth
        id="postcode"
        name={`${addressAccessor}.postcode`}
        label={t('delivery.address.postcode')}
        value={formik.values[addressAccessor]?.postcode}
        onChange={formik.handleChange}
        error={formik.touched[addressAccessor]?.postcode && Boolean(formik.errors[addressAccessor]?.postcode)}
        helperText={formik.touched[addressAccessor]?.postcode && formik.errors[addressAccessor]?.postcode}
      />
      <TextField
        fullWidth
        id="city"
        name={`${addressAccessor}.city`}
        label={t('delivery.address.city')}
        value={formik.values[addressAccessor]?.city}
        onChange={formik.handleChange}
        error={formik.touched[addressAccessor]?.city && Boolean(formik.errors[addressAccessor]?.city)}
        helperText={formik.touched[addressAccessor]?.city && formik.errors[addressAccessor]?.city}
      />
      <CountryDropdown formik={formik} addressAccessor={addressAccessor} />
    </>
  );
};
