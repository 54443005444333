import { MobileListItem } from './mobileListItem/MobileListItem';
import { ResponsiveDataGridProps } from '../responsiveDataGrid/ResponsiveDataGrid';
import { Pagination, Stack } from '@mui/material';
import { useMemo, useState } from 'react';

function paginate(array: readonly any[], page_size: number, page_number: number) {
  // human-readable page numbers usually start with 1, so we reduce 1 in the first argument
  return array.slice((page_number - 1) * page_size, page_number * page_size);
}
export const MobileList: React.FC<ResponsiveDataGridProps> = ({
  rows,
  columns,
  pageSize,
  onSelectionModelChange,
  disableSelectionOnClick,
}) => {
  const needsPagination = pageSize && rows.length > pageSize;
  const numberOfPages = Math.round(rows.length / (pageSize || 1));
  const [page, setPage] = useState(1);
  const visibleRows = useMemo(() => {
    if (!needsPagination) {
      return rows;
    }
    return paginate(rows, pageSize, page);
  }, [needsPagination, page, pageSize, rows]);

  const [selectedItem, setSelectedItem] = useState();

  const handleClick = (row: any) => {
    if (!disableSelectionOnClick && onSelectionModelChange) {
      onSelectionModelChange([row.id], {});
      setSelectedItem(row);
    }
  };
  return (
    <div>
      {visibleRows.map(r => (
        <MobileListItem isSelected={selectedItem === r} onClick={handleClick} key={r.id} row={r} columns={columns} />
      ))}
      {needsPagination && (
        <Stack alignItems="center">
          <Pagination sx={{ margin: 'auto' }} count={numberOfPages} page={page} onChange={(_, page) => setPage(page)} />
        </Stack>
      )}
    </div>
  );
};
