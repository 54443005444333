import { Box, TextField } from '@mui/material';
import * as yup from 'yup';
import { useFormik } from 'formik';
import { ReviewDeliveryDetailsForm, InfoRow, Separator } from './ReviewDeliveryDetails.styles';
import { useTranslation } from 'react-i18next';
import { useOrderWizardStore } from '../../../../../stores/orderWizardStore';
import shallow from 'zustand/shallow';
import { useCompaniesStore } from '../../../../../stores/companiesStore';
import { DatePicker, LocalizationProvider } from '@mui/lab';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import { formatAddress, formatDate } from '../../../../../services/utils/format';
import { SaveButton } from '../../common/SaveButton';
import { GeneralTermsAndConditionsLink } from '../../common/GeneralTermsAndConditionsLink';

export const ReviewDeliveryDetails: React.FC = () => {
  const { t } = useTranslation();

  const [order, saveOrder, setOrder] = useOrderWizardStore(s => [s.order, s.saveOrder, s.setOrder], shallow);

  const currentCompany = useCompaniesStore(s => s.selectedCompany);

  const requiredMessage = t('validations.required');

  const validationSchema = yup.object({
    actualDeliveryDate: yup.date().typeError(requiredMessage).required(requiredMessage),
    baswaComments: yup.string().required(requiredMessage),
    paymentTerms: yup.string().required(requiredMessage),
  });

  const initialValues = {
    actualDeliveryDate: (order?.actualDeliveryDate && new Date(order?.actualDeliveryDate)) || null,
    baswaComments: order?.baswaComments || '',
    paymentTerms: order?.paymentTerms || currentCompany?.paymentTerms || '',
  };

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: validationSchema,
    onSubmit: values => {
      setOrder({ ...order, ...values } as any);
      saveOrder('NotYetSigned');
    },
  });

  return order ? (
    <ReviewDeliveryDetailsForm onSubmit={formik.handleSubmit} id="order-wizard-review-form">
      <InfoRow>
        <div>{t('delivery.actualDeliveryDate')}</div>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <DatePicker
            minDate={new Date()}
            inputFormat={'dd. MMM. yyyy'}
            label={t('delivery.actualDeliveryDate')}
            value={formik.values.actualDeliveryDate}
            onChange={date => formik.setFieldValue('actualDeliveryDate', date)}
            renderInput={params => (
              <TextField
                fullWidth
                {...params}
                error={formik.touched.actualDeliveryDate && Boolean(formik.errors.actualDeliveryDate)}
                helperText={formik.touched.actualDeliveryDate && formik.errors.actualDeliveryDate}
              />
            )}
          />
        </LocalizationProvider>
      </InfoRow>
      <InfoRow>
        <div>{t('delivery.baswaComments')}</div>
        <TextField
          fullWidth
          multiline
          rows={5}
          id="baswaComments"
          name="baswaComments"
          label={t('common.comments')}
          value={formik.values.baswaComments}
          onChange={formik.handleChange}
          error={formik.touched.baswaComments && Boolean(formik.errors.baswaComments)}
          helperText={formik.touched.baswaComments && formik.errors.baswaComments}
        />
      </InfoRow>
      <InfoRow>
        <div>{t('orders.paymentTerms')}</div>
        <TextField
          fullWidth
          multiline
          rows={5}
          id="paymentTerms"
          name="paymentTerms"
          label={t('orders.paymentTerms')}
          value={formik.values.paymentTerms}
          onChange={formik.handleChange}
          error={formik.touched.paymentTerms && Boolean(formik.errors.paymentTerms)}
          helperText={formik.touched.paymentTerms && formik.errors.paymentTerms}
        />
      </InfoRow>
      <InfoRow>
        <div></div>
        <div>
          <GeneralTermsAndConditionsLink />
        </div>
      </InfoRow>
      <Box sx={{ textAlign: 'right' }}>
        <SaveButton onClick={() => setOrder({ ...order, ...formik.values } as any)} />
      </Box>
      <Separator />
      <InfoRow>
        <div>{t('delivery.requestedDeliveryDate')}</div>
        <div>{order.requestedDeliveryDate && formatDate(order.requestedDeliveryDate)}</div>
      </InfoRow>
      <InfoRow>
        <div>{t('delivery.shippingAddress')}</div>
        <div>{formatAddress(order?.shippingAddress)}</div>
      </InfoRow>
      <InfoRow>
        <div>{t('delivery.deliveryDetails')}</div>
        <div>{order?.deliveryDetails}</div>
      </InfoRow>
      <InfoRow>
        <div>{t('project.contactPerson')}</div>
        <div>
          {order?.contactFirstName} {order?.contactLastName}
        </div>
      </InfoRow>
      <InfoRow>
        <div>{t('common.phoneNumber')}</div>
        <div>{order?.phoneNumber}</div>
      </InfoRow>
      <InfoRow>
        <div>{t('common.email')}</div>
        <div>{order?.contactEmail}</div>
      </InfoRow>
    </ReviewDeliveryDetailsForm>
  ) : null;
};
