import ReactDOM from 'react-dom';
import { ThemeProvider } from 'styled-components';
import Confirmation from '../../components/core/confirmation/Confirmation';
import history from '../history/history';
import theme from '../styles/theme';

interface ConfirmAdditionalProps {
  title?: string;
  noLabel?: string;
  yesLabel?: string;
  checkboxConfirmationMessage?: string;
  choices?: { label: string; value: string }[];
}

const confirm = (message: string, additionalProps?: ConfirmAdditionalProps) => {
  const wrapper = document.body.appendChild(document.createElement('div'));
  function dispose() {
    setTimeout(() => {
      ReactDOM.unmountComponentAtNode(wrapper);
      unregisterHistoryChangeEventHandler();
      setTimeout(() => document.body.removeChild(wrapper));
    });
  }

  const unregisterHistoryChangeEventHandler = history.listen(dispose);

  const { title, yesLabel, noLabel, checkboxConfirmationMessage, choices } = additionalProps || {};

  const promise = new Promise<string | undefined>((resolve, reject) => {
    try {
      ReactDOM.render(
        <ThemeProvider theme={theme}>
          <Confirmation
            checkboxConfirmationMessage={checkboxConfirmationMessage}
            message={message}
            onReject={reject}
            onConfirm={resolve}
            title={title}
            yesLabel={yesLabel}
            noLabel={noLabel}
            choices={choices}
          />
        </ThemeProvider>,
        wrapper,
      );
    } catch (e) {
      throw e;
    }
  });

  return promise.then(
    (choice) => {
      dispose();
      return choice || true;
    },
    () => {
      dispose();
      return false;
    },
  );
};

export default confirm;
