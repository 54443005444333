import { Input } from '@mui/icons-material';
import { IconButton, TextField, Tooltip } from '@mui/material';
import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import shallow from 'zustand/shallow';
import { useIsTablet } from '../../../../../../hooks/useIsMobile';
import { OrderProduct } from '../../../../../../models/OrderProduct';
import { useCompaniesStore } from '../../../../../../stores/companiesStore';
import { useNotificationStore } from '../../../../../../stores/notificationStore';
import { useOrderWizardStore } from '../../../../../../stores/orderWizardStore';
import { useUserStore } from '../../../../../../stores/userStore';
import { Button } from '../../../../../core/button/Button';
import { ReactiveText } from '../../../../../core/Core.styles';
import { ImportProductsDialog } from '../../import/ImportProductsDialog';
import { ProductListEditableRow } from '../productListRow/ProductListRow';
import { ProductListCell, ProductListRow } from '../productListRow/ProductListRow.styles';
import {
  AddProductContainer,
  ProductListContainer,
  ProductListHeaderCell,
  ProductListHeaderRow,
  TotalContainer,
} from './ProductList.styles';
import { useProductsStore } from '../../../../../../stores/productStore';
import { debounce } from 'lodash-es';

interface IProductListProps {
  roomId?: string;
  showAllProducts?: boolean;
  readOnly?: boolean;
  showAdditionalProjectDiscount?: boolean;
  readonlyAdditionalProjectDiscount?: boolean;
  showColorConfirmation?: boolean;
  products?: OrderProduct[];
  onSelect?: (orderProduct: OrderProduct) => void;
  saveOrderOnProjectDiscountChange?: boolean;
  priceCustomizationEnabled?: boolean;
}

const ProductList: React.FunctionComponent<IProductListProps> = ({
  roomId,
  showAllProducts,
  readOnly,
  showAdditionalProjectDiscount,
  readonlyAdditionalProjectDiscount,
  showColorConfirmation,
  products,
  onSelect,
  priceCustomizationEnabled,
  saveOrderOnProjectDiscountChange,
}) => {
  const { t } = useTranslation();

  const [importProducts, setImportProducts] = useState(false);

  const isTablet = useIsTablet();

  const addNotification = useNotificationStore(s => s.addNotification);

  const [isAdmin, isSales] = useUserStore(s => [s.isAdmin, s.isSales], shallow);

  const [addNewProduct, setAddNewProduct] = useState(false);

  const productsMap = useProductsStore(s => s.productsMap);

  const [
    order,
    orderProductsFromStore,
    addProduct,
    removeProduct,
    onEditProduct,
    setOrder,
    saveOrder,
    uploadFile,
    isEditingProduct,
    setIsEditingProduct,
    pickFile,
  ] = useOrderWizardStore(
    s => [
      s.order,
      s.products,
      s.addProduct,
      s.removeProduct,
      s.editProduct,
      s.setOrder,
      s.saveOrder,
      s.uploadColorConfirmationFile,
      s.isEditingProduct,
      s.setIsEditingProduct,
      s.pickColorConfirmationFile,
    ],
    shallow,
  );

  const allProducts = products || orderProductsFromStore;

  const currentCompany = useCompaniesStore(s => s.selectedCompany);

  const vatPercentage = currentCompany?.vatPercentage || 0;

  const orderProducts = useMemo(
    () =>
      !showAllProducts
        ? // disable eqeqeq because we have null on room id
          // eslint-disable-next-line eqeqeq
          allProducts.filter(p => p.roomUiId == roomId)
        : allProducts,
    [allProducts, roomId, showAllProducts],
  );

  const discount = currentCompany?.standardDiscount || 0;

  const onAddProduct = (orderProduct: OrderProduct) => {
    addProduct(orderProduct);
    setAddNewProduct(false);
  };

  const onCancel = () => {
    setAddNewProduct(false);
    setIsEditingProduct(false);
  };

  const onDeleteProduct = (orderProduct?: OrderProduct) => {
    if (orderProduct) {
      removeProduct(orderProduct);
      addNotification({ color: 'success', message: t('product.productRemoved') });
      saveOrder();
    } else setAddNewProduct(false);
  };

  const productRowProps = { onAddProduct, onDeleteProduct, onEditProduct, onCancel };

  const gross = useMemo(
    () =>
      orderProducts.reduce((pv, cp) => {
        return pv + (cp.price || 0) * cp.quantity;
      }, 0),
    [orderProducts],
  );

  const discountableProducts = useMemo(
    () => orderProducts.filter(p => productsMap[p.productId].discountable),
    [orderProducts, productsMap],
  );

  const discountableGross = useMemo(
    () =>
      discountableProducts.reduce((pv, cp) => {
        return pv + (cp.price || 0) * cp.quantity;
      }, 0),
    [discountableProducts],
  );

  const nonDiscountableGross = gross - discountableGross;

  const net =
    nonDiscountableGross +
    (discountableGross / 100) *
      (100 - discount - (showAdditionalProjectDiscount ? order?.projectDiscountPercentage || 0 : 0));

  const vat = net * vatPercentage;

  const currency = order.currency;

  const handleUpload = (
    tintType: string,
    file: File,
    colorCode: string,
    adjacentCeiling: boolean,
    comments: string,
    product: OrderProduct,
  ) => {
    setAddNewProduct(false);
    setIsEditingProduct(false);
    return uploadFile(tintType, file, colorCode, adjacentCeiling, comments, undefined, product);
  };

  const handlePickFile = (
    tintType: string,
    url: string,
    colorCode: string,
    adjacentCeiling: boolean,
    comments: string,
    product: OrderProduct,
  ) => {
    setAddNewProduct(false);
    setIsEditingProduct(false);
    return pickFile(tintType, url, colorCode, adjacentCeiling, comments, undefined, product);
  };

  const saveOrderDebounced = useMemo(() => debounce(saveOrder, 1000), [saveOrder]);

  const handleChangeProjectDiscount = (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    let projectDiscountPercentage = +e.target.value;
    if (projectDiscountPercentage > 100 - discount) {
      projectDiscountPercentage = 100 - discount;
    }
    setOrder({ ...order, projectDiscountPercentage });
    if (saveOrderOnProjectDiscountChange) {
      saveOrderDebounced();
    }
  };

  const justifyContent = isTablet ? 'right' : 'left';

  return (
    <>
      <ProductListContainer>
        {!isTablet && (
          <ProductListHeaderRow>
            <ProductListHeaderCell $maxWidth="300px">{t('common.category')}</ProductListHeaderCell>
            <ProductListHeaderCell>{t('common.product')}</ProductListHeaderCell>
            <ProductListHeaderCell $maxWidth="120px">{t('product.totalUnits')}</ProductListHeaderCell>
            <ProductListHeaderCell $maxWidth="80px">{t('product.totalQty')}</ProductListHeaderCell>
            {!onSelect && (
              <ProductListHeaderCell $maxWidth={readOnly ? '120px' : '180px'}>
                {readOnly && t('product.price')}
              </ProductListHeaderCell>
            )}
          </ProductListHeaderRow>
        )}
        {orderProducts.map((p, i) => (
          <ProductListEditableRow
            showColorConfirmation={showColorConfirmation}
            defaultEditable={false}
            key={p.productId + i + p.id}
            readOnly={readOnly}
            roomId={roomId}
            {...productRowProps}
            orderProduct={p}
            onUploadConfirmationFile={handleUpload}
            onPickConfirmationFile={handlePickFile}
            onSelect={onSelect}
            priceCustomizationEnabled={priceCustomizationEnabled}
          />
        ))}
        {addNewProduct && (
          <ProductListEditableRow
            onUploadConfirmationFile={handleUpload}
            onPickConfirmationFile={handlePickFile}
            defaultEditable={true}
            roomId={roomId}
            key="newProduct"
            {...productRowProps}
          />
        )}
        {!readOnly && !onSelect && (
          <AddProductContainer>
            <Button
              disabled={addNewProduct || isEditingProduct}
              onClick={() => {
                setAddNewProduct(true);
                setIsEditingProduct(true);
              }}
            >
              {t('product.addProduct')}
            </Button>
            <Tooltip title={t('orders.fromPreviousOrder') as string}>
              <IconButton color="primary" disabled={addNewProduct} onClick={() => setImportProducts(true)}>
                <Input />
              </IconButton>
            </Tooltip>
          </AddProductContainer>
        )}
        {readOnly && (
          <>
            <TotalContainer noBorder>
              <ProductListCell justifyContent={justifyContent}>{t('common.grossAmount')}</ProductListCell>
              <ProductListCell justifyContent={justifyContent} $maxWidth="120px">
                {currency} {Number(gross.toFixed(2)).toLocaleString('de-CH', { minimumFractionDigits: 2 })}
              </ProductListCell>
            </TotalContainer>
            {(!!discount || (showAdditionalProjectDiscount && !!order.projectDiscountPercentage)) && (
              <>
                {!!nonDiscountableGross && (
                  <ProductListRow noBorder>
                    <ProductListCell justifyContent={justifyContent}>
                      {t('common.totalDiscountableProducts')}
                    </ProductListCell>
                    <ProductListCell justifyContent={justifyContent} $maxWidth="120px">
                      {currency}{' '}
                      {Number((gross - nonDiscountableGross).toFixed(2)).toLocaleString('de-CH', {
                        minimumFractionDigits: 2,
                      })}
                    </ProductListCell>
                  </ProductListRow>
                )}
                {!!discount && (
                  <ProductListRow noBorder>
                    <ProductListCell justifyContent={justifyContent}>{t('common.discount')}</ProductListCell>
                    <ProductListCell justifyContent={justifyContent} $maxWidth="120px">
                      {discount} %
                    </ProductListCell>
                  </ProductListRow>
                )}
              </>
            )}
            {showAdditionalProjectDiscount && (!!order.projectDiscountPercentage || isAdmin || isSales) && (
              <ProductListRow noBorder>
                <ProductListCell justifyContent={justifyContent}>
                  {t('project.additionalProjectDiscount')}
                </ProductListCell>
                <ProductListCell justifyContent={justifyContent} $maxWidth="120px">
                  {(isAdmin || isSales) && !readonlyAdditionalProjectDiscount ? (
                    <div>
                      <TextField
                        size="small"
                        type="number"
                        value={order.projectDiscountPercentage || 0}
                        onChange={handleChangeProjectDiscount}
                        InputProps={{
                          inputProps: { min: 0, max: 100 - discount },
                          endAdornment: <ReactiveText isActive={true}>%</ReactiveText>,
                        }}
                      />
                    </div>
                  ) : (
                    <div>{order.projectDiscountPercentage || 0} %</div>
                  )}
                </ProductListCell>
              </ProductListRow>
            )}

            <ProductListRow>
              <ProductListCell justifyContent={justifyContent}>{t('common.netAmount')}</ProductListCell>
              <ProductListCell justifyContent={justifyContent} $maxWidth="120px">
                {currency} {Number(net.toFixed(2)).toLocaleString('de-CH', { minimumFractionDigits: 2 })}
              </ProductListCell>
            </ProductListRow>

            {!!vatPercentage && (
              <>
                <ProductListRow noBorder>
                  <ProductListCell justifyContent={justifyContent}>{t('common.vat')}</ProductListCell>
                  <ProductListCell justifyContent={justifyContent} $maxWidth="120px">
                    {currency} {Number(vat.toFixed(2)).toLocaleString('de-CH', { minimumFractionDigits: 2 })}
                  </ProductListCell>
                </ProductListRow>

                <ProductListRow noBorder>
                  <ProductListCell justifyContent={justifyContent}>
                    {t('common.netAmount')} + {t('common.vat')}
                  </ProductListCell>
                  <ProductListCell justifyContent={justifyContent} $maxWidth="120px">
                    {currency} {Number((net + vat).toFixed(2)).toLocaleString('de-CH', { minimumFractionDigits: 2 })}
                  </ProductListCell>
                </ProductListRow>
              </>
            )}
          </>
        )}
      </ProductListContainer>
      {importProducts && <ImportProductsDialog roomUiId={roomId} onClose={() => setImportProducts(false)} />}
    </>
  );
};

export default ProductList;
