import { Dictionary } from 'lodash';
import uniq from 'lodash-es/uniq';
import create from 'zustand';
import { Product } from '../models/Product';
import { getProducts } from '../services/http/products';

interface ProductsStoreValues {
  productCategories: string[];
  products: Product[];
  productsMap: Dictionary<Product>;
  productsLoaded: boolean;
}

interface ProductsStoreActions {
  loadProducts: (companyId: string) => void;
  reset: () => void;
}

const initialValues = {
  productCategories: [],
  products: [],
  productsLoaded: false,
  productsMap: {},
};

export const useProductsStore = create<ProductsStoreValues & ProductsStoreActions>(set => ({
  ...initialValues,
  loadProducts: companyId => {
    getProducts(companyId).then(products => {
      const productsMap: Dictionary<Product> = {};
      const productCategories = uniq(
        products.map(p => {
          productsMap[p.itemNo] = p;
          return p.category;
        }),
      ).sort();
      set({ productCategories, products, productsLoaded: true, productsMap });
    });
  },
  reset: () => set({ ...initialValues }),
}));
