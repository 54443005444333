import create from 'zustand';
import { Company } from '../models/Company';
import { getCompanies, getCompanyColorConfirmations } from '../services/http/companies';
import { useProjectsStore } from './projectsStore';
import { ColorConfirmationFile } from '../models/CompanyFile';

interface CompaniesStoreValues {
  companiesLoaded: boolean;
  companies: Company[];
  selectedCompany?: Company;
  colorConfirmations: ColorConfirmationFile[];
  isLoadingColorConfirmations: boolean;
}

interface CompaniesStoreActions {
  loadCompanies: () => void;
  setSelectedCompany: (companyId?: string) => void;
  loadCompanyColorConfirmations: (companyId: string) => void;
}

const defaultValues: CompaniesStoreValues = {
  companiesLoaded: false,
  companies: [],
  selectedCompany: undefined,
  colorConfirmations: [],
  isLoadingColorConfirmations: false,
};

export const useCompaniesStore = create<CompaniesStoreValues & CompaniesStoreActions>((set, get) => ({
  ...defaultValues,
  setSelectedCompany: companyId => {
    set({ selectedCompany: get().companies.find(c => c.companyId === companyId) });
    if (companyId) {
      useProjectsStore.getState().loadProjects(companyId);
    }
  },
  loadCompanies: () => {
    getCompanies().then(companies => {
      set({ companies, companiesLoaded: true });
      if (companies.length === 1) {
        get().setSelectedCompany(companies[0].companyId);
      }
    });
  },
  loadCompanyColorConfirmations: companyId => {
    set({ isLoadingColorConfirmations: true });
    getCompanyColorConfirmations(companyId)
      .then(colorConfirmations => set({ colorConfirmations }))
      .finally(() => set({ isLoadingColorConfirmations: false }));
  },
}));
