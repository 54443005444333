import { MenuItem, TextField, TextFieldProps } from '@mui/material';

interface Option<T = any> {
  value: string | number;
  label: string | number;
  extendedValue?: T;
}

interface Props<T> {
  native?: boolean;
  options: Option<T>[];
  renderMenuItem?: (option: Option<T>) => JSX.Element;
}

export function Dropdown<T = any>({ options, renderMenuItem, ...props }: Props<T> & TextFieldProps) {
  return (
    <TextField {...props} select>
      {options.map(o =>
        props.native ? (
          <option key={o.value} value={o.value}>
            {o.label}
          </option>
        ) : (
          <MenuItem key={o.value} value={o.value}>
            {renderMenuItem ? renderMenuItem(o) : o.label}
          </MenuItem>
        ),
      )}
    </TextField>
  );
}
