import { useTranslation } from 'react-i18next';
import { Box, CircularProgress, IconButton, Tooltip } from '@mui/material';
import { Download } from '@mui/icons-material';
import { useCallback, useState } from 'react';
import { downloadOrder } from '../../../../services/http/orders';
import { useOrderWizardStore } from '../../../../stores/orderWizardStore';
import { useUserStore } from '../../../../stores/userStore';

export const DownloadOrderButton: React.FC = () => {
  const { t } = useTranslation();

  const orderId = useOrderWizardStore(s => s.order.id);

  const language = useUserStore(s => s.language);

  const [isLoading, setIsLoading] = useState(false);

  const exportOrder = useCallback(() => {
    if (orderId) {
      setIsLoading(true);
      downloadOrder(orderId, language).finally(() => setIsLoading(false));
    }
  }, [orderId, language]);

  return (
    <Box sx={{ marginRight: 1 }}>
      <Tooltip title={t('orders.downloadOrder') as string}>
        {isLoading ? (
          <CircularProgress color="secondary" />
        ) : (
          <IconButton onClick={exportOrder}>
            <Download />
          </IconButton>
        )}
      </Tooltip>
    </Box>
  );
};
