import { useMemo, useState } from 'react';

import { MoreVert } from '@mui/icons-material';
import { Box, Checkbox, IconButton } from '@mui/material';
import Dialog from '@mui/material/Dialog';

import { MobileListColumnLocations } from '../../../../models/MobileListColumnLocations';
import { ResponsiveGridColumns, ResponsiveGridColumn } from '../../../../models/ResponsiveGridColumn';

import {
  ActionsContainer,
  ContentContainer,
  Header,
  InlineActionsContainer,
  MobileListItemContainer,
  Subtitle,
  Title,
  TitleContainer,
  TopLeftDetail,
  TopLeftDetailsContainer,
} from './MobileListItem.styles';

const getCheckContent = (isSelected: boolean) => <Checkbox key="check" checked={isSelected} />;

const getRowContent = (row: any, field: ResponsiveGridColumn) => {
  if (field.renderMobileCell) {
    return field.renderMobileCell({
      id: row.id,
      row,
      field,
      value: row[field.field],
      formattedValue: row[field.field],
    });
  }
  return field.renderCell
    ? field.renderCell({ id: row.id, row, field, value: row[field.field], formattedValue: row[field.field] } as any)
    : row[field.field];
};

export const MobileListItem: React.FC<{
  row: any;
  columns: ResponsiveGridColumns;
  onClick: (row: any) => void;
  isSelected: boolean;
}> = ({ row, columns, onClick, isSelected }) => {
  const columnsMap = useMemo(() => {
    const map: { [key in MobileListColumnLocations]: ResponsiveGridColumns } = {
      check: [],
      title: [],
      subtitle: [],
      content: [],
      counts: [],
      hidden: [],
      actions: [],
      topRightDetail: [],
      inlineActions: [],
    };
    columns.forEach(c => map[c.mobileLocation || 'hidden'].push(c));
    return map;
  }, [columns]);

  const [isActionsModalOpen, setIsActionsModalOpen] = useState(false);

  const hasActions = !!columnsMap.actions.length;

  return (
    <>
      <MobileListItemContainer isSelected={isSelected} onClick={() => onClick(row)}>
        {columnsMap.check.map(c => getCheckContent(isSelected))}
        <Box sx={{ flex: 1, maxWidth: hasActions ? 'calc(100% - 30px)' : '100%' }}>
          <Header>
            <TitleContainer>
              {columnsMap.title.map(t => (
                <Title key={t.field}>{getRowContent(row, t)}</Title>
              ))}
              {columnsMap.subtitle.map(t => (
                <Subtitle key={t.field}>{getRowContent(row, t)}</Subtitle>
              ))}
            </TitleContainer>
            <TopLeftDetailsContainer>
              {columnsMap.topRightDetail.map(t => (
                <TopLeftDetail key={t.field}>{getRowContent(row, t)}</TopLeftDetail>
              ))}
            </TopLeftDetailsContainer>
          </Header>
          <ContentContainer>
            {columnsMap.content.map(c => (row[c.field] ? <div key={c.field}>{getRowContent(row, c)}</div> : null))}
          </ContentContainer>
          {!!columnsMap.inlineActions.length && (
            <InlineActionsContainer>
              {columnsMap.inlineActions.map(c => (
                <div key={c.field}>{getRowContent(row, c)}</div>
              ))}
            </InlineActionsContainer>
          )}
        </Box>

        {hasActions && (
          <ActionsContainer>
            <IconButton
              size="small"
              onClick={columnsMap.actions.length ? () => setIsActionsModalOpen(true) : undefined}
            >
              <MoreVert fontSize="small" />
            </IconButton>
          </ActionsContainer>
        )}
      </MobileListItemContainer>
      {isActionsModalOpen && (
        <Dialog open onClose={() => setIsActionsModalOpen(false)}>
          {columnsMap.actions.map((c: any) => c.getActions && c.getActions({ row, id: row.id }))}
        </Dialog>
      )}
    </>
  );
};
