import { useTranslation } from 'react-i18next';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { Box, Fade, FormControlLabel, Slider, Switch, TextField } from '@mui/material';
import {
  ButtonsContainer,
  Form,
  FormSectionsContainer,
  FormTitle,
  WastageBox,
  WastageContainer,
} from './RoomForm.styles';
import { Dropdown } from '../../../../../core/dropdown/Dropdown';
import { ReactiveText } from '../../../../../core/Core.styles';
import { Button } from '../../../../../core/button/Button';
import InfoButton from '../../../../../core/infoButton/InfoButton';
import { Room } from '../../../../../../models/Room';
import { useCallback, useEffect, useLayoutEffect, useRef, useState } from 'react';
import { useOrderWizardStore } from '../../../../../../stores/orderWizardStore';
import shallow from 'zustand/shallow';
import confirm from '../../../../../../services/confirm/confirm';
import { useRoomsStore } from '../../../../../../stores/roomOptionsStore';
import { TechnicalValue } from '../../../../../../models/TechnicalValue';
import { tintTypes } from '../../../../../../constants/tintTypes';
import { ColorAgreementInfoBox } from '../../../common/colorAgreement/ColorAgreementInfoBox';
import { useIsTablet } from '../../../../../../hooks/useIsMobile';

const getOption = (option: TechnicalValue) => ({ value: option.key, label: option.value });

export const RoomForm: React.FC<{ room: Room; roomIndex: number }> = ({ room, roomIndex }) => {
  const { t } = useTranslation();

  const [updateRoom, deleteRoom, uploadColorConfirmation, products, pickColorConfirmationFile] = useOrderWizardStore(
    s => [s.updateRoom, s.deleteRoom, s.uploadColorConfirmationFile, s.products, s.pickColorConfirmationFile],
    shallow,
  );

  const [computeRoomOrderProducts] = useRoomsStore(s => [s.computeRoomOrderProducts], shallow);

  const roomOptions = useRoomsStore(s => s.roomOptions);

  const isTablet = useIsTablet();

  const inputSize = 'small';

  const [needsAgreement, setNeedsAgreement] = useState(room.tintType !== tintTypes.standard);

  const validationSchema = yup.object({
    name: yup.string().required(t('validations.required')),
    type: yup.string(),
    surface: yup.number().required(t('validations.required')),
    wastage: yup.number().required(t('validations.required')),
    substrate: yup.string().required(t('validations.required')),
    system: yup.string().required(t('validations.required')),
    thickness: yup.string().required(t('validations.required')),
    coatType: yup.string().required(t('validations.required')),
    tintType: yup.string().required(t('validations.required')),
    fireResistant: yup.bool().optional(),
    colorAgreementUrl: needsAgreement ? yup.string().required(t('validations.required')) : yup.string().nullable(),
    colorCode: needsAgreement ? yup.string().required(t('validations.required')) : yup.string().nullable(),
  });

  const formik = useFormik({
    validationSchema,
    enableReinitialize: true,
    initialValues: room,
    onSubmit: async formValues => {
      updateRoom({ ...formValues, id: room.id }, roomIndex);
      if (products.some(p => p.roomUiId === room.uiId)) {
        const replace = 'replace';
        const action = await confirm(t('rooms.productsAlreadyInRoomInfo'), {
          title: t('rooms.productsAlreadyInRoom'),
          choices: [
            { label: t('rooms.replaceProductsInRoom'), value: replace },
            { label: t('rooms.appendProductsInRoom'), value: 'append' },
          ],
          yesLabel: t('common.proceed'),
          noLabel: t('common.cancel'),
        });
        if (action) {
          computeRoomOrderProducts(formValues, action === replace);
          setTouchedFields([]);
        }
      } else {
        computeRoomOrderProducts(formValues, false);
        setTouchedFields([]);
      }
    },
  });

  const [touchedFields, setTouchedFields] = useState<string[]>([]);

  const addTouchedField = (field: string) => {
    setTouchedFields([...touchedFields, field]);
  };

  const handleChange = (e: React.ChangeEvent<any> | Event) => {
    formik.handleChange(e);
    addTouchedField(e.target.name);
  };

  useEffect(() => {
    setNeedsAgreement(formik.values.tintType !== tintTypes.standard);
  }, [formik.values.tintType]);

  const selectedRoom = roomOptions.find(o => o.panel.key === formik.values.system);

  const resetColorAgreement = useCallback(() => {
    formik.setFieldValue('colorAgreementUrl', '');
    formik.setFieldValue('colorCode', '');
  }, [formik]);

  const wastageBox = (
    <WastageBox>
      <WastageContainer>
        <ReactiveText isActive={true}>
          {t('rooms.withWastage', { wastage: formik.values.wastage })} ={' '}
          {+formik.values.surface + (formik.values.surface / 100) * formik.values.wastage} {t('orders.sqm')}
        </ReactiveText>
        <InfoButton content={t('rooms.withWastageInfo')} />
      </WastageContainer>

      <Slider
        size={inputSize}
        name="wastage"
        max={30}
        defaultValue={0}
        value={formik.values.wastage}
        onChange={handleChange}
        valueLabelFormat={value => value + '%'}
        valueLabelDisplay="auto"
      />
    </WastageBox>
  );

  const [isCalculateVisible, setIsCalculateVisible] = useState(true);
  const [runCalculateAnimation, setRunCalculateAnimation] = useState(false);

  const calculateAnimationIntervalId = useRef<number | null>(null);
  const dirtyAnimationIntervalId = useRef<number | null>(null);

  useLayoutEffect(() => {
    if (runCalculateAnimation && !calculateAnimationIntervalId.current) {
      let times = 0;
      calculateAnimationIntervalId.current = window.setInterval(() => {
        if (times < 6) {
          setIsCalculateVisible(!!(times % 2));
          times++;
        } else {
          if (calculateAnimationIntervalId.current) {
            clearInterval(calculateAnimationIntervalId.current);
            calculateAnimationIntervalId.current = null;
            setRunCalculateAnimation(false);
          }
        }
      }, 200);
    }
  }, [runCalculateAnimation]);

  const runIntermittentAnimation = () => {
    if (!dirtyAnimationIntervalId.current) {
      setRunCalculateAnimation(true);
      dirtyAnimationIntervalId.current = window.setInterval(() => setRunCalculateAnimation(true), 5000);
    }
  };

  const cleanIntermittentAnimation = () => {
    if (dirtyAnimationIntervalId.current) {
      clearInterval(dirtyAnimationIntervalId.current);
      dirtyAnimationIntervalId.current = null;
    }
  };

  const handleUpload = (file: File, colorCode: string, adjacentCeiling: boolean, comments: string) =>
    uploadColorConfirmation(formik.values.tintType, file, colorCode, adjacentCeiling, comments, room.uiId).then(url => {
      formik.setFieldValue('colorAgreementUrl', url);
      formik.setFieldValue('colorCode', colorCode);
      formik.setFieldValue('comments', comments);
      formik.setFieldValue('adjacentCeiling', adjacentCeiling);
      addTouchedField('colorAgreementUrl');
    });

  const handlePickFile = (url: string, colorCode: string, adjacentCeiling: boolean, comments: string) =>
    pickColorConfirmationFile(formik.values.tintType, url, colorCode, adjacentCeiling, comments, room.uiId).then(
      url => {
        formik.setFieldValue('colorAgreementUrl', url);
        formik.setFieldValue('colorCode', colorCode);
        formik.setFieldValue('comments', comments);
        formik.setFieldValue('adjacentCeiling', adjacentCeiling);
        addTouchedField('colorAgreementUrl');
      },
    );

  useEffect(() => {
    if (formik.dirty && touchedFields.some(f => f !== 'name' && f !== 'type')) {
      runIntermittentAnimation();
    } else {
      cleanIntermittentAnimation();
    }
  }, [formik.dirty, touchedFields]);

  return (
    <Form onSubmit={formik.handleSubmit}>
      <FormTitle>{t('rooms.roomConfiguration')}</FormTitle>
      <FormSectionsContainer>
        <TextField
          size={inputSize}
          fullWidth
          name="name"
          label={t('rooms.roomName')}
          value={formik.values.name}
          onChange={handleChange}
          error={formik.touched.name && Boolean(formik.errors.name)}
          helperText={formik.touched.name && formik.errors.name}
        />
        <TextField
          size={inputSize}
          fullWidth
          name="type"
          label={t('rooms.roomType')}
          value={formik.values.type}
          onChange={handleChange}
          error={formik.touched.type && Boolean(formik.errors.type)}
          helperText={formik.touched.type && formik.errors.type}
        />
        <Box sx={{ display: 'flex' }}>
          <TextField
            fullWidth={isTablet}
            sx={{ width: { md: 120 }, marginRight: { md: 2 } }}
            size={inputSize}
            name="surface"
            label={t('rooms.roomSurface')}
            value={formik.values.surface}
            onChange={handleChange}
            error={formik.touched.surface && Boolean(formik.errors.surface)}
            helperText={formik.touched.surface && formik.errors.surface}
            type="number"
            InputProps={{
              endAdornment: <ReactiveText isActive={false}>sqm</ReactiveText>,
            }}
          />
          {!isTablet && wastageBox}
        </Box>
        {isTablet && wastageBox}
        <Dropdown
          size={inputSize}
          fullWidth
          name="system"
          label={t('rooms.acousticPanel')}
          options={roomOptions.map(r => getOption(r.panel))}
          error={formik.touched.system && Boolean(formik.errors.system)}
          helperText={formik.touched.system && formik.errors.system}
          value={formik.values.system}
          onChange={e => {
            const selectedPanel = roomOptions.find(r => r.panel.key === e.target.value);
            formik.setValues({
              ...formik.values,
              system: e.target.value as any,
              substrate: selectedPanel?.substrate.find(s => s.key === formik.values.substrate)?.key || '',
              coatType: selectedPanel?.coatingSystems.find(s => s.key === formik.values.system)?.key || '',
              thickness: selectedPanel?.thicknesses.find(s => s.key === formik.values.thickness)?.key || '',
            });
            addTouchedField('system');
          }}
        />
        <Dropdown
          disabled={!formik.values.system}
          size={inputSize}
          fullWidth
          name="thickness"
          label={t('rooms.systemThickness')}
          options={selectedRoom?.thicknesses.map(getOption) || []}
          error={formik.touched.thickness && Boolean(formik.errors.thickness)}
          helperText={formik.touched.thickness && formik.errors.thickness}
          value={formik.values.thickness}
          onChange={handleChange}
        />
        <Dropdown
          disabled={!formik.values.system}
          size={inputSize}
          fullWidth
          name="substrate"
          label={t('rooms.substrate')}
          options={selectedRoom?.substrate.map(getOption) || []}
          error={formik.touched.substrate && Boolean(formik.errors.substrate)}
          helperText={formik.touched.substrate && formik.errors.substrate}
          value={formik.values.substrate}
          onChange={handleChange}
        />

        <Dropdown
          disabled={!formik.values.system}
          size={inputSize}
          fullWidth
          name="coatType"
          label={t('rooms.coatingSystem')}
          options={selectedRoom?.coatingSystems.map(getOption) || []}
          error={formik.touched.coatType && Boolean(formik.errors.coatType)}
          helperText={formik.touched.coatType && formik.errors.coatType}
          value={formik.values.coatType}
          onChange={handleChange}
        />

        <Dropdown
          size={inputSize}
          fullWidth
          name="tintType"
          label={t('rooms.tint')}
          options={[
            { value: tintTypes.standard, label: t('product.standardWhite') as string },
            { value: tintTypes.tintedWithConfirmation, label: t('product.tintedWithConfirmation') as string },
            { value: tintTypes.tintedWithoutConfirmation, label: t('product.tintedWithoutConfirmation') as string },
          ]}
          value={formik.values.tintType}
          onChange={async e => {
            if ((e.target.value as any) === tintTypes.tintedWithoutConfirmation) {
              if (
                await confirm(t('rooms.tintedWithoutConfirmationWarning'), {
                  checkboxConfirmationMessage: t('rooms.tintedWithoutConfirmationConfirm'),
                })
              ) {
                handleChange(e);
                resetColorAgreement();
              }
            } else {
              handleChange(e);
              resetColorAgreement();
            }
          }}
        />

        {formik.values.system === 'Panel_Cool' && (
          <div>
            <FormControlLabel
              sx={{ marginLeft: 'auto' }}
              control={
                <Switch
                  value={formik.values.fireResistant}
                  onChange={e => {
                    formik.setFieldValue('fireResistant', e.target.checked);
                    addTouchedField('fireResistant');
                  }}
                />
              }
              label={t('product.fireResistant') as any}
              labelPlacement="end"
            />
          </div>
        )}
        {needsAgreement && (
          <ColorAgreementInfoBox
            onPickFile={handlePickFile}
            onUpload={handleUpload}
            mode={formik.values.tintType as any}
            colorCode={formik.values.colorCode}
            uploadedFileUrl={formik.values.colorAgreementUrl}
            onRemoveFile={resetColorAgreement}
          />
        )}
      </FormSectionsContainer>
      <ButtonsContainer>
        <Button
          onClick={async () => {
            if (await confirm(t('rooms.confirmDelete'))) deleteRoom(roomIndex);
          }}
          color="warning"
          variant="outlined"
        >
          {t('common.delete')}
        </Button>
        <Fade in={isCalculateVisible}>
          <div>
            <Button type="submit">{t('rooms.calculate')}</Button>
          </div>
        </Fade>
      </ButtonsContainer>
    </Form>
  );
};
