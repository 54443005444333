import styled from 'styled-components';

export const QuoteByRoomControlContainer = styled.div`
  display: flex;
  margin-bottom: ${p => p.theme.spacing(2)};
`;

export const QuoteByRoomContainer = styled.div`
  margin-bottom: ${p => p.theme.spacing(4)};
`;

export const QuoteByRoomContainerTitle = styled.div`
  font-size: 1.5rem;
  padding-left: ${p => p.theme.spacing(1)};
`;
