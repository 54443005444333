import { useEffect } from 'react';
import shallow from 'zustand/shallow';
import { useOrderWizardStore, WizardStepComponent } from '../../../stores/orderWizardStore';

interface IWizardStepNavigatorProps {
  step: number;
  children: React.ReactNode;
}

const WizardStepNavigator: React.FC<IWizardStepNavigatorProps> = ({ step, children }) => {
  const setActiveStep = useOrderWizardStore(s => s.setActiveStep);
  useEffect(() => {
    setActiveStep(step);
  }, [setActiveStep, step]);
  return <>{children}</>;
};

export const withWizardNavigator =
  (Component: React.FC): WizardStepComponent =>
  ({ step, suitableStatuses }) => {
    const [order, steps] = useOrderWizardStore(s => [s.order, s.steps], shallow);
    const isSuitable = !order.status || suitableStatuses.includes(order.status);
    let suitableStep = step;
    if (!isSuitable && order.status) {
      suitableStep = steps.findIndex(s => s.suitableStatuses.includes(order.status!));
    }
    return (
      <WizardStepNavigator step={suitableStep}>
        <Component />
      </WizardStepNavigator>
    );
  };

export default WizardStepNavigator;
