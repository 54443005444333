import create from 'zustand';

interface Notification {
  color: 'success' | 'warning' | 'error';
  message: string;
  persist?: boolean;
  details?: string;
}

interface NotificationStore {
  notification?: Notification;
  addNotification: (notification: Notification) => void;
  removeNotification: () => void;
}

export const useNotificationStore = create<NotificationStore>(set => ({
  addNotification: notification => set({ notification }),
  removeNotification: () => set({ notification: undefined }),
}));
