import {
  getAuthRedirectURL,
  getMsalAuthority,
  getMsalClientId,
  getMsalLoginRequestScope,
} from '../environment/environment';

export const msalConfig = {
  auth: {
    clientId: getMsalClientId(),
    authority: getMsalAuthority(),
    redirectUri: getAuthRedirectURL(),
    postLogoutRedirectUri: getAuthRedirectURL(),
  },
  cache: {
    cacheLocation: 'sessionStorage',
    storeAuthStateInCookie: false,
  },
};

export const loginRequest = {
  scopes: [`api://${getMsalLoginRequestScope()}/Invoke`],
};
