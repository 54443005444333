import styled from 'styled-components';

export const OrderConfirmedContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  max-width: 800px;
  font-size: 1rem;
  margin: auto;
  text-align: center;
  padding: ${p => p.theme.spacing(2)};

  svg {
    height: 50px;
    width: 50px;
    color: ${p => p.theme.palette.success.main};
  }
`;
