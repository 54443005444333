import { Delete, Edit } from '@mui/icons-material';
import * as yup from 'yup';
import { Box, Checkbox, FormControlLabel, Link, Stack, TextField } from '@mui/material';
import React, { useMemo, useState, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { OrderProduct } from '../../../../../../models/OrderProduct';
import confirm from '../../../../../../services/confirm/confirm';
import { useOrderWizardStore } from '../../../../../../stores/orderWizardStore';
import { useProductsStore } from '../../../../../../stores/productStore';
import { Button } from '../../../../../core/button/Button';
import { ReactiveText } from '../../../../../core/Core.styles';
import { Dropdown } from '../../../../../core/dropdown/Dropdown';
import {
  ConfigurationEntryContainer,
  ConfirmationContainer,
  NoColorWarning,
  ProductListCell as Cell,
  ProductListRow,
  ProductQuantityWrapper,
  UnitOfMeasureContainer,
} from './ProductListRow.styles';
import { ProductSelectItem } from './ProductSelectItem';
import { useFormik } from 'formik';
import { Product } from '../../../../../../models/Product';
import { CalculatePopover } from './CalculatePopover';
import { useIsTablet } from '../../../../../../hooks/useIsMobile';
import shallow from 'zustand/shallow';
import { tintTypes } from '../../../../../../constants/tintTypes';
import { ColorAgreementInfoBox } from '../../../common/colorAgreement/ColorAgreementInfoBox';
import { useUserStore } from '../../../../../../stores/userStore';
import { EditPriceDialog } from './EditPriceDialog';
import InfoButton from '../../../../../core/infoButton/InfoButton';

export const ProductListEditableRow: React.FC<{
  showColorConfirmation?: boolean;
  defaultEditable: boolean;
  orderProduct?: OrderProduct;
  roomId?: string;
  onCancel: () => void;
  onAddProduct: (orderProduct: OrderProduct) => void;
  onDeleteProduct: (orderProduct?: OrderProduct) => void;
  onEditProduct: (oldOrderProduct: OrderProduct, newOrderProduct: OrderProduct) => void;
  onUploadConfirmationFile: (
    tinType: string,
    file: File,
    colorCode: string,
    adjacentCeiling: boolean,
    comments: string,
    row: OrderProduct,
  ) => Promise<string>;
  onPickConfirmationFile: (
    tinType: string,
    url: string,
    colorCode: string,
    adjacentCeiling: boolean,
    comments: string,
    row: OrderProduct,
  ) => Promise<string>;
  readOnly?: boolean;
  onSelect?: (orderProduct: OrderProduct) => void;
  priceCustomizationEnabled?: boolean;
}> = ({
  onCancel,
  orderProduct,
  onAddProduct,
  onDeleteProduct,
  onEditProduct,
  roomId,
  readOnly,
  defaultEditable,
  showColorConfirmation,
  onUploadConfirmationFile,
  onSelect,
  priceCustomizationEnabled,
  onPickConfirmationFile,
}) => {
  const { t } = useTranslation();
  const [isEditable, setIsEditable] = useState(defaultEditable || orderProduct?.defaultEditable);
  const [categories, products, productsMap] = useProductsStore(s => [s.productCategories, s.products, s.productsMap]);

  const [rooms, saveOrder, isEditingProduct, setIsEditingProduct] = useOrderWizardStore(
    s => [s.rooms, s.saveOrder, s.isEditingProduct, s.setIsEditingProduct],
    shallow,
  );

  const isAdmin = useUserStore(s => s.isAdmin);

  const product = orderProduct?.productId ? productsMap[orderProduct.productId] : undefined;
  const [selectedProduct, setSelectedProduct] = useState<Product | undefined>(product);

  const validationSchema = yup.object({
    category: yup.string().required(t('validations.required')),
    productId: yup.string().required(t('validations.required')),
    quantity: yup.number().required(t('validations.required')),
    tintType: selectedProduct?.tinted ? yup.string().required(t('validations.required')) : yup.string().nullable(),
    colorAgreementUrl: selectedProduct?.tinted
      ? yup.string().required(t('validations.required'))
      : yup.string().nullable(),
    colorCode: selectedProduct?.tinted ? yup.string().required(t('validations.required')) : yup.string().nullable(),
    specialInstructions: selectedProduct?.customizable
      ? yup.string().required(t('validations.required'))
      : yup.string().nullable(),
  });

  const room = useMemo(() => rooms.find(r => r.uiId === roomId), [roomId, rooms]);

  const initialValues = useMemo(() => {
    return orderProduct
      ? { ...orderProduct, category: product?.category }
      : {
          category: '',
          productId: '',
          roomUiId: roomId,
          quantity: 1,
          quantitySqm: 0,
          quantityLm: 0,
          price: 0,
          tintType: product?.tinted ? tintTypes.tintedWithConfirmation : null,
        };
  }, [orderProduct, product, roomId]);

  const formik = useFormik<OrderProduct & { category?: string }>({
    enableReinitialize: true,
    initialValues,
    validationSchema: validationSchema,
    onSubmit: values => {
      const product: OrderProduct = {
        ...values,
        id: orderProduct && orderProduct.productId !== values.productId ? undefined : values.id,
      };
      orderProduct ? onEditProduct(orderProduct, product) : onAddProduct(product);
      setIsEditable(false);
      setIsEditingProduct(false);
      saveOrder('Open');
    },
  });

  const cancel = () => {
    if (orderProduct) {
      formik.setValues(initialValues);
      setIsEditable(false);
    }
    onCancel();
  };

  const selectedCategory = formik.values.category;

  const productsOptions = useMemo(
    () =>
      products
        .filter(s => s.category === selectedCategory)
        .map(p => ({ value: p.itemNo, label: p.name, extendedValue: p })),
    [products, selectedCategory],
  );

  const showTintConfirmation =
    showColorConfirmation &&
    readOnly &&
    orderProduct?.tintType &&
    orderProduct?.tintType === tintTypes.tintedWithoutConfirmation;

  const isTablet = useIsTablet();

  const resetColorAgreement = useCallback(() => {
    formik.setFieldValue('colorAgreementUrl', '');
    formik.setFieldValue('colorCode', '');
  }, [formik]);

  const handleSelectProduct = useCallback(
    (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
      const selectedProduct = productsMap[e.target.value];
      if (selectedProduct) {
        setSelectedProduct(selectedProduct);
        formik.resetForm({
          values: {
            ...formik.values,
            price: selectedProduct.price,
            category: selectedProduct.category,
            productId: e.target.value,
            specialInstructions: selectedProduct.customizable ? '' : undefined,
            quantity: 1,
            tintType: selectedProduct.tinted ? tintTypes.tintedWithConfirmation : null,
            colorCode: undefined,
            colorAgreementUrl: undefined,
            quantitySqm: selectedProduct.consumptionPerSqm || null,
            quantityLm: selectedProduct.consumptionPerLm || null,
          },
        });
      }
    },
    [formik, productsMap],
  );

  const handleChangeTintType = useCallback(
    async (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
      if ((e.target.value as any) === tintTypes.tintedWithoutConfirmation) {
        if (
          await confirm(t('rooms.tintedWithoutConfirmationWarning'), {
            checkboxConfirmationMessage: t('rooms.tintedWithoutConfirmationConfirm'),
          })
        ) {
          formik.handleChange(e);
          resetColorAgreement();
        }
      } else {
        formik.handleChange(e);
        resetColorAgreement();
      }
    },
    [formik, resetColorAgreement, t],
  );

  const handleUpload = useCallback(
    async (file: File, colorCode: string, adjacentCeiling: boolean, comments: string) => {
      if (orderProduct) {
        onEditProduct(orderProduct, { ...orderProduct, ...formik.values, defaultEditable: true });
      }
      formik.setFieldValue('colorCode', colorCode).then(() => {
        if (formik.values.tintType) {
          onUploadConfirmationFile(formik.values.tintType, file, colorCode, adjacentCeiling, comments, {
            ...formik.values,
            colorCode,
          }).then(colorAgreementUrl => {
            formik.setFieldValue('colorAgreementUrl', colorAgreementUrl);
          });
        }
      });
    },
    [formik, onEditProduct, onUploadConfirmationFile, orderProduct],
  );

  const handlePickFile = useCallback(
    async (url: string, colorCode: string, adjacentCeiling: boolean, comments: string) => {
      if (orderProduct) {
        onEditProduct(orderProduct, { ...orderProduct, ...formik.values, defaultEditable: true });
      }
      formik.setFieldValue('colorCode', colorCode).then(() => {
        if (formik.values.tintType) {
          onPickConfirmationFile(formik.values.tintType, url, colorCode, adjacentCeiling, comments, {
            ...formik.values,
            colorCode,
          }).then(colorAgreementUrl => {
            formik.setFieldValue('colorAgreementUrl', colorAgreementUrl);
          });
        }
      });
    },
    [formik, onEditProduct, onPickConfirmationFile, orderProduct],
  );

  const handleSelectCategory = useCallback(
    (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
      formik.resetForm({
        values: {
          ...formik.values,
          category: e.target.value,
          productId: '',
        },
      });
    },
    [formik],
  );

  const [isEditPriceDialogOpen, setIsEditPriceDialogOpen] = useState(false);

  return (
    <>
      <ProductListRow onSubmit={formik.handleSubmit}>
        <Cell $maxWidth="300px">
          {onSelect && orderProduct && (
            <Checkbox sx={{ margin: 0, padding: 0, marginRight: 1 }} onChange={() => onSelect(orderProduct)} />
          )}
          {isEditable ? (
            <Dropdown
              name="category"
              size="small"
              fullWidth
              label={t('product.selectCategory')}
              value={formik.values.category}
              options={categories.map(c => ({ value: c, label: c })).sort()}
              onChange={handleSelectCategory}
            />
          ) : (
            product?.category || <ReactiveText isActive={false}>{t('product.selectCategory')}</ReactiveText>
          )}
        </Cell>
        <Cell>
          <Stack sx={{ width: '100%' }} spacing={1}>
            {isEditable ? (
              formik.values.category ? (
                <Dropdown
                  size="small"
                  fullWidth
                  name="articleNo"
                  label={t('product.selectProduct')}
                  value={formik.values.productId}
                  onChange={handleSelectProduct}
                  renderMenuItem={p => <ProductSelectItem product={p.extendedValue!} />}
                  options={productsOptions}
                />
              ) : (
                ''
              )
            ) : (
              (product && <ProductSelectItem product={product} />) || (
                <ReactiveText isActive={false}>{t('product.selectProduct')}</ReactiveText>
              )
            )}
            {selectedProduct?.customizable && (
              <ConfigurationEntryContainer>
                {isEditable ? (
                  <TextField
                    multiline
                    name="specialInstructions"
                    onChange={formik.handleChange}
                    value={formik.values.specialInstructions}
                    rows={2}
                    label={t('orders.specialInstructions')}
                    size="small"
                    fullWidth
                  />
                ) : (
                  orderProduct?.specialInstructions && (
                    <span>
                      {t('orders.specialInstructions')}: {orderProduct?.specialInstructions}
                    </span>
                  )
                )}
              </ConfigurationEntryContainer>
            )}
            {selectedProduct?.tinted &&
              (isEditable ? (
                <>
                  <Dropdown
                    size="small"
                    fullWidth
                    name="tintType"
                    onChange={handleChangeTintType}
                    defaultValue={formik.values.tintType}
                    value={formik.values.tintType}
                    label={t('rooms.color')}
                    error={formik.touched.tintType && Boolean(formik.errors.tintType)}
                    helperText={formik.touched.tintType && formik.errors.tintType}
                    options={[
                      { value: tintTypes.tintedWithConfirmation, label: t('product.tintedWithConfirmation') as any },
                      {
                        value: tintTypes.tintedWithoutConfirmation,
                        label: t('product.tintedWithoutConfirmation') as any,
                      },
                    ]}
                  />
                  {formik.values.tintType !== tintTypes.standard && (
                    <ColorAgreementInfoBox
                      mode={formik.values.tintType as any}
                      onUpload={handleUpload}
                      onRemoveFile={resetColorAgreement}
                      colorCode={formik.values.colorCode}
                      uploadedFileUrl={formik.values.colorAgreementUrl}
                      onPickFile={handlePickFile}
                    />
                  )}
                </>
              ) : orderProduct?.colorCode && orderProduct?.colorAgreementUrl ? (
                <Link rel="noopener noreferrer" target="_blank" href={orderProduct.colorAgreementUrl}>
                  {t('rooms.color')}: {orderProduct?.colorCode}
                </Link>
              ) : (
                <NoColorWarning>{t('product.missingColorInfo')}</NoColorWarning>
              ))}
          </Stack>
        </Cell>
        <Cell $maxWidth={!isTablet ? '120px' : undefined}>
          <ProductQuantityWrapper>
            <Box
              sx={{
                display: 'flex',
                width: '100%',
                flexDirection: isEditable ? 'column' : 'row',
                alignItems: 'flex-end',
              }}
            >
              {isEditable ? (
                selectedProduct && (
                  <>
                    <TextField
                      size="small"
                      name="quantity"
                      fullWidth
                      onChange={formik.handleChange}
                      InputProps={{
                        inputProps: { min: 1, style: { textAlign: 'right' } },
                        startAdornment: (
                          <CalculatePopover
                            packSize={selectedProduct.packSize}
                            onCalculate={quantity => formik.setFieldValue('quantity', quantity)}
                            sqm={
                              !selectedProduct.consumptionPerLm && room
                                ? +room.surface + (+room.surface / 100) * +room.wastage
                                : undefined
                            }
                            hideConsumption={selectedProduct.relevantForWastage}
                            consumptionPerLm={selectedProduct.consumptionPerLm}
                            consumptionPerSqm={selectedProduct.consumptionPerSqm}
                            lm={0}
                            workingSteps={selectedProduct.workingSteps}
                          />
                        ),
                      }}
                      type="number"
                      value={formik.values.quantity}
                    />
                  </>
                )
              ) : (
                <div>{orderProduct?.quantity || 0}</div>
              )}
              {selectedProduct && (
                <UnitOfMeasureContainer>
                  X {selectedProduct?.packSize || '1'} {selectedProduct?.unitOfMeasure}
                </UnitOfMeasureContainer>
              )}
            </Box>
          </ProductQuantityWrapper>
        </Cell>
        <Cell justifyContent={isTablet ? 'right' : undefined} $maxWidth="80px">
          {selectedProduct && Math.round((formik.values.quantity || 1) * (selectedProduct?.packSize || 1) * 100) / 100}{' '}
          {selectedProduct?.unitOfMeasure}
        </Cell>
        {!onSelect &&
          (!readOnly ? (
            <Cell justifyContent={'space-evenly'} $maxWidth="180px">
              {isEditable ? (
                <>
                  <Button size="small" onClick={cancel} variant="text" key="cancel">
                    {t('common.cancel')}
                  </Button>
                  {selectedCategory && selectedProduct && (
                    <>
                      <Button size="small" key="save" type="submit" variant="contained">
                        {t('common.save')}
                      </Button>
                    </>
                  )}
                </>
              ) : (
                <>
                  {!isEditingProduct && (
                    <>
                      <Button
                        key="edit"
                        variant="text"
                        onClick={() => {
                          setIsEditingProduct(true);
                          setIsEditable(true);
                        }}
                      >
                        {isTablet ? t('common.edit') : <Edit />}
                      </Button>
                      <Button
                        key="delete"
                        onClick={async () => {
                          if (await confirm(t('product.removeFromOrder'))) onDeleteProduct(orderProduct);
                        }}
                        variant="text"
                      >
                        {isTablet ? t('common.delete') : <Delete />}
                      </Button>
                    </>
                  )}
                </>
              )}
            </Cell>
          ) : (
            <>
              <Cell direction="column" alignItems={isTablet ? 'end' : undefined} $maxWidth={'120px'}>
                {product?.currency}{' '}
                {(Math.round((orderProduct?.price || 0) * (orderProduct?.quantity || 0) * 10) / 10).toLocaleString(
                  'de-CH',
                  { minimumFractionDigits: 2 },
                )}
                {priceCustomizationEnabled && orderProduct && orderProduct.productId.startsWith('cust') && (
                  <div>
                    {isAdmin ? (
                      <Button onClick={() => setIsEditPriceDialogOpen(true)} variant="text" size="small">
                        {t('common.edit')}
                      </Button>
                    ) : (
                      <InfoButton marginRight content={t('product.pricesCanVary')} />
                    )}
                  </div>
                )}
              </Cell>
            </>
          ))}
      </ProductListRow>
      {showTintConfirmation && (
        <ConfirmationContainer>
          <FormControlLabel
            control={
              <Checkbox
                value={orderProduct.isTintWithoutConfirmationApproved}
                onChange={e => {
                  onEditProduct(orderProduct, { ...orderProduct, isTintWithoutConfirmationApproved: e.target.checked });
                  saveOrder();
                }}
              />
            }
            label={t('rooms.tintedWithoutConfirmationConfirm') as string}
          />
        </ConfirmationContainer>
      )}
      {isEditPriceDialogOpen && orderProduct && (
        <EditPriceDialog
          price={orderProduct?.price}
          onClose={() => setIsEditPriceDialogOpen(false)}
          onSubmit={price => {
            onEditProduct(orderProduct, { ...orderProduct, price, isEdited: true });
            saveOrder();
            setIsEditPriceDialogOpen(false);
          }}
        />
      )}
    </>
  );
};
