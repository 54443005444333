import { Info, QuestionMark } from '@mui/icons-material';
import * as React from 'react';
import { InfoButtonContainer, TooltipContent, StyledTooltip } from './InfoButton.styles';

interface IInfoButtonProps {
  content: string;
  marginRight?: boolean;
  help?: boolean;
}

const InfoButton: React.FunctionComponent<IInfoButtonProps> = ({ content, marginRight, help }) => {
  return (
    <StyledTooltip placement="top" title={<TooltipContent>{content}</TooltipContent>}>
      <InfoButtonContainer help={help} marginRight={marginRight}>
        {help ? <QuestionMark /> : <Info />}
      </InfoButtonContainer>
    </StyledTooltip>
  );
};

export default InfoButton;
