import { useTranslation } from 'react-i18next';
import Grow from '@mui/material/Grow';

import { Room } from './room/Room';
import { AddRoomButton, GuidedContainer, ProductSection } from './SelectProductsWizardStep.styles';
import { useOrderWizardStore } from '../../../../stores/orderWizardStore';
import InfoButton from '../../../core/infoButton/InfoButton';
import shallow from 'zustand/shallow';
import ProductList from './products/productList/ProductList';
import Fade from '@mui/material/Fade';
import { WizzardNavigationButtonsContainer } from '../../OrderWizardPage.styles';
import { Button } from '../../../core/button/Button';
import { useEffect } from 'react';
import { useRoomsStore } from '../../../../stores/roomOptionsStore';
import { useProductsStore } from '../../../../stores/productStore';
import { useNotificationStore } from '../../../../stores/notificationStore';
import { useIsTablet } from '../../../../hooks/useIsMobile';

export const SelectProductsWizardStep: React.FC = () => {
  const { t } = useTranslation();

  const [order, setIsGuided, rooms, addRoom, goToNextStep, orderProducts, isEditingProduct] = useOrderWizardStore(
    s => [s.order, s.setIsGuided, s.rooms, s.addRoom, s.goToNextStep, s.products, s.isEditingProduct],
    shallow,
  );

  const addNotification = useNotificationStore(s => s.addNotification);

  const productsMap = useProductsStore(s => s.productsMap);

  const isTablet = useIsTablet();

  const [loadRoomOptions, roomOptionsLoaded] = useRoomsStore(s => [s.loadRoomOptions, s.roomOptionsLoaded], shallow);

  const isGuided = order.isGuided;

  useEffect(() => {
    if (isGuided && !roomOptionsLoaded) {
      loadRoomOptions();
    }
  }, [isGuided, loadRoomOptions, roomOptionsLoaded]);

  const handleGetQuote = () => {
    const hasTintedProductsWithNoConfirmation = orderProducts.some(
      p => productsMap[p.productId]?.tinted && (!p.colorCode || !p.tintType || !p.colorAgreementUrl),
    );
    if (hasTintedProductsWithNoConfirmation) {
      addNotification({ message: t('orders.tintedWithoutConfimationErrorMessage'), color: 'warning' });
    } else {
      goToNextStep();
    }
  };

  return (
    <>
      <Grow in={true}>
        <div>
          <GuidedContainer>
            <Grow in={isGuided}>
              <div>
                <AddRoomButton onClick={addRoom}>{t('rooms.addRoom')}</AddRoomButton>
              </div>
            </Grow>
            <Button sx={{ marginLeft: 'auto' }} onClick={() => setIsGuided(!isGuided)}>
              {isGuided ? t('orders.quickOrder') : t('orders.guidedOrder')}
            </Button>
            {!isGuided && !isTablet && <InfoButton help content={t('orders.guidedInfo')} />}
          </GuidedContainer>
          <ProductSection>
            {isGuided ? (
              <>
                {rooms.map((r, i) => (
                  <Room key={r.uiId} room={r} roomIndex={i} />
                ))}
                <Room key="otherProducts" />
              </>
            ) : (
              <Fade in={true}>
                <div>
                  <ProductList showAllProducts={!isGuided} />
                </div>
              </Fade>
            )}
          </ProductSection>
        </div>
      </Grow>
      <WizzardNavigationButtonsContainer>
        <Button
          disabled={!orderProducts.length || isEditingProduct}
          type="submit"
          sx={{ marginLeft: 'auto' }}
          onClick={handleGetQuote}
        >
          {t('orders.getQuote')}
        </Button>
      </WizzardNavigationButtonsContainer>
    </>
  );
};
