import { t } from 'i18next';
import create from 'zustand';
import { Project } from '../models/Project';
import { editProject, getProjects } from '../services/http/projects';
import { useNotificationStore } from './notificationStore';

interface ProjectsStoreActions {
  updateProject: (Project: Project) => Promise<void>;
  loadProjects: (companyId: string) => Promise<void>;
}

interface ProjectsStoreValues {
  projects: Project[];
  projectsLoaded: boolean;
}

const initialValues: ProjectsStoreValues = {
  projects: [],
  projectsLoaded: false,
};

export const useProjectsStore = create<ProjectsStoreActions & ProjectsStoreValues>((set, get) => ({
  ...initialValues,
  loadProjects: companyId => getProjects(companyId).then(projects => set({ projects, projectsLoaded: true })),
  updateProject: project =>
    editProject(project).then(() => {
      useNotificationStore.getState().addNotification({ message: t('project.updateSuccess'), color: 'success' });
      get().loadProjects(project.companyId);
    }),
}));
