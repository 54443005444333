import { Lock } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';
import { SystemPage } from './SystemPages.styles';

export const UnauthorizedPage: React.FC = () => {
  const { t } = useTranslation();
  return (
    <SystemPage>
      <h1><Lock fontSize='large' /></h1>
      <div>{t('system.unauthorizedAction')}</div>
    </SystemPage>
  );
};
