import { useIsMobile } from '../../../hooks/useIsMobile';
import { ResponsiveGridColumns } from '../../../models/ResponsiveGridColumn';
import { DataGrid } from '../dataGrid/DataGrid';
import { MobileList } from '../mobileList/MobileList';

type Props = React.ComponentProps<typeof DataGrid>;

export type ResponsiveDataGridProps = Props & { columns: ResponsiveGridColumns };

export const ResponsiveDataGrid: React.FC<ResponsiveDataGridProps> = props => {
  const isMobile = useIsMobile();

  return isMobile ? <MobileList {...props} /> : <DataGrid {...props} />;
};
