import { DataGrid as MuiDataGrid } from '@mui/x-data-grid';

type Props = React.ComponentProps<typeof MuiDataGrid>;

export const DataGrid: React.FC<Props> = props => {
  const pageSize = props.pageSize || 10;
  const hasMoreRowsThanPageSize = props.rows.length > pageSize;
  return (
    <MuiDataGrid
      disableColumnSelector
      disableSelectionOnClick
      hideFooter={!hasMoreRowsThanPageSize}
      pageSize={pageSize}
      sx={{
        border: 0,
        '.MuiDataGrid-root .MuiDataGrid-columnHeaderCheckbox .MuiDataGrid-columnHeaderTitleContainer': {
          display: 'none',
        },
        '& .MuiDataGrid-columnHeader:hover': {
          color: 'text.secondary',
          transition: 'all 0.3s',
        },
        '& .MuiDataGrid-columnHeaderTitleContainer': {
          padding: 0,
        },
        '& .MuiDataGrid-columnHeaderTitle': {
          fontFamily: 'Flama Semibold',
        },
        '& .MuiDataGrid-columnSeparator': {
          display: 'none',
        },
        '& .MuiDataGrid-row':
          props.disableSelectionOnClick != null && !props.disableSelectionOnClick
            ? {
                cursor: 'pointer',
              }
            : {},
        '& .MuiDataGrid-row:last-child': {
          '& .MuiDataGrid-cell': {
            borderColor: 'transparent',
          },
        },
        '& .MuiDataGrid-cell:focus': {
          outline: 'unset',
        },
      }}
      {...props}
    />
  );
};
