import { Backdrop, CircularProgress } from '@mui/material';
import { useAppStore } from '../../../stores/appStore';

export const AppLoading = () => {
  const isAppLoading = useAppStore(s => s.isAppLoading);

  return isAppLoading ? (
    <Backdrop sx={{ zIndex: 9999 }} open>
      <CircularProgress color="secondary" />
    </Backdrop>
  ) : null;
};
