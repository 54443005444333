import * as yup from 'yup';

import { Dialog, DialogActions, DialogContent, DialogTitle, Stack, TextField } from '@mui/material';
import { useFormik } from 'formik';
import { useTranslation } from 'react-i18next';
import { Project } from '../../../models/Project';
import { Button } from '../../core/button/Button';
import { useProjectsStore } from '../../../stores/projectsStore';
import { AddressFields } from '../../orderWizard/steps/common/AddressFields';
import { ContactPersonFields } from '../../orderWizard/steps/common/ContactPersonFields';

interface Props {
  project: Project;
  onClose: () => void;
}

export const ProjectEditorDialog: React.FC<Props> = ({ project, onClose }) => {
  const { t } = useTranslation();

  const updateProject = useProjectsStore(s => s.updateProject);

  const requiredMessage = t('validations.required');

  const validationSchema = yup.object({
    name: yup.string().required(requiredMessage),
    address: yup.object().shape({
      nameOfBuilding: yup.string().optional(),
      street: yup.string().required(requiredMessage),
      addressLine2: yup.string().required(requiredMessage),
      postcode: yup.string().required(requiredMessage),
      city: yup.string().required(requiredMessage),
      country: yup.string().required(requiredMessage),
    }),
    buildingType: yup.string().required(requiredMessage),
    contactFirstName: yup.string().required(requiredMessage),
    contactLastName: yup.string().required(requiredMessage),
    contactEmail: yup.string().required(requiredMessage),
    phoneNumber: yup.string().required(requiredMessage),
  });

  const formik = useFormik<Project>({
    initialValues: project,
    validationSchema,
    onSubmit: values => {
      updateProject(values).then(() => onClose());
    },
  });

  return (
    <Dialog onClose={onClose} open>
      <DialogTitle>{t('project.editProject')}</DialogTitle>
      <DialogContent>
        <form id="edit-project-form" onSubmit={formik.handleSubmit}>
          <Stack sx={{ width: '300px', padding: 1 }} spacing={2}>
            <TextField
              fullWidth
              name="name"
              label={t('common.name')}
              value={formik.values.name}
              onChange={formik.handleChange}
              error={formik.touched.name && Boolean(formik.errors.name)}
              helperText={formik.touched.name && formik.errors.name}
            />
            <TextField
              fullWidth
              name="buildingType"
              label={t('project.buildingType')}
              value={formik.values.buildingType}
              onChange={formik.handleChange}
              error={formik.touched.buildingType && Boolean(formik.errors.buildingType)}
              helperText={formik.touched.buildingType && formik.errors.buildingType}
            />
            <AddressFields formik={formik} addressAccessor="address" />
            <ContactPersonFields formik={formik} />
          </Stack>
        </form>
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" onClick={onClose}>
          {t('common.cancel')}
        </Button>
        <Button form="edit-project-form" type="submit">
          {t('common.save')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
