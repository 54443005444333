import Grow from '@mui/material/Grow';
import { useTranslation } from 'react-i18next';
import confirm from '../../../../services/confirm/confirm';
import { useOrderWizardStore } from '../../../../stores/orderWizardStore';
import { Button } from '../../../core/button/Button';
import { WizzardNavigationButtonsContainer } from '../../OrderWizardPage.styles';
import { EditOrderButton } from '../common/EditOrderButton';
import ProductList from '../selectProducts/products/productList/ProductList';
import { ActualDeliveryInfo } from './actualDeliveryInfo/ConfirmDeliveryInfo';
import { DownloadOrderButton } from '../common/DownloadOrderButton';
import { Box } from '@mui/material';
import { useUserStore } from '../../../../stores/userStore';
import shallow from 'zustand/shallow';

interface IConfirmOrderWizardStepProps {}

const ConfirmOrderWizardStep: React.FunctionComponent<IConfirmOrderWizardStepProps> = props => {
  const { t } = useTranslation();
  const [saveOrder] = useOrderWizardStore(s => [s.saveOrder]);

  const [isAdmin, isSales] = useUserStore(s => [s.isAdmin, s.isSales], shallow);

  const isRegularUser = !isAdmin && !isSales;

  return (
    <Grow in>
      <div>
        <ActualDeliveryInfo />
        <ProductList
          readOnly
          showAllProducts
          showAdditionalProjectDiscount
          readonlyAdditionalProjectDiscount={isRegularUser}
        />
        <WizzardNavigationButtonsContainer>
          {!isRegularUser && <EditOrderButton />}
          <Box sx={{ marginLeft: 'auto' }}>
            <DownloadOrderButton />
          </Box>
          <Button
            color="success"
            type="submit"
            onClick={async () => {
              if (await confirm(t('orders.confirmOrderConfirmation'))) {
                saveOrder('Signed');
              }
            }}
          >
            {t('orderWizard.confirm-order.title')}
          </Button>
        </WizzardNavigationButtonsContainer>
      </div>
    </Grow>
  );
};

export default ConfirmOrderWizardStep;
