import styled from 'styled-components';
import { Button } from '../../../core/button/Button';
import { ReactiveText } from '../../../core/Core.styles';

export const GuidedContainer = styled.div`
  display: flex;
  align-items: center;
  position: relative;
  margin-right: ${p => p.theme.spacing(3)};
`;

export const GuidedInfoContainer = styled(ReactiveText)`
  margin-left: auto;
`;

export const ProductSection = styled.div`
  margin: ${p => p.theme.spacing(2)} 0;
`;

export const AddRoomButton = styled(Button)`
  margin-left: ${p => p.theme.spacing(2)};
`;
