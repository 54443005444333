import Tab from '@mui/material/Tab';
import styled from 'styled-components';

export const TabViews = styled.div`
  margin-top: ${p => p.theme.spacing(3)};
`;

export const EllipsableSpan = styled.span`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  display: inline-block;
  width: 100%;
`;

export const StyledTab = styled(Tab)`
  &:hover {
    color: ${p => p.theme.palette.text.primary};
  }
`;
