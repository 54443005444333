import { useTranslation } from 'react-i18next';
import { formatAddress, formatDate } from '../../../../../services/utils/format';
import { useOrderWizardStore } from '../../../../../stores/orderWizardStore';
import { ActualDeliveryInfoContainer, InfoRow } from './ActualDeliveryInfo.styles';
import { useProjectsStore } from '../../../../../stores/projectsStore';
import { GeneralTermsAndConditionsLink } from '../../common/GeneralTermsAndConditionsLink';

export const ActualDeliveryInfo: React.FC<{ hideBaswaDetails?: boolean }> = ({ hideBaswaDetails }) => {
  const { t } = useTranslation();
  const order = useOrderWizardStore(s => s.order);
  const projects = useProjectsStore(s => s.projects);

  const project = order.projectId ? order.project || projects.find(p => p.id === order.projectId) : undefined;

  return order ? (
    <ActualDeliveryInfoContainer>
      <InfoRow>
        <div>{t('delivery.deliveryDate')}</div>
        <div>{order.actualDeliveryDate && formatDate(order.actualDeliveryDate)}</div>
      </InfoRow>
      {project && (
        <InfoRow>
          <div>{t('common.project')}</div>
          <div>{project.name}</div>
        </InfoRow>
      )}
      <InfoRow>
        <div>{t('delivery.shippingAddress')}</div>
        <div>{formatAddress(order.shippingAddress)}</div>
      </InfoRow>
      <InfoRow>
        <div>{t('delivery.deliveryDetails')}</div>
        <div>{order.deliveryDetails}</div>
      </InfoRow>
      <InfoRow>
        <div>{t('project.contactPerson')}</div>
        <div>
          {order.contactFirstName} {order.contactLastName}
        </div>
      </InfoRow>
      <InfoRow>
        <div>{t('common.phoneNumber')}</div>
        <div>{order.phoneNumber}</div>
      </InfoRow>
      <InfoRow>
        <div>{t('common.email')}</div>
        <div>{order.contactEmail}</div>
      </InfoRow>
      {!hideBaswaDetails && (
        <>
          <InfoRow>
            <div>{t('delivery.baswaComments')}</div>
            <div>{order.baswaComments}</div>
          </InfoRow>
          <InfoRow>
            <div>{t('orders.paymentTerms')}</div>
            <div>{order.paymentTerms}</div>
          </InfoRow>
        </>
      )}

      <InfoRow>
        <div></div>
        <div>
          <GeneralTermsAndConditionsLink />
        </div>
      </InfoRow>
    </ActualDeliveryInfoContainer>
  ) : null;
};
