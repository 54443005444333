import { AppLanguage } from '../../constants/languages';

export const getApiBaseURL = () => process.env.REACT_APP_API_URL as string;
export const getAuthRedirectURL = () => process.env.REACT_APP_AUTH_REDIRECT_URL as string;
export const getMsalClientId = () => process.env.REACT_APP_MSAL_CLIENT_ID as string;
export const getMsalAuthority = () => process.env.REACT_APP_MSAL_CLIENT_AUTHORITY as string;
export const getMsalLoginRequestScope = () => process.env.REACT_APP_MSAL_LOGIN_REQUEST_SCOPE as string;

export const getColorConfirmationTemplateUrl = (language: AppLanguage) =>
  process.env[`REACT_APP_${language}_TEMPLATE`] as string;

export const getIsTraining = () => Boolean(process.env[`REACT_APP_IS_TRAINING`]);

export const getHideDraftMode = () => Boolean(process.env['REACT_APP_HIDE_DRAFT_MODE']);

export const getSwitchVersionUrl = () => process.env.REACT_APP_SWITCH_VERSION_URL as string;
