import { createTheme } from '@mui/material/styles';

const accentColor = '#d5c1b4';
const primary = '#000000';

const theme = createTheme({
  palette: {
    mode: 'light',
    primary: {
      main: primary,
    },
    secondary: {
      main: accentColor,
    },
    text: {
      secondary: accentColor,
      primary: primary,
    },
    divider: accentColor,
  },
  shape: { borderRadius: 0 },
  typography: {
    fontFamily: ['Flama Basic', 'Dosis', 'Nunito', 'Roboto', '"Helvetica Neue"', 'Arial', 'sans-serif'].join(','),
  },
  components: {
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          fontSize: '1rem',
        },
      },
    },
    MuiTab: {
      styleOverrides: {
        root: {
          fontFamily: 'Flama Semibold',
          textTransform: 'none',
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          fontFamily: 'Flama Semibold',
          textTransform: 'none',
          fontSize: '0.85rem',
        },
      },
    },
  },
});

export default theme;
