import { ExitToApp, Menu } from '@mui/icons-material';
import { Box, Divider, Drawer, IconButton, Link, Slide, Toolbar, useScrollTrigger } from '@mui/material';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import BaswaLogo from '../../assets/baswa_logo.svg';
import { useIsTablet } from '../../hooks/useIsMobile';
import { getProductCatalog } from '../../services/http/products';
import { useAppStore } from '../../stores/appStore';
import { useCompaniesStore } from '../../stores/companiesStore';
import { useUserStore } from '../../stores/userStore';
import { NewOrderFab } from '../home/newOrderFab/NewOrderFab';
import { AccountControl } from './accountControl/AccountControl';
import { CompanySelector } from './companySelector/CompanySelector';
import { LanguageControl } from './languageControl/LanguageControl';
import {
  BaswaLogoMobileWrapper,
  ButtonLink,
  DrawerButton,
  DrawerContainer,
  ExternalNavigationNode,
  GoToLiveContainer,
  NavContainer,
  NavigationNode,
  NavigationNodesContainer,
  TopNavElementsContainer,
  TrainingContainer,
  TrainingText,
} from './Navigation.styles';
import shallow from 'zustand/shallow';
import { DraftModeControl } from './draftModeControl/DraftModeControl';
import { getSwitchVersionUrl } from '../../services/environment/environment';

interface INavigationNode {
  url?: string;
  title: string;
  isExternal?: boolean;
  hidden?: boolean;
  render?: () => JSX.Element;
}

const HideOnScroll: React.FC<{ children: React.ReactElement }> = ({ children }) => {
  const trigger = useScrollTrigger({
    target: window,
  });

  return (
    <Slide appear={false} direction="down" in={!trigger}>
      {children}
    </Slide>
  );
};

const drawerWidth = 250;

const BaswaHorizontalLogo = <img height="60px" src={BaswaLogo} alt="logo" />;

export const Navigation: React.FC = () => {
  const { t } = useTranslation();
  const company = useCompaniesStore(s => s.selectedCompany);
  const currentLanguage = useUserStore(s => s.language);
  const [setIsAppLoading, $isTraining, $isInDraftMode] = useAppStore(
    s => [s.setAppLoading, s.isTraining, s.isInDraftMode],
    shallow,
  );

  const navigationNodes: INavigationNode[] = [
    {
      url: '/',
      title: t('navigation.home'),
    },
    {
      title: t('navigation.productCatalog'),
      hidden: !company,
      render: () => (
        <ButtonLink
          key="catalog"
          onClick={() => {
            if (company) {
              setIsAppLoading(true);
              getProductCatalog(company.companyId, currentLanguage).finally(() => setIsAppLoading(false));
              handleDrawerToggle();
            }
          }}
        >
          {t('navigation.productCatalog')}
        </ButtonLink>
      ),
    },
    {
      url: 'https://www.baswa.com/en/contact/',
      title: t('navigation.contact'),
      isExternal: true,
    },
  ];

  const [mobileOpen, setMobileOpen] = useState(false);

  const isTablet = useIsTablet();

  const handleDrawerToggle = () => {
    if (isTablet) {
      setMobileOpen(!mobileOpen);
    }
  };

  const navNodes = (
    <NavigationNodesContainer>
      <NewOrderFab onClick={() => (mobileOpen ? handleDrawerToggle() : undefined)} />
      {navigationNodes
        .filter(n => !n.hidden)
        .map(n =>
          n.render ? (
            n.render()
          ) : n.isExternal ? (
            <ExternalNavigationNode
              onClick={handleDrawerToggle}
              key={n.url}
              target="_blank"
              rel="noopener noreferrer"
              href={n.url}
            >
              {n.title}
            </ExternalNavigationNode>
          ) : (
            <NavigationNode onClick={handleDrawerToggle} key={n.url} to={n.url || ''}>
              {n.title}
            </NavigationNode>
          ),
        )}
    </NavigationNodesContainer>
  );

  const getDrawer = () => (
    <DrawerContainer>
      <BaswaLogoMobileWrapper>
        {BaswaHorizontalLogo}
        <Box sx={{ display: 'flex', justifyContent: 'center' }}>
          <CompanySelector />
        </Box>
      </BaswaLogoMobileWrapper>
      <Divider sx={{ marginBottom: 2 }} />
      {navNodes}
      <AccountControl />
    </DrawerContainer>
  );

  return (
    <>
      <HideOnScroll>
        <NavContainer component="nav" {...{ $isTraining, $isInDraftMode }}>
          {$isTraining && (
            <TrainingContainer>
              <TrainingText>{t('common.training')}</TrainingText>
              <GoToLiveContainer>
                {isTablet ? (
                  <IconButton sx={{ padding: 0 }} size="small" href={getSwitchVersionUrl()}>
                    <ExitToApp fontSize="small" />
                  </IconButton>
                ) : (
                  <Link sx={{ color: 'white' }} href={getSwitchVersionUrl()}>
                    {t('system.goToLive')}
                  </Link>
                )}
              </GoToLiveContainer>
            </TrainingContainer>
          )}
          <Toolbar sx={{ height: '100%' }}>
            <DrawerButton edge="start" onClick={handleDrawerToggle}>
              <Menu />
            </DrawerButton>
            <TopNavElementsContainer>
              {BaswaHorizontalLogo}
              {navNodes}
              <AccountControl />
              <CompanySelector />
            </TopNavElementsContainer>
            <LanguageControl />
          </Toolbar>
          {$isInDraftMode && <DraftModeControl />}
        </NavContainer>
      </HideOnScroll>
      <Box component="nav">
        <Drawer
          container={() => window.document.body}
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true,
          }}
          sx={{
            display: { xs: 'block', md: 'none' },
            '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
          }}
        >
          {getDrawer()}
        </Drawer>
      </Box>
    </>
  );
};
