import styled from 'styled-components';
import { Tab } from '../../core/Core.styles';

export const ProjectsContainer = styled.div`
  ${p => p.theme.breakpoints.up('md')} {
    display: flex;
  }
`;

export const ProjectView = styled.div`
  ${p => p.theme.breakpoints.up('md')} {
    margin-left: ${p => p.theme.spacing(4)};
    flex: 1;
  }
`;

export const ProjectDetailsContainer = styled.div`
  ${p => p.theme.breakpoints.up('md')} {
    display: flex;
    justify-content: space-between;
  }
`;

export const ProjectOrdersContainer = styled.div`
  margin-top: ${p => p.theme.spacing(2)};
`;

export const ProjectTitle = styled.div`
  font-size: x-large;
`;

export const ProjectDetail = styled.div``;

export const ProjectTab = styled(Tab)`
  align-items: end;
`;

export const NoProjects = styled.div`
  padding: ${p => p.theme.spacing(4)};
  text-align: center;
`;

export const ContactInfoContainer = styled.div`
  display: flex;
  align-items: flex-start;
`;
