import { useTranslation } from 'react-i18next';
import { SystemPage } from './SystemPages.styles';

export const PageNotFound: React.FC = () => {
  const { t } = useTranslation();
  return (
    <SystemPage>
      <h1>404</h1>
      <div>{t('system.pageNotFound')}</div>
    </SystemPage>
  );
};
