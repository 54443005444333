import { useState } from 'react';
import { CollapsibleRoom, ExpandRoomButton, RoomContainer, RoomHeader, RoomProducts, RoomTitle } from './Room.styles';

import { RoomForm } from './roomForm/RoomForm';
import { ArrowDropDown, ArrowDropUp } from '@mui/icons-material';
import { Room as IRoom } from '../../../../../models/Room';
import { useTranslation } from 'react-i18next';
import { Box, Fade } from '@mui/material';
import ProductList from '../products/productList/ProductList';
import { useRoomsStore } from '../../../../../stores/roomOptionsStore';
import { useOrderWizardStore } from '../../../../../stores/orderWizardStore';

export const Room: React.FC<{ room?: IRoom; roomIndex?: number }> = ({ room, roomIndex }) => {
  const { t } = useTranslation();
  const [isRoomConfigurationOpen, setIsRoomConfigurationOpen] = useState(true);
  const roomOptions = useRoomsStore(s => s.roomOptions);
  const products = useOrderWizardStore(s => s.products);
  const roomOption = room && roomOptions.find(r => r.panel.key === room.system);
  return (
    <Fade in={true}>
      <RoomContainer isOther={!room}>
        <RoomHeader isOther={!room}>
          <ExpandRoomButton onClick={() => setIsRoomConfigurationOpen(!isRoomConfigurationOpen)}>
            <RoomTitle>
              {room?.name || t('product.otherProducts')}
              {room && ` - ${room.surface} sqm `}
              <Box sx={{ fontSize: '0.9rem', marginLeft: '16px' }}>
                {roomOption &&
                  !isRoomConfigurationOpen &&
                  [
                    room.type,
                    roomOption.panel.value,
                    roomOption.thicknesses.find(t => t.key === room.thickness)?.value,
                    roomOption.coatingSystems.find(t => t.key === room.coatType)?.value,
                  ]
                    .filter(Boolean)
                    .join(', ')}
              </Box>
            </RoomTitle>
            {room && (isRoomConfigurationOpen ? <ArrowDropUp /> : <ArrowDropDown />)}
          </ExpandRoomButton>
        </RoomHeader>

        {room && roomIndex !== undefined && (
          <CollapsibleRoom in={isRoomConfigurationOpen} collapsedSize={0}>
            <RoomForm room={room} roomIndex={roomIndex} />
          </CollapsibleRoom>
        )}

        <RoomProducts>
          <ProductList products={products} roomId={room && room.uiId} />
        </RoomProducts>
      </RoomContainer>
    </Fade>
  );
};
