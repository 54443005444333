import { ChangeCircle, Help, Logout, Settings } from '@mui/icons-material';
import ListItemIcon from '@mui/material/ListItemIcon';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import React from 'react';
import { useTranslation } from 'react-i18next';
import shallow from 'zustand/shallow';
import { useUserStore } from '../../../stores/userStore';
import { Button } from '../../core/button/Button';
import { AccountControlContainer, NameContainer } from './AccountControl.styles';
import { AdminGuard } from '../../guards/AdminGuard';
import { useAppStore } from '../../../stores/appStore';
import { getHideDraftMode, getSwitchVersionUrl } from '../../../services/environment/environment';
import { AppLanguage } from '../../../constants/languages';

const switchVersions = () => (window.location.href = getSwitchVersionUrl());

const userManuals: { [key in AppLanguage]: string } = {
  EN: 'https://baswastorageprod.blob.core.windows.net/orderconfigurator-usermanuals/Order%20Tool%20User%20Manual.pdf',
  DE: 'https://baswastorageprod.blob.core.windows.net/orderconfigurator-usermanuals/Benutzerhandbuch%20BASWA%20Bestell-Tool.pdf',
  FR: "https://baswastorageprod.blob.core.windows.net/orderconfigurator-usermanuals/Manuel%20d'utilisation%20BASWA%20outil%20de%20commende%202023_1.pdf",
};

const openUserManual = (language: AppLanguage) => window.open(userManuals[language], '_blank', 'noopener noreferrer');

export const AccountControl: React.FC = () => {
  const { t } = useTranslation();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const [name, username, logout, language] = useUserStore(s => [s.name, s.username, s.logout, s.language], shallow);

  const [draftMode, setDraftMode, isTraining] = useAppStore(
    s => [s.isInDraftMode, s.setIsInDraftMode, s.isTraining],
    shallow,
  );

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(null);
  };

  const toggleDraftMode = () => setDraftMode(!draftMode);

  return (
    <AccountControlContainer>
      <Button
        variant="text"
        id="profile-button"
        aria-controls={open ? 'composition-menu' : undefined}
        aria-expanded={open ? 'true' : undefined}
        aria-haspopup="true"
        onClick={handleClick}
      >
        <NameContainer>{name || username || ''}</NameContainer>
      </Button>
      <Menu
        anchorEl={anchorEl}
        id="account-menu"
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      >
        <MenuItem onClick={logout}>
          <ListItemIcon>
            <Logout fontSize="small" />
          </ListItemIcon>
          {t('common.logout')}
        </MenuItem>
        {!getHideDraftMode() && (
          <AdminGuard>
            <MenuItem onClick={toggleDraftMode}>
              <ListItemIcon>
                <Settings fontSize="small" />
              </ListItemIcon>
              {draftMode ? t('draftMode.exitDraftMode') : t('draftMode.enableDraftMode')}
            </MenuItem>
          </AdminGuard>
        )}
        <MenuItem onClick={switchVersions}>
          <ListItemIcon>
            <ChangeCircle fontSize="small" />
          </ListItemIcon>
          {t(isTraining ? 'system.goToLive' : 'system.goToTraining')}
        </MenuItem>
        <MenuItem onClick={() => openUserManual(language)}>
          <ListItemIcon>
            <Help fontSize="small" />
          </ListItemIcon>
          {t('common.userManual')}
        </MenuItem>
      </Menu>
    </AccountControlContainer>
  );
};
