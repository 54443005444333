import { createGlobalStyle } from 'styled-components';

import FlamaBasicWoff from './Flama-Basic.woff';
import FlamaBasicEot from './Flama-Basic.eot';

import FlamaSemiboldWoff from './Flama-Semibold.woff';
import FlamaSemiboldEot from './Flama-Semibold.eot';

export default createGlobalStyle`
    @font-face {
        font-family: 'Flama Basic';
        src: local('Flama Basic'), local('FlamaBasic'),
        url(${FlamaBasicWoff}) format('woff'),
        url(${FlamaBasicEot}) format('eot');
    }
    @font-face {
        font-family: 'Flama Semibold';
        src: local('Flama Semibold'), local('FlamaSemibold'),
        url(${FlamaSemiboldWoff}) format('woff'),
        url(${FlamaSemiboldEot}) format('eot');
    }
`;
