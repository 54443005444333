import { http } from './http';
import { RoomOptionPanel } from '../../models/RoomOptionPanel';
import { ApiResponse } from '../../models/ApiResponse';
import { useUserStore } from '../../stores/userStore';
import { Room } from '../../models/Room';
import { RoomComputedOrderProduct } from '../../models/RoomComputedOrderProduct';
import { tintTypes } from '../../constants/tintTypes';

export const getRoomsOptions = () =>
  http
    .get<ApiResponse<RoomOptionPanel[]>>(`/rooms/options`, { params: { language: useUserStore.getState().language } })
    .then(r => r.data.payload);

export const getComputedRoomOrderProducts = (room: Room) =>
  http
    .post<ApiResponse<RoomComputedOrderProduct[]>>('/rooms/calculateProducts', {
      squareMeters: room.surface,
      wastage: room.wastage,
      panel: room.system,
      thickness: room.thickness,
      substrate: room.substrate,
      coatingSystem: room.coatType,
      fireRezistant: !!room.fireResistant,
      tinted: room.tintType !== tintTypes.standard,
    })
    .then(r => r.data.payload);
