import { Autocomplete, TextField } from '@mui/material';
import { uniqBy } from 'lodash-es';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import shallow from 'zustand/shallow';
import { useCompaniesStore } from '../../../stores/companiesStore';

export const CompanySelector: React.FC = () => {
  const { t } = useTranslation();

  const { pathname } = useLocation();

  const [companies, setSelectedCompany, selectedCompany] = useCompaniesStore(
    s => [s.companies, s.setSelectedCompany, s.selectedCompany],
    shallow,
  );

  const options = useMemo(
    () =>
      uniqBy(
        companies.filter(c => c.name && c.companyId).map(c => ({ label: c.name, value: c.companyId })),
        'value',
      ),
    [companies],
  );

  const showControl = options.length > 1;

  return showControl ? (
    <Autocomplete
      disabled={pathname !== '/' && !!selectedCompany}
      size="small"
      value={options.find(o => o.value === selectedCompany?.companyId) || null}
      options={options}
      sx={{ width: 200 }}
      onChange={(a, b) => setSelectedCompany(b?.value)}
      renderOption={(props, option) => (
        <li {...props} key={option.value}>
          {option.label}
        </li>
      )}
      renderInput={params => <TextField placeholder={t('customer.selectCustomer')} {...params} variant="standard" />}
    />
  ) : null;
};
