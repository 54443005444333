import create from 'zustand';
import { v4 as uuidv4 } from 'uuid';
import { devtools } from 'zustand/middleware';
import React from 'react';
import { DeliveryDetailsWizardStep } from '../components/orderWizard/steps/deliveryDetails/DeliveryDetailsWizardStep';
import { SelectProductsWizardStep } from '../components/orderWizard/steps/selectProducts/SelectProductsWizardStep';
import { GetQuoteWizardStep } from '../components/orderWizard/steps/getQuote/GetQuoteWizardStep';
import ConfirmOrderWizardStep from '../components/orderWizard/steps/confirmOrder/ConfirmOrderWizardStep';
import { Order } from '../models/Order';
import history from '../services/history/history';
import { Room } from '../models/Room';
import appHistory from '../services/history/history';
import { withWizardNavigator } from '../components/orderWizard/steps/WizardStepNavigator';
import { OrderProduct } from '../models/OrderProduct';
import { getOrder, saveOrder, uploadColorConfirmation } from '../services/http/orders';
import { useCompaniesStore } from './companiesStore';
import { useNotificationStore } from './notificationStore';
import { t } from 'i18next';
import ReviewOrderWizardStep from '../components/orderWizard/steps/reviewOrder/ReviewOrderWizardStep';
import OrderStatusWizardStep from '../components/orderWizard/steps/orderStatus/OrderStatusWizardStep';
import { OrderStatus } from '../constants/orderStatus';
import { tintTypes } from '../constants/tintTypes';
import { useAppStore } from './appStore';
import { last } from 'lodash-es';
import { useUserStore } from './userStore';

export type WizardStepComponent = React.FC<{ step: number; suitableStatuses: OrderStatus[] }>;

interface OrderWizardStep {
  name: string;
  component: WizardStepComponent;
  suitableStatuses: OrderStatus[];
}

const getStep = (name: string, component: React.FC, suitableStatuses: OrderStatus[]) => ({
  name,
  component: withWizardNavigator(component),
  suitableStatuses,
});

const steps: OrderWizardStep[] = [
  getStep('select-products', SelectProductsWizardStep, ['Open']),
  getStep('get-quote', GetQuoteWizardStep, ['Open']),
  getStep('delivery-details', DeliveryDetailsWizardStep, ['Open']),
  getStep('review', ReviewOrderWizardStep, ['Submitted']),
  getStep('confirm-order', ConfirmOrderWizardStep, ['NotYetSigned']),
  getStep('order-status', OrderStatusWizardStep, ['Signed', 'Closed']),
];

interface OrderWizardStoreValues {
  isEditingProduct: boolean;
  steps: OrderWizardStep[];
  activeStep: number;
  products: OrderProduct[];
  order: Order;
  rooms: Room[];
  isLoadingOrder: boolean;
  tintedWithoutConfirmationReconfirmIds: number[];
}

interface OrderWizardStoreActions {
  setIsEditingProduct: (isEditing: boolean) => void;
  setActiveStep: (orderNumber: number) => void;
  goToNextStep: () => void;
  goToPreviousStep: () => void;
  setProducts: (products: OrderProduct[]) => void;

  addProduct: (product: OrderProduct) => void;
  removeProduct: (product: OrderProduct) => void;
  editProduct: (oldOrderProduct: OrderProduct, newProduct: OrderProduct) => void;

  setOrder: (order: Order) => void;

  setIsGuided: (isGuided: boolean) => void;

  addRoom: () => void;
  updateRoom: (room: Room, roomIndex: number) => void;
  deleteRoom: (roomIndex: number) => void;

  saveOrder: (status?: OrderStatus) => Promise<Order>;
  loadOrder: (orderId: number) => void;

  uploadColorConfirmationFile: (
    tintType: string,
    file: File | string,
    colorCode: string,
    adjacentCeiling: boolean,
    comments: string,
    roomUiid?: string,
    orderProduct?: OrderProduct,
  ) => Promise<string>;

  pickColorConfirmationFile: (
    tintType: string,
    url: string,
    colorCode: string,
    adjacentCeiling: boolean,
    comments: string,
    roomUiid?: string,
    orderProduct?: OrderProduct,
  ) => Promise<string>;

  reset: () => void;
}

type OrderWizardStore = OrderWizardStoreValues & OrderWizardStoreActions;

const navigateToStep = (state: OrderWizardStore, activeStep: number) => {
  const {
    steps,
    order: { id },
  } = state;
  const stepName = steps[activeStep]?.name;
  if (stepName) {
    if (!history.location.pathname.endsWith(stepName)) history.push(`/order${id ? `/${id}/` : '/'}${stepName}`);
  }
  return { activeStep };
};

const getRoom = (roomsLenght: number): Room => ({
  uiId: uuidv4(),
  type: '',
  name: 'Room ' + (roomsLenght + 1),
  surface: 20,
  wastage: 0,
  substrate: '',
  system: '',
  thickness: '',
  coatType: '',
  tintType: tintTypes.standard,
  products: [],
  colorAgreementUrl: '',
});

const getInitialValues = (): OrderWizardStoreValues => ({
  isEditingProduct: false,
  steps,
  activeStep: steps.findIndex(s => appHistory.location.pathname.includes(s.name)),
  rooms: [getRoom(0)],
  order: { isGuided: true, products: [], language: useUserStore.getState().language },
  products: [],
  isLoadingOrder: false,
  tintedWithoutConfirmationReconfirmIds: [],
});

const resetScroll = () => window.scrollTo(0, 0);

export const useOrderWizardStore = create<OrderWizardStore>(
  devtools((set, get) => ({
    ...getInitialValues(),
    setIsEditingProduct: isEditingProduct => set({ isEditingProduct }),
    setProducts: products => set({ products }),
    setActiveStep: activeStep => set(state => navigateToStep(state, activeStep)),
    goToNextStep: () => {
      set(state => navigateToStep(state, state.activeStep + 1));
      resetScroll();
    },
    goToPreviousStep: () => {
      set(state => navigateToStep(state, state.activeStep - 1));
      resetScroll();
    },

    setIsGuided: isGuided => set({ order: { ...get().order, isGuided } }),

    updateRoom: (room, index) =>
      set(state => {
        state.rooms[index] = room;
        return {
          rooms: [...state.rooms],
        };
      }),
    addRoom: () =>
      set(state => {
        return {
          rooms: [getRoom(state.rooms.length), ...state.rooms],
        };
      }),
    deleteRoom: index => {
      const state = get();
      const room = state.rooms[index];
      const rooms = state.rooms.slice();
      rooms.splice(index, 1);
      set({
        rooms,
        products: state.products.filter(p => p.roomUiId !== room.uiId),
      });
      state.saveOrder();
    },

    setOrder: order => set({ order }),

    addProduct: (product: OrderProduct) =>
      set(state => {
        return { products: [...state.products, product] };
      }),

    removeProduct: (product: OrderProduct) =>
      set(state => {
        return { products: state.products.filter(p => p !== product) };
      }),

    editProduct: (oldOrderProduct, newOrderProduct) =>
      set(state => ({ products: state.products.map(p => (p === oldOrderProduct ? newOrderProduct : p)) })),

    saveOrder: status => {
      const company = useCompaniesStore.getState().selectedCompany;
      const { rooms, products, order } = get();
      useAppStore.getState().setAppLoading(true);
      return saveOrder({
        ...order,
        status: status || order.status,
        companyDiscount: company?.standardDiscount,
        companyId: company?.companyId,
        currency: company?.currency,
        products,
        rooms,
        country: company?.country,
        language: useUserStore.getState().language,
      })
        .then(order => {
          set({ order, products: order.products });
          useNotificationStore.getState().addNotification({ color: 'success', message: t('orders.savedSuccess') });
          return order;
        })
        .finally(() => useAppStore.getState().setAppLoading(false));
    },

    pickColorConfirmationFile: async (tintType, url, colorCode, adjacentCeiling, comments, roomUiid, orderProduct) => {
      return get().uploadColorConfirmationFile(tintType, url, colorCode, adjacentCeiling, comments, roomUiid, orderProduct);
    },

    uploadColorConfirmationFile: async (
      tintType,
      file,
      colorCode,
      adjacentCeiling,
      comments,
      roomUiid,
      orderProduct,
    ) => {
      const setIsAppLoading = useAppStore.getState().setAppLoading;
      setIsAppLoading(true);
      let orderId = get().order.id;
      if (!orderId) {
        orderId = (await get().saveOrder()).id;
      }
      let roomId: number | undefined;
      if (roomUiid) {
        let room = get().order.rooms?.find(r => r.uiId === roomUiid);
        roomId = room?.id;
        if (!roomId) {
          await get().saveOrder();
          room = get().order.rooms?.find(r => r.uiId === roomUiid);
          roomId = room?.id;
        }
      }

      let productId = orderProduct?.id;
      if (orderProduct && !productId) {
        get().addProduct({ ...orderProduct, defaultEditable: true });
        const order = await get().saveOrder();
        const lastProduct = last(order.products);
        const products = order.products.slice();
        productId = lastProduct?.id;
        products[products.length - 1] = { ...lastProduct!, defaultEditable: true };
        set({ products });
      }

      return uploadColorConfirmation(file, colorCode, orderId!, adjacentCeiling, comments, roomId, productId)
        .then(colorAgreementUrl => {
          if (productId && !roomUiid) {
            const { editProduct, products } = get();
            const product = products.find(p => p.id === productId);
            if (product) {
              editProduct(product, { ...product, colorCode, colorAgreementUrl, adjacentCeiling, comments });
            }
          }
          if (roomUiid) {
            const room = get().rooms.find(r => r.uiId === roomUiid);
            if (room) {
              room.id = roomId;
              room.colorAgreementUrl = colorAgreementUrl;
              room.colorCode = colorCode;
              room.tintType = tintType;
              room.adjacentCeiling = adjacentCeiling;
              room.comments = comments;
            }
          }
          get().saveOrder();
          return colorAgreementUrl;
        })
        .finally(() => {
          setIsAppLoading(false);
        });
    },

    loadOrder: orderId => {
      set({ isLoadingOrder: true });
      getOrder(orderId)
        .then(order => {
          set({ order, products: order.products || [], rooms: order.rooms || [] });
          useCompaniesStore.getState().setSelectedCompany(order.companyId);
        })
        .finally(() => set({ isLoadingOrder: false }));
    },

    reset: () => set({ ...getInitialValues() }),
  })),
);
