import styled from 'styled-components';

export const AccountControlContainer = styled.div`
  border-left: 1px solid ${p => p.theme.palette.divider};
  padding: 0 ${p => p.theme.spacing(2)};
  display: flex;
  justify-content: center;
  align-items: center;

  ${p => p.theme.breakpoints.down('md')} {
    padding-top: ${p => p.theme.spacing(2)};
    border-top: 1px solid ${p => p.theme.palette.divider};
    border-left: none;
    justify-content: flex-start;
    align-items: center;
  }
`;

export const NameContainer = styled.span`
  margin-left: ${p => p.theme.spacing(2)};
`;
