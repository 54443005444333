import { TextField } from '@mui/material';
import { useTranslation } from 'react-i18next';

export const ContactPersonFields: React.FC<{ formik: any }> = ({ formik }) => {
  const { t } = useTranslation();
  return (
    <>
      <TextField
        fullWidth
        id="contactLastName"
        name={`contactFirstName`}
        label={t('project.contactPersonFirstName')}
        value={formik.values.contactFirstName}
        onChange={formik.handleChange}
        error={formik.touched.contactFirstName && Boolean(formik.errors.contactFirstName)}
        helperText={formik.touched.contactFirstName && formik.errors.contactFirstName}
      />
      <TextField
        fullWidth
        id="contactLastName"
        name={`contactLastName`}
        label={t('project.contactPersonLastName')}
        value={formik.values.contactLastName}
        onChange={formik.handleChange}
        error={formik.touched.contactLastName && Boolean(formik.errors.contactLastName)}
        helperText={formik.touched.contactLastName && formik.errors.contactLastName}
      />
      <TextField
        fullWidth
        id="contactEmail"
        name="contactEmail"
        label={t('common.email')}
        value={formik.values.contactEmail}
        onChange={formik.handleChange}
        error={formik.touched.contactEmail && Boolean(formik.errors.contactEmail)}
        helperText={formik.touched.contactEmail && formik.errors.contactEmail}
      />
      <TextField
        fullWidth
        id="phoneNumber"
        name="phoneNumber"
        label={t('common.phoneNumber')}
        value={formik.values.phoneNumber}
        onChange={formik.handleChange}
        error={formik.touched.phoneNumber && Boolean(formik.errors.phoneNumber)}
        helperText={formik.touched.phoneNumber && formik.errors.phoneNumber}
      />
    </>
  );
};
