import { Autocomplete, Box, TextField } from '@mui/material';
import { countries } from '../../../../constants/countries';
import { useTranslation } from 'react-i18next';

export const CountryDropdown: React.FC<{ formik: any; addressAccessor: string }> = ({ formik, addressAccessor }) => {
  const { t } = useTranslation();
  const country = countries.find(c => c.code === formik.values[addressAccessor]?.country) || null;
  return (
    <Autocomplete
      id="country"
      options={countries}
      getOptionLabel={option => option.label}
      defaultValue={country}
      value={country}
      onChange={(e, value) => {
        formik.setFieldValue(`${addressAccessor}.country`, value ? value.code : '');
      }}
      renderOption={(props, option) => (
        <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
          <img
            loading="lazy"
            width="20"
            src={`https://flagcdn.com/w20/${option.code.toLowerCase()}.png`}
            srcSet={`https://flagcdn.com/w40/${option.code.toLowerCase()}.png 2x`}
            alt=""
          />
          {option.label} ({option.code})
        </Box>
      )}
      renderInput={params => (
        <TextField
          {...params}
          label={t('delivery.address.country')}
          inputProps={{
            ...params.inputProps,
            autoComplete: 'new-password', // disable autocomplete and autofill
          }}
          error={formik.touched[addressAccessor]?.country && Boolean(formik.errors[addressAccessor]?.country)}
          helperText={formik.touched[addressAccessor]?.country && formik.errors[addressAccessor]?.country}
        />
      )}
    />
  );
};
