import styled from 'styled-components';

export const OrderWizardPageContainer = styled.div``;

export const OrderWizardStepContainer = styled.div`
  margin-top: ${p => p.theme.spacing(2)};
  ${p => p.theme.breakpoints.up('md')} {
    margin-top: ${p => p.theme.spacing(7)};
  }
`;

export const WizzardNavigationButtonsContainer = styled.div`
  margin-top: ${p => p.theme.spacing(7)};
  display: flex;
`;
