import Grow from '@mui/material/Grow';
import { useTranslation } from 'react-i18next';
import { Button } from '../../../core/button/Button';
import { WizzardNavigationButtonsContainer } from '../../OrderWizardPage.styles';
import ProductList from '../selectProducts/products/productList/ProductList';
import PendingRequest from '../reviewOrder/pendingRequest/PendingRequest';
import { useUserStore } from '../../../../stores/userStore';
import { ReviewDeliveryDetails } from './reviewDeliveryDetails/ReviewDeliveryDetails';
import { EditOrderButton } from '../common/EditOrderButton';
import { ActualDeliveryInfo } from '../orderStatus/actualDeliveryInfo/DeliveryInfo';
import { DownloadOrderButton } from '../common/DownloadOrderButton';
import { Box } from '@mui/material';
import shallow from 'zustand/shallow';
import { useOrderWizardStore } from '../../../../stores/orderWizardStore';

interface IConfirmOrderWizardStepProps {}

const ReviewOrderWizardStep: React.FunctionComponent<IConfirmOrderWizardStepProps> = () => {
  const { t } = useTranslation();
  const [isAdmin, isSales] = useUserStore(s => [s.isAdmin, s.isSales], shallow);

  const order = useOrderWizardStore(s => s.order);

  return isAdmin ? (
    <Grow in>
      <div>
        <ReviewDeliveryDetails />
        <ProductList priceCustomizationEnabled readOnly showAllProducts showAdditionalProjectDiscount />
        <WizzardNavigationButtonsContainer>
          <EditOrderButton />
          <Box sx={{ marginLeft: 'auto' }}>
            <DownloadOrderButton />
          </Box>
          <Button type="submit" form="order-wizard-review-form" color="success">
            {t('common.approve')}
          </Button>
        </WizzardNavigationButtonsContainer>
      </div>
    </Grow>
  ) : (
    <Grow in>
      <div>
        <PendingRequest />
        <ActualDeliveryInfo hideBaswaDetails />
        <ProductList
          readOnly
          showAllProducts
          showAdditionalProjectDiscount={isSales || isAdmin || (order?.projectDiscountPercentage || 0) > 0}
          readonlyAdditionalProjectDiscount={!isAdmin}
        />
        <WizzardNavigationButtonsContainer>
          <Box sx={{ marginLeft: 'auto' }}>
            <DownloadOrderButton />
          </Box>
        </WizzardNavigationButtonsContainer>
      </div>
    </Grow>
  );
};

export default ReviewOrderWizardStep;
