import { LockClock } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';
import { OrderConfirmedContainer } from './OrderConfirmed.styles';

const OrderClosed: React.FunctionComponent = props => {
  const { t } = useTranslation();
  return (
    <OrderConfirmedContainer>
      <div>
        <LockClock />
      </div>

      <h3>{t('orders.orderClosed')}</h3>
      <div>{t('orders.orderClosedChanges')}</div>
    </OrderConfirmedContainer>
  );
};

export default OrderClosed;
