import styled from 'styled-components';

export const LanguageControlContainer = styled.div`
  width: 70px;
  display: flex;
  align-items: center;

  margin-left: auto;

  fieldset {
    border: none;
  }
`;
