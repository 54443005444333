import { Fab } from '@mui/material';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

export const NewOrderFabLink = styled(Link)`
  margin-left: ${p => p.theme.spacing(1)};
  text-decoration: none;
  color: inherit;
`;

export const FabContainer = styled(Fab)`
  text-align: center;
  margin-right: ${p => p.theme.spacing(4)};
  transition: all 0.3s;
  background: ${p => p.theme.palette.primary.main};
  color: ${p => p.theme.palette.primary.contrastText};
  height: auto;
  padding: ${p => p.theme.spacing(1)} ${p => p.theme.spacing(2)};

  ${p => p.theme.breakpoints.down('md')} {
    width: 100%;
  }

  & > span {
    margin-left: ${p => p.theme.spacing(1)};
  }

  &:hover {
    color: ${p => p.theme.palette.text.primary};
    background: ${p => p.theme.palette.secondary.main};
  }
`;
