import { ApiResponse } from '../../models/ApiResponse';
import { Product } from '../../models/Product';
import { useUserStore } from '../../stores/userStore';
import { base64ToArrayBuffer, createAndDownloadBlobFile } from '../utils/downloadFile';
import { http } from './http';

export const getProducts = (companyId: string) =>
  http
    .get<ApiResponse<Product[]>>('product', {
      params: { companyId, language: useUserStore.getState().language },
    })
    .then(r => r.data.payload);

export const getProductCatalog = (companyId: string, language: string) => {
  return http
    .get<ApiResponse<{ file: string; fileName: string }>>('product/catalog', { params: { companyId, language } })
    .then(r => {
      const arrayBuffer = base64ToArrayBuffer(r.data.payload.file);
      createAndDownloadBlobFile(arrayBuffer, r.data.payload.fileName);
    });
};
