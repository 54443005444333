import { format } from 'date-fns';
import { ShippingAddress } from '../../models/ShippingAddress';

export const formatDate = (dateString: string) => format(new Date(dateString), 'dd. MMM. yyyy');
export const formatTime = (dateString: string) => format(new Date(dateString), 'HH:mm');
export const formatAddress = (address?: ShippingAddress) => {
  if (address) {
    const addressFields = [
      address.nameOfBuilding,
      address.street,
      address.addressLine2,
      address.postcode,
      address.city,
      address.country,
    ].filter(v => !!v);
    return addressFields.join(', ');
  }
  return '-';
};
