import { Edit } from '@mui/icons-material';
import { Box, IconButton, Link } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { tintTypes } from '../../../../../constants/tintTypes';
import { ImportantActionsContainer } from '../../selectProducts/room/roomForm/RoomForm.styles';
import { ConfirmedContainer, ConfirmedInfoContainer } from './ColorAgreement.styles';
import { ColorAgreementDialog } from './ColorAgreementDialog';
import { useUserStore } from '../../../../../stores/userStore';
import { getColorConfirmationTemplateUrl } from '../../../../../services/environment/environment';
import { useMemo } from 'react';

interface Props {
  colorCode?: string;
  uploadedFileUrl?: string;
  mode: 'tintedWithConfirmation' | 'tintedWithoutConfirmation';
  onUpload: (file: File, colorCode: string, adjacentCeiling: boolean, comments: string) => Promise<any>;
  onPickFile: (url: string, colorCode: string, adjacentCeiling: boolean, comments: string) => Promise<any>;
  onRemoveFile: () => void;
}

export const ColorAgreementInfoBox: React.FC<Props> = ({
  colorCode,
  uploadedFileUrl,
  mode,
  onUpload,
  onRemoveFile,
  onPickFile,
}) => {
  const { t } = useTranslation();

  const isValid = !!(colorCode && uploadedFileUrl);

  const language = useUserStore(s => s.language);

  const templateUrl = useMemo(() => getColorConfirmationTemplateUrl(language), [language]);

  return (
    <ImportantActionsContainer success={isValid}>
      {isValid ? (
        <ConfirmedContainer>
          <ConfirmedInfoContainer target="_blank" rel="noopener noreferrer" href={uploadedFileUrl}>
            {t('rooms.confirmedColorInfo', { colorCode })}
          </ConfirmedInfoContainer>
          <IconButton size="small" onClick={onRemoveFile}>
            <Edit />
          </IconButton>
        </ConfirmedContainer>
      ) : (
        <div>
          {mode === tintTypes.tintedWithConfirmation
            ? t('rooms.tintedWithConfimationInfo')
            : t('rooms.tintedWithoutConfimationInfo')}
        </div>
      )}
      {!isValid && (
        <ColorAgreementDialog
          onPickFile={onPickFile}
          onUpload={onUpload}
          buttonLabel={
            mode === tintTypes.tintedWithConfirmation
              ? t('rooms.uploadColorConfirmation')
              : t('rooms.uploadSignedAgreement')
          }
        />
      )}
      {mode === 'tintedWithoutConfirmation' && (
        <Box sx={{ marginTop: 1 }}>
          <Link rel="noopener noreferrer" target="_blank" href={templateUrl}>
            {t('rooms.downloadAgreementSample')}
          </Link>
        </Box>
      )}
    </ImportantActionsContainer>
  );
};
