import axios, { ResponseType } from 'axios';
import registerAuthInterceptor from '../auth/authInterceptors';
import { getApiBaseURL } from '../environment/environment';

const defaultHttpSettings = {
  responseType: 'json' as ResponseType,
  headers: {
    'Content-Type': 'application/json',
  },
  baseURL: `${getApiBaseURL()}`,
};

export const http = registerAuthInterceptor(axios.create(defaultHttpSettings));
