import TextField from '@mui/material/TextField';
import * as yup from 'yup';
import { useFormik } from 'formik';
import { useTranslation } from 'react-i18next';
import { DeliveryDetailsWizardViewForm, SelectExistingProjectContainer } from './DeliveryDetailsWizardStep.styles';
import DatePicker from '@mui/lab/DatePicker';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import Grow from '@mui/material/Grow';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import { useEffect, useState } from 'react';
import { Dropdown } from '../../../core/dropdown/Dropdown';
import { useProjectsStore } from '../../../../stores/projectsStore';
import InfoButton from '../../../core/infoButton/InfoButton';
import { WizzardNavigationButtonsContainer } from '../../OrderWizardPage.styles';
import { Button } from '../../../core/button/Button';
import { useOrderWizardStore } from '../../../../stores/orderWizardStore';
import shallow from 'zustand/shallow';
import { ImportantActionsContainer } from '../selectProducts/room/roomForm/RoomForm.styles';
import { OrderDeliveryDetails } from '../../../../models/OrderDeliveryDetails';
import { useCompaniesStore } from '../../../../stores/companiesStore';
import { addDays } from 'date-fns';
import { SaveButton } from '../common/SaveButton';
import { AddressFields } from '../common/AddressFields';
import { Box } from '@mui/material';
import { ContactPersonFields } from '../common/ContactPersonFields';
import { Order } from '../../../../models/Order';

const formId = 'wizard-step-delivery-details';

type FormType = OrderDeliveryDetails & { buildingType: string; projectName: string };

export const DeliveryDetailsWizardStep: React.FC = () => {
  const { t } = useTranslation();
  const [projects] = useProjectsStore(s => [s.projects], shallow);

  const [goToPreviousStep, order, saveOrder, setOrder] = useOrderWizardStore(
    s => [s.goToPreviousStep, s.order, s.saveOrder, s.setOrder],
    shallow,
  );

  const selectedCompany = useCompaniesStore(s => s.selectedCompany);
  const loadProjects = useProjectsStore(s => s.loadProjects);

  const [selectExistingProject, setSelectExistingProject] = useState<boolean>(order && !!order.projectId);
  const [deliverToWarehouse, setDeliverToWarehouse] = useState(false);

  const requiredMessage = t('validations.required');
  const emailValidation = t('validations.email');

  const projectValidation = {
    projectName: yup.string(),
    buildingType: yup.string(),
  };

  const validationSchema = yup.object({
    requestedDeliveryDate: yup.date().typeError(requiredMessage).required(requiredMessage),
    shippingAddress: yup.object().shape({
      nameOfBuilding: yup.string().optional(),
      street: yup.string().required(requiredMessage),
      addressLine2: yup.string(),
      postcode: yup.string().required(requiredMessage),
      city: yup.string().required(requiredMessage),
      country: yup.string().required(requiredMessage),
    }),
    contactFirstName: yup.string().required(requiredMessage),
    contactLastName: yup.string().required(requiredMessage),
    contactEmail: yup.string().email(emailValidation).required(requiredMessage),
    phoneNumber: yup.string().required(requiredMessage),
    deliveryDetails: yup.string(),

    ...(selectExistingProject ? projectValidation : {}),
  });

  const initialValues = {
    projectId: order?.projectId || null,
    buildingType: order?.project?.buildingType || '',
    projectName: order?.project?.name || '',
    shippingAddress: order.shippingAddress || {
      city: '',
      country: '',
      postcode: '',
      street: '',
      addressLine2: '',
      nameOfBuilding: '',
    },
    contactFirstName: order?.contactFirstName || '',
    contactLastName: order?.contactLastName || '',
    contactEmail: order?.contactEmail || '',
    phoneNumber: order?.phoneNumber || '',
    requestedDeliveryDate: order?.requestedDeliveryDate ? new Date(order.requestedDeliveryDate) : null,
    deliveryDetails: order?.deliveryDetails || '',
  };

  const formik = useFormik<FormType>({
    initialValues: initialValues as any,
    validationSchema: validationSchema,
    onSubmit: values => {
      updateOrder(values);
      saveOrder('Submitted');
    },
  });

  const updateOrder = (formValues?: FormType) => {
    const values = formValues || formik.values;
    const project =
      !selectExistingProject && values.projectName
        ? { name: values.projectName, buildingType: values.buildingType }
        : null;
    const projectId = selectExistingProject ? values.projectId || order.projectId : null;
    setOrder({ ...order, ...values, project, projectId });
  };

  const handleSavedOrder = (newOrder: Order) => {
    if (selectedCompany && newOrder.projectId && formik.values.projectId !== newOrder.projectId) {
      loadProjects(selectedCompany?.companyId).then(() => {
        setSelectExistingProject(true);
        formik.setFieldValue('project', newOrder.projectId);
      });
    }
  };

  useEffect(() => {
    if (selectedCompany) {
      loadProjects(selectedCompany.companyId);
    }
  }, [loadProjects, selectedCompany]);

  return (
    <>
      <Grow in={true}>
        <DeliveryDetailsWizardViewForm id={formId} onSubmit={formik.handleSubmit}>
          <SelectExistingProjectContainer>
            <FormControlLabel
              control={
                <Checkbox
                  checked={selectExistingProject}
                  onChange={e => {
                    formik.setFieldValue('projectName', '');
                    formik.setFieldValue('buildingType', '');
                    setSelectExistingProject(e.target.checked);
                  }}
                />
              }
              label={t('project.selectExistingProject') as any}
            />
            {!selectExistingProject && <InfoButton content={t('project.saveProjectInfo')} />}
          </SelectExistingProjectContainer>

          {selectExistingProject ? (
            <Dropdown
              fullWidth
              name="project"
              label={t('common.project')}
              options={projects.map(p => ({ label: p.name, value: p.id!.toString() }))}
              onChange={e => {
                const projectId = e.target.value;
                const project = projects.find(p => p.id === +projectId)!;
                formik.setFieldValue('projectId', project.id);
                formik.setFieldValue('contactFirstName', project.contactFirstName);
                formik.setFieldValue('contactLastName', project.contactLastName);
                formik.setFieldValue('contactEmail', project.contactEmail);
                formik.setFieldValue('phoneNumber', project.phoneNumber);
                if (!deliverToWarehouse) {
                  formik.setFieldValue('shippingAddress', project.address);
                }
              }}
              defaultValue={order.projectId || ''}
            />
          ) : (
            <>
              <TextField
                fullWidth
                id="projectName"
                name="projectName"
                label={`${t('project.projectName')} (${t('common.optional')})`}
                value={formik.values.projectName}
                onChange={formik.handleChange}
                error={formik.touched.projectName && Boolean(formik.errors.projectName)}
                helperText={formik.touched.projectName && formik.errors.projectName}
              />
              <TextField
                fullWidth
                id="buildingType"
                name="buildingType"
                label={t('project.buildingType')}
                value={formik.values.buildingType}
                onChange={formik.handleChange}
                error={formik.touched.buildingType && Boolean(formik.errors.buildingType)}
                helperText={formik.touched.buildingType && formik.errors.buildingType}
              />
            </>
          )}

          <div>
            <FormControlLabel
              control={
                <Checkbox
                  checked={deliverToWarehouse}
                  onChange={e => {
                    const isChecked = e.target.checked;
                    setDeliverToWarehouse(isChecked);
                    if (isChecked) {
                      formik.setFieldValue('shippingAddress.street', selectedCompany?.address || '');
                      formik.setFieldValue('shippingAddress.postcode', selectedCompany?.postCode || '');
                      formik.setFieldValue('shippingAddress.city', selectedCompany?.town || '');
                      formik.setFieldValue('shippingAddress.country', selectedCompany?.country || '');
                    }
                  }}
                />
              }
              label={t('delivery.deliverToOurWarehouse') as any}
            />
          </div>

          <AddressFields addressAccessor="shippingAddress" formik={formik} />

          <ContactPersonFields formik={formik} />

          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DatePicker
              minDate={addDays(new Date(), 1)}
              inputFormat={'dd. MMM. yyyy'}
              label={t('delivery.requestedDeliveryDate')}
              value={formik.values.requestedDeliveryDate}
              onChange={date => formik.setFieldValue('requestedDeliveryDate', date)}
              renderInput={params => (
                <TextField
                  fullWidth
                  {...params}
                  error={formik.touched.requestedDeliveryDate && Boolean(formik.errors.requestedDeliveryDate)}
                  helperText={formik.touched.requestedDeliveryDate && formik.errors.requestedDeliveryDate}
                />
              )}
            />
          </LocalizationProvider>

          {formik.values.requestedDeliveryDate && (
            <ImportantActionsContainer>{t('delivery.leadTime')}</ImportantActionsContainer>
          )}

          {((formik.values.requestedDeliveryDate as any as Date)?.getMonth() >= 10 ||
            (formik.values.requestedDeliveryDate as any as Date)?.getMonth() <= 1) && (
            <ImportantActionsContainer>{t('delivery.thermoTransport')}</ImportantActionsContainer>
          )}

          <TextField
            fullWidth
            multiline
            rows={10}
            id="deliveryDetails"
            name="deliveryDetails"
            label={t('delivery.deliveryDetails')}
            value={formik.values.deliveryDetails}
            onChange={formik.handleChange}
            error={formik.touched.deliveryDetails && Boolean(formik.errors.deliveryDetails)}
            helperText={formik.touched.deliveryDetails && formik.errors.deliveryDetails}
          />
        </DeliveryDetailsWizardViewForm>
      </Grow>
      <WizzardNavigationButtonsContainer>
        <Button onClick={goToPreviousStep}>{t('common.previous')}</Button>
        <Box sx={{ marginLeft: 'auto' }}>
          <SaveButton onClick={updateOrder} onSaved={handleSavedOrder} />
        </Box>
        <Button form={formId} type="submit">
          {t('orders.sendRequest')}
        </Button>
      </WizzardNavigationButtonsContainer>
    </>
  );
};
