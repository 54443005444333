import { Tooltip } from '@mui/material';
import styled from 'styled-components';

export const InfoButtonContainer = styled.span<{ marginRight?: boolean; help?: boolean }>`
  &:hover {
    cursor: ${p => (p.help ? 'help' : 'inherit')};
  }

  display: flex;
  align-items: center;
  color: ${p => p.theme.palette.secondary.main};
  margin: 0 ${p => (p.marginRight ? p.theme.spacing(1) : 0)} 0 ${p => (!p.marginRight ? p.theme.spacing(1) : 0)};
`;

export const TooltipContent = styled.div`
  padding: ${p => p.theme.spacing(1)};
`;

export const StyledTooltip = styled(Tooltip)`
  font-size: 20px;
`;
