import create from 'zustand';
import { getIsTraining } from '../services/environment/environment';
import confirm from '../services/confirm/confirm';
import { t } from 'i18next';
import { publishDraftChanges } from '../services/http/draftMode';
import { useNotificationStore } from './notificationStore';

interface StoreActions {
  setAppLoading: (isLoading: boolean) => void;
  setIsInDraftMode: (draftMode: boolean) => void;
  publishDraftModeChanges: () => void;
}

interface StoreValues {
  isAppLoading: boolean;
  isTraining: boolean;
  isInDraftMode: boolean;
}

const draftModeCookieKey = 'DRAFT_MODE';

const getIsInDraftMode = () => !!localStorage.getItem(draftModeCookieKey);
const defaultValues: StoreValues = {
  isAppLoading: false,
  isTraining: getIsTraining(),
  isInDraftMode: getIsInDraftMode(),
};

const exitDraftMode = () => {
  localStorage.removeItem(draftModeCookieKey);
  window.location.reload();
};

export const useAppStore = create<StoreActions & StoreValues>((set, get) => ({
  ...defaultValues,
  setAppLoading: isAppLoading => set({ isAppLoading }),
  setIsInDraftMode: async isInDraftMode => {
    if (isInDraftMode) {
      if (await confirm(t('draftMode.confirmEnterDraftMode'))) {
        localStorage.setItem(draftModeCookieKey, 'TRUE');
        window.location.reload();
      }
    } else {
      if (await confirm(t('draftMode.confirmExitDraftMode'))) {
        exitDraftMode();
      }
    }
  },
  publishDraftModeChanges: async () => {
    if (await confirm(t('draftMode.confirmPublishChanges'))) {
      set({ isAppLoading: true });
      publishDraftChanges()
        .then(async () => {
          useNotificationStore.getState().addNotification({ color: 'success', message: t('draftMode.publishSuccess') });
          set({ isAppLoading: false });
          if (await confirm(t('draftMode.publishSuccessDialog'), { title: t('draftMode.publishSuccessTitle') })) {
            exitDraftMode();
          }
        })
        .catch(() => set({ isAppLoading: false }));
    }
  },
}));
