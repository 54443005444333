import { Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from '../../../../core/button/Button';
import { ColorAgreementForm } from './ColorAgreementForm';

interface Props {
  buttonLabel: string;
  onUpload: (file: File, colorCode: string, adjacent: boolean, comments: string) => Promise<any>;
  onPickFile: (url: string, colorCode: string, adjacentCeiling: boolean, comments: string) => Promise<any>;
}

export const ColorAgreementDialog: React.FC<Props> = ({ buttonLabel, onUpload, onPickFile }) => {
  const [isOpen, setIsOpen] = useState(false);

  const [isFullScreen, setIsFullScreen] = useState(false);

  const { t } = useTranslation();

  const handleUpload = (file: File, colorCode: string, adjacentCeiling: boolean, comments: string) => {
    onUpload(file, colorCode, adjacentCeiling, comments).then(() => setIsOpen(false));
  };

  const handlePickFile = (url: string, colorCode: string, adjacentCeiling: boolean, comments: string) => {
    onPickFile(url, colorCode, adjacentCeiling, comments).then(() => setIsOpen(false));
  };

  return (
    <>
      <Button onClick={() => setIsOpen(true)}>{buttonLabel}</Button>
      {isOpen && (
        <Dialog maxWidth={isFullScreen ? 'xl' : undefined} fullWidth={isFullScreen} onClose={() => setIsOpen(false)} open>
          <DialogTitle>{buttonLabel}</DialogTitle>
          <DialogContent>
            <ColorAgreementForm setFullScreen={setIsFullScreen} onUpload={handleUpload} onPickFile={handlePickFile} />
          </DialogContent>
          <DialogActions>
            <Button variant="outlined" onClick={() => setIsOpen(false)}>
              {t('common.cancel')}
            </Button>
            <Button form="uploadColorAgreementForm" type="submit">
              {t('common.upload')}
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </>
  );
};
