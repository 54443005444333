import styled, { css } from 'styled-components';

export const MobileListItemContainer = styled.div<{ isSelected: boolean }>`
  padding: ${p => p.theme.spacing(2)} ${p => p.theme.spacing(1)};
  flex: 1;

  ${p =>
    p.isSelected &&
    css`
      background-color: rgba(0, 0, 0, 0.12);
    `}

  & > *:not(:last-child) {
    margin-bottom: ${p => p.theme.spacing(1)};
  }

  &:not(:first-child) {
    border-top: 1px solid ${p => p.theme.palette.divider};
  }

  display: flex;
`;

export const Header = styled.div`
  display: flex;
  margin-bottom: ${p => p.theme.spacing(1)};
`;

export const Title = styled.div`
  font-weight: 500;
  margin-bottom: ${p => p.theme.spacing(0.5)};
`;

export const TitleContainer = styled.div`
  padding-right: ${p => p.theme.spacing(0.5)};
  width: 60%;
  word-break: break-word;
`;

export const Subtitle = styled.div``;

export const TopLeftDetailsContainer = styled.div`
  margin-left: auto;
`;

export const TopLeftDetail = styled.div`
  font-size: 0.8rem;
  color: ${p => p.theme.palette.grey[500]};
  text-align: right;
`;

export const ContentContainer = styled.div``;

export const InlineActionsContainer = styled.div`
  margin-top: ${p => p.theme.spacing(1)};
`;

export const ActionsContainer = styled.div`
  display: flex;
`;
