import Button from '@mui/material/Button';
import Collapse from '@mui/material/Collapse';
import styled from 'styled-components';

export const RoomContainer = styled.div<{ isOther: boolean }>`
  margin: ${p => p.theme.spacing(2)} 0;
`;

export const RoomHeader = styled.div<{ isOther: boolean }>`
  background: ${p => (p.isOther ? p.theme.palette.grey[300] : p.theme.palette.secondary.main)};
  display: flex;
  align-items: center;
`;

export const RoomTitle = styled.span`
  margin-right: auto;
  display: flex;
  align-items: center;
`;

export const RoomContentContainer = styled.div`
  display: flex;
  min-height: 200px;
`;

export const CollapsibleRoom = styled(Collapse)`
  transition: all 0.3s;
`;

export const ExpandRoomButton = styled(Button)`
  display: flex;
  width: 100%;
  padding: ${p => p.theme.spacing(2)};
  font-size: 20px;
  background: ${p => p.theme.palette.background.default};
  border-bottom: 1px solid black;
`;

export const RoomSideBar = styled.div`
  background: ${p => p.theme.palette.secondary.main};
  width: 40px;
`;

export const RoomProducts = styled.div`
  margin: 0 ${p => p.theme.spacing(1)};
  flex: 1;
`;
