import styled from 'styled-components';

export const ActualDeliveryInfoContainer = styled.div`
  border: 2px solid black;
  padding: ${p => p.theme.spacing(1)};
  margin-bottom: ${p => p.theme.spacing(2)};
`;
export const InfoRow = styled.div<{ isWarning?: boolean }>`
  display: flex;

  & div {
    flex: 1;
  }
  margin-bottom: ${p => p.theme.spacing(2)};

  color: ${p => (p.isWarning ? p.theme.palette.warning.main : p.theme.palette.primary.main)};
`;

export const Separator = styled.div`
  border-bottom: 1px solid ${p => p.theme.palette.divider};
  margin: ${p => p.theme.spacing(4)} 0;
`;
