import { OrderStatus } from '../../constants/orderStatus';
import { ApiResponse } from '../../models/ApiResponse';
import { Order } from '../../models/Order';
import { base64ToArrayBuffer, createAndDownloadBlobFile } from '../utils/downloadFile';
import { http } from './http';

export const statusColors: { [key in OrderStatus]: string } = {
  Open: '#2dccff',
  Closed: '#9ea7ad',
  Submitted: '#ffb302',
  NotYetSigned: '#fce83a',
  Signed: '#56f000',
};

export const downloadOrder = (orderId: number, language: string) => {
  return http
    .get<ApiResponse<{ file: string; fileName: string }>>(`orders/${orderId}/export`, { params: { language } })
    .then(r => {
      const arrayBuffer = base64ToArrayBuffer(r.data.payload.file);
      createAndDownloadBlobFile(arrayBuffer, r.data.payload.fileName);
    });
};

export const getOrder = (orderId: number) =>
  http.get<ApiResponse<Order>>(`orders/${orderId}`).then(r => r.data.payload);

export const deleteOrder = (orderId: number) =>
  http.delete<ApiResponse<Order>>(`orders/${orderId}`).then(r => r.data.payload);

export const getOrders = (companyId?: string) =>
  http.get<ApiResponse<Order[]>>('orders', { params: { companyId } }).then(r => r.data.payload);

export const saveOrder = (order: Order) => {
  return http.post<ApiResponse<Order>>('orders', order).then(r => r.data.payload);
};

const getBase64FromFile = (file: File) => {
  return new Promise(resolve => {
    let reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      resolve((reader.result as string).split(',')[1]);
    };
  });
};

export const uploadColorConfirmation = async (
  fileOrUrl: File | string,
  colorCode: string,
  orderId: number,
  adjacentCeiling: boolean,
  comments: string,
  roomId?: number,
  productId?: number,
) => {
  let file: any = undefined;
  let colorAgreementUrl: string | undefined = undefined;
  if (typeof fileOrUrl === 'string') {
    colorAgreementUrl = fileOrUrl;
  } else {
    file = await getBase64FromFile(fileOrUrl);
  }
  return http
    .post<ApiResponse<string>>('orders/colorConfirmation', {
      orderId,
      colorAgreementUrl,
      roomId,
      productId,
      adjacentCeiling,
      comments,
      colorCode,
      file,
    })
    .then(r => r.data.payload);
};
