import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Checkbox,
  FormControlLabel,
  FormControl,
  Radio,
  RadioGroup,
} from '@mui/material';
import * as React from 'react';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from '../button/Button';

interface IConfirmationProps {
  onReject: () => void;
  onConfirm: (choice?: string) => void;
  title?: string;
  message: string;
  yesLabel?: string;
  noLabel?: string;
  checkboxConfirmationMessage?: string;
  choices?: { label: string; value: string }[];
}

const Confirmation: React.FunctionComponent<IConfirmationProps> = ({
  onReject,
  onConfirm,
  title,
  message,
  noLabel,
  yesLabel,
  checkboxConfirmationMessage,
  choices,
}) => {
  const { t } = useTranslation();

  const [isConfirmed, setIsConfirmed] = useState(false);

  const [selectedChoice, setChoice] = useState(choices?.[0]?.value);

  return (
    <Dialog open={true}>
      <DialogTitle>{title || t('common.areYouSure')}</DialogTitle>
      <DialogContent>
        <div>{message}</div>
        {checkboxConfirmationMessage && (
          <FormControlLabel
            sx={{ marginTop: 2 }}
            control={
              <Checkbox
                checked={isConfirmed}
                onChange={e => {
                  setIsConfirmed(e.target.checked);
                }}
              />
            }
            label={checkboxConfirmationMessage}
          />
        )}
        {choices && (
          <FormControl>
            <RadioGroup
              onChange={e => setChoice(e.target.value)}
              defaultValue={selectedChoice}
            >
              {choices.map(c => (
                <FormControlLabel key={c.value} value={c.value} control={<Radio size="small" />} label={c.label} />
              ))}
            </RadioGroup>
          </FormControl>
        )}
      </DialogContent>

      <DialogActions>
        <Button onClick={onReject}>{noLabel || t('common.no')}</Button>
        <Button
          disabled={!!checkboxConfirmationMessage && !isConfirmed}
          onClick={() => onConfirm(selectedChoice)}
          autoFocus
        >
          {yesLabel || t('common.yes')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default Confirmation;
