import styled from 'styled-components';
import { AppFormStyles } from '../../../../../core/Core.styles';

export const Form = styled.form`
  padding: ${p => p.theme.spacing(2)};
  border-bottom: 2px solid ${p => p.theme.palette.secondary.main};
`;
export const FormSectionContainer = styled.div`
  ${AppFormStyles}
  flex: 1;
  &:first-child {
    margin-right: ${p => p.theme.spacing(1)};
  }
  &:last-child {
    margin-left: ${p => p.theme.spacing(1)};
  }
`;

export const FormSectionsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  & > * {
    margin: ${p => p.theme.spacing(1)};

    width: 31%;

    ${p => p.theme.breakpoints.down('md')} {
      width: 45%;
    }
    ${p => p.theme.breakpoints.down('sm')} {
      width: 95%;
    }
  }

  ${p => p.theme.breakpoints.down('sm')} {
    flex-direction: column;
  }
`;

export const FormTitle = styled.div`
  margin-bottom: ${p => p.theme.spacing(2)};
  font-size: 20px;
`;

export const ButtonsContainer = styled.div`
  margin-top: ${p => p.theme.spacing(4)};
  display: flex;
  justify-content: space-between;
`;

export const ImportantActionsContainer = styled.div<{ success?: boolean }>`
  border: 2px solid ${p => (p.success ? p.theme.palette.success.main : p.theme.palette.warning.main)};
  padding: ${p => p.theme.spacing(p.success ? 0.20 : 1)} ${p => p.theme.spacing(p.success ? 1.5 : 2)};
`;

export const WastageContainer = styled.div`
  display: flex;
  align-items: center;
  font-size: 0.85rem;
`;

export const WastageBox = styled.div`
  flex: 1;
  height: 40px;
`;
