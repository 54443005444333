import { useTranslation } from 'react-i18next';
import { ResponsiveGridColumns } from '../../../../../models/ResponsiveGridColumn';
import { ResponsiveDataGrid } from '../../../../core/responsiveDataGrid/ResponsiveDataGrid';
import { useMemo, useState } from 'react';
import { useOrdersStore } from '../../../../../stores/ordersStore';
import { useCompaniesStore } from '../../../../../stores/companiesStore';
import { useProjectsStore } from '../../../../../stores/projectsStore';
import { Project } from '../../../../../models/Project';
import { Order } from '../../../../../models/Order';
import { Link } from '@mui/material';
import { ColorConfirmationFile } from '../../../../../models/CompanyFile';
import { formatDate } from '../../../../../services/utils/format';
import { GRID_CHECKBOX_SELECTION_COL_DEF, GridSelectionModel } from '@mui/x-data-grid';
import { AppLink } from '../../../../core/Core.styles';

export const UploadedFilesPicker: React.FC<{
  onFilePick: (url: string, colorCode: string) => void;
}> = ({ onFilePick }) => {
  const { t } = useTranslation();

  const orders = useOrdersStore(s => s.orders);
  const colorConfirmations = useCompaniesStore(s => s.colorConfirmations);
  const projects = useProjectsStore(s => s.projects);

  const projectsById = useMemo(() => {
    const projectsById: { [key: number]: Project } = {};
    projects.forEach(p => (projectsById[p.id!] = p));
    return projectsById;
  }, [projects]);

  const ordersById = useMemo(() => {
    const projectsById: { [key: number]: Order } = {};
    orders.forEach(p => (projectsById[p.id!] = p));
    return projectsById;
  }, [orders]);

  const rows = useMemo(
    () =>
      colorConfirmations
        .map((c, id) => ({
          id,
          ...c,
          project: c.projectId ? projectsById[c.projectId]?.name : '',
          orderDate: ordersById[c.orderId]?.lastChange,
          orderId: ordersById[c.orderId]?.id,
        }))
        .sort((a, b) => Date.parse(b.orderDate!) - Date.parse(a.orderDate!)),
    [colorConfirmations, ordersById, projectsById],
  );

  const [selectionModel, setSelectionModel] = useState<GridSelectionModel>([]);

  const handleSelectionChange = (gsm: GridSelectionModel) => {
    if (gsm.length) {
      const file = colorConfirmations[gsm[0] as number];
      if (file) {
        onFilePick(file.url, file.colorCode);
      }
    }
    setSelectionModel(prevModel => gsm.filter(newId => !prevModel.includes(newId)));
  };

  const columns: ResponsiveGridColumns = [
    {
      mobileLocation: 'check',
      ...GRID_CHECKBOX_SELECTION_COL_DEF,
      renderHeader: () => <></>,
    },
    {
      mobileLocation: 'subtitle',
      field: 'fileName',
      headerName: t('common.fileName'),
      flex: 1,
      renderCell: ({ row }: { row: ColorConfirmationFile }) => (
        <Link target="_blank" rel="noopener noreferrer" href={row.url}>
          {row.fileName}
        </Link>
      ),
    },
    {
      mobileLocation: 'title',
      field: 'colorCode',
      headerName: t('rooms.colorCode'),
      flex: 0.5,
    },
    {
      mobileLocation: 'topRightDetail',
      field: 'project',
      flex: 0.5,
      headerName: t('common.project'),
    },
    {
      mobileLocation: 'content',
      field: 'orderId',
      headerName: t('common.orderNumber'),
      flex: 0.5,
      renderCell: ({ row }) => (
        <AppLink target="_blank" to={`/order/${row.orderId}/select-products`}>
          {row.orderId}
        </AppLink>
      ),
    },
    {
      mobileLocation: 'subtitle',
      field: 'orderDate',
      headerName: t('common.orderLastModified'),
      type: 'date',
      flex: 0.5,
      renderCell: ({ row }) => formatDate(row.orderDate),
    },
  ];

  return (
    <ResponsiveDataGrid
      hideFooterSelectedRowCount
      checkboxSelection
      selectionModel={selectionModel}
      onSelectionModelChange={handleSelectionChange}
      disableSelectionOnClick={false}
      pageSize={5}
      autoHeight
      columns={columns}
      rows={rows}
    />
  );
};
