import { FabContainer, NewOrderFabLink } from './NewOrderFab.styles';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import { useTranslation } from 'react-i18next';
import { useOrderWizardStore } from '../../../stores/orderWizardStore';
import { useCompaniesStore } from '../../../stores/companiesStore';
import shallow from 'zustand/shallow';

export const NewOrderFab: React.FC<{ onClick: () => void }> = ({ onClick }) => {
  const { t } = useTranslation();

  const [wizardSteps, resetStore] = useOrderWizardStore(s => [s.steps, s.reset], shallow);

  const selectedCompany = useCompaniesStore(s => s.selectedCompany);

  const fab = (
    <FabContainer disabled={!selectedCompany} variant="extended" size="medium" aria-label="add">
      <ShoppingCartIcon color="inherit" />
      <span>{t('home.createOrder')}</span>
    </FabContainer>
  );

  const handleClick = () => {
    resetStore();
    onClick();
  };

  return selectedCompany ? (
    <NewOrderFabLink onClick={handleClick} to={`/order/${wizardSteps[0].name}`}>
      {fab}
    </NewOrderFabLink>
  ) : (
    fab
  );
};
