import { PendingActions } from '@mui/icons-material';
import Grow from '@mui/material/Grow';
import { useTranslation } from 'react-i18next';
import { PendingRequestContainer } from './PendingRequest.styles';

const PendingRequest: React.FunctionComponent = props => {
  const { t } = useTranslation();
  return (
    <Grow in>
      <PendingRequestContainer>
        <PendingActions />
        <h3>{t('orders.orderSent')}</h3>
        <div>{t('orders.orderProcessingInfo')}</div>
      </PendingRequestContainer>
    </Grow>
  );
};

export default PendingRequest;
