import { Snackbar } from '@mui/material';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import shallow from 'zustand/shallow';
import { useUserStore } from '../../../stores/userStore';

export const Greeter: React.FC = () => {
  const [isOpen, setIsOpen] = useState(true);
  const { t } = useTranslation();
  const [name, username] = useUserStore(s => [s.name, s.username], shallow);
  return (
    <Snackbar
      anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
      autoHideDuration={6000}
      onClose={() => setIsOpen(false)}
      open={isOpen}
      color="secondary"
      message={t('home.greeter', { user: name || username || '' })}
    />
  );
};
