import { t } from 'i18next';
import { formatAddress, formatDate } from '../../../../../services/utils/format';
import { useOrderWizardStore } from '../../../../../stores/orderWizardStore';
import { ActualDeliveryInfoContainer, InfoRow, Separator } from './ConfirmDeliveryInfo.styles';
import { GeneralTermsAndConditionsLink } from '../../common/GeneralTermsAndConditionsLink';

export const ActualDeliveryInfo: React.FC = () => {
  const order = useOrderWizardStore(s => s.order);
  return order ? (
    <ActualDeliveryInfoContainer>
      <InfoRow isWarning>
        <div>{t('delivery.actualDeliveryDate')}</div>
        <div>{order.actualDeliveryDate && formatDate(order.actualDeliveryDate)}</div>
      </InfoRow>
      <InfoRow>
        <div>{t('delivery.baswaComments')}</div>
        <div>{order.baswaComments}</div>
      </InfoRow>
      <InfoRow>
        <div>{t('orders.paymentTerms')}</div>
        <div>{order.paymentTerms}</div>
      </InfoRow>
      <InfoRow>
        <div></div>
        <div>
          <GeneralTermsAndConditionsLink />
        </div>
      </InfoRow>
      <Separator />
      <InfoRow>
        <div>{t('delivery.requestedDeliveryDate')}</div>
        <div>{order.requestedDeliveryDate && formatDate(order.requestedDeliveryDate)}</div>
      </InfoRow>
      <InfoRow>
        <div>{t('delivery.shippingAddress')}</div>
        <div>{formatAddress(order.shippingAddress)}</div>
      </InfoRow>
      <InfoRow>
        <div>{t('delivery.deliveryDetails')}</div>
        <div>{order.deliveryDetails}</div>
      </InfoRow>
      <InfoRow>
        <div>{t('project.contactPerson')}</div>
        <div>
          {order.contactFirstName} {order.contactLastName}
        </div>
      </InfoRow>
      <InfoRow>
        <div>{t('common.phoneNumber')}</div>
        <div>{order.phoneNumber}</div>
      </InfoRow>
      <InfoRow>
        <div>{t('common.email')}</div>
        <div>{order.contactEmail}</div>
      </InfoRow>
    </ActualDeliveryInfoContainer>
  ) : null;
};
