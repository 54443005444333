import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import { ThemeProvider } from 'styled-components';
import theme from './services/styles/theme';
import CssBaseline from '@mui/material/CssBaseline';
import Notification from './components/core/notification/Notification';
import GlobalFonts from './fonts/fonts';
import './services/i18n/i18n';
import { MsalAuthenticationTemplate, MsalProvider } from '@azure/msal-react';
import { msalInstance } from './services/msal/msalInstance';
import { InteractionType } from '@azure/msal-browser';
import { loginRequest } from './services/msal/msalConfig';
import { AppLoading } from './components/core/appLoading/AppLoading';

ReactDOM.render(
  <React.StrictMode>
    <MsalProvider instance={msalInstance}>
      <MsalAuthenticationTemplate interactionType={InteractionType.Redirect} authenticationRequest={loginRequest}>
        <ThemeProvider theme={theme}>
          <GlobalFonts />
          <CssBaseline />
          <Notification />
          <AppLoading />
          <App />
        </ThemeProvider>
      </MsalAuthenticationTemplate>
    </MsalProvider>
  </React.StrictMode>,
  document.getElementById('root'),
);
