import * as yup from 'yup';

import { Dialog, DialogActions, DialogContent, DialogTitle, Stack, TextField } from '@mui/material';
import { useFormik } from 'formik';
import { useTranslation } from 'react-i18next';
import { Button } from '../../../../../core/button/Button';

interface Props {
  price: number;
  onClose: () => void;
  onSubmit: (price: number) => void;
}

export const EditPriceDialog: React.FC<Props> = ({ price, onClose, onSubmit }) => {
  const { t } = useTranslation();

  const requiredMessage = t('validations.required');

  const validationSchema = yup.object({
    price: yup.number().required(requiredMessage),
  });

  const formik = useFormik<{ price: number }>({
    initialValues: { price: price },
    validationSchema,
    onSubmit: values => {
      onSubmit(values.price);
    },
  });

  return (
    <Dialog onClose={onClose} open>
      <DialogTitle>{t('product.editPrice')}</DialogTitle>
      <DialogContent>
        <form id="edit-project-form" onSubmit={formik.handleSubmit}>
          <Stack sx={{ width: '300px', padding: 1 }} spacing={2}>
            <TextField
              type="number"
              fullWidth
              name="price"
              label={t('product.price')}
              value={formik.values.price}
              onChange={formik.handleChange}
              error={formik.touched.price && Boolean(formik.errors.price)}
              helperText={formik.touched.price && formik.errors.price}
            />
          </Stack>
        </form>
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" onClick={onClose}>
          {t('common.cancel')}
        </Button>
        <Button form="edit-project-form" type="submit">
          {t('common.save')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
