import Box from '@mui/material/Box';
import { DraftContainer } from '../Navigation.styles';
import { Button } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useAppStore } from '../../../stores/appStore';
import shallow from 'zustand/shallow';

export const DraftModeControl: React.FC = () => {
  const { t } = useTranslation();

  const [setDraftMode, publishDraftModeChanges] = useAppStore(
    s => [s.setIsInDraftMode, s.publishDraftModeChanges],
    shallow,
  );

  const exitDraftMode = () => {
    setDraftMode(false);
  };

  return (
    <DraftContainer>
      <div>{t('draftMode.inDraftMode')}</div>
      <Box sx={{ marginLeft: 'auto' }}>
        <Button onClick={publishDraftModeChanges} color="success" variant="contained" size="small">
          {t('draftMode.publish')}
        </Button>
        <Button onClick={exitDraftMode} variant="contained" size="small">
          {t('draftMode.exit')}
        </Button>
      </Box>
    </DraftContainer>
  );
};
