import { AppBar, IconButton } from '@mui/material';
import { NavLink } from 'react-router-dom';
import styled, { css } from 'styled-components';
import { getAdditionalNavigationOffset } from '../../services/utils/uiUtils';

export const NavContainer = styled(AppBar)<{ component: string; $isTraining: boolean; $isInDraftMode: boolean }>`
  display: flex;
  border-bottom: 1px solid ${p => p.theme.palette.divider};
  background: ${p => p.theme.palette.background.default};
  margin-bottom: 18px;
  ${p => p.theme.breakpoints.up('md')} {
    height: calc(90px + ${p => getAdditionalNavigationOffset(p.$isTraining, p.$isInDraftMode)}px);
    margin-bottom: 90px;
  }
`;

export const TrainingContainer = styled.div`
  background: ${p => p.theme.palette.success.light};
  text-align: center;
  height: 25px;
  font-family: sans-serif;
  position: relative;
`;

export const TrainingText = styled.div`
  letter-spacing: 2px;
  text-transform: uppercase;
  font-weight: 800;
`;

export const GoToLiveContainer = styled.div`
  position: absolute;
  right: 15px;
  top: 0;
  a {
    color: ${p => p.theme.palette.common.white};
    text-decoration: none;
    font-size: small;
  }
`;

export const DraftContainer = styled.div`
  height: 32px;
  background: ${p => p.theme.palette.error.light};
  font-size: small;
  display: flex;
  align-items: center;
  padding-left: ${p => p.theme.spacing(3)};
`;

export const NavigationNodesContainer = styled.div`
  display: flex;
  align-items: center;
  margin-left: auto;
  margin-right: ${p => p.theme.spacing(2)};

  ${p => p.theme.breakpoints.down('md')} {
    flex-direction: column;
    align-items: flex-start;
    margin-left: ${p => p.theme.spacing(1)};

    & > * {
      margin-bottom: ${p => p.theme.spacing(1)};
    }
  }
`;

export const BaswaLogoMobileWrapper = styled.div`
  padding: ${p => p.theme.spacing(2)} 0;
  text-align: center;
`;

export const DrawerContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`;

export const TopNavElementsContainer = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  ${p => p.theme.breakpoints.down('md')} {
    display: none;
  }
`;

export const DrawerButton = styled(IconButton)`
  ${p => p.theme.breakpoints.up('md')} {
    display: none;
  }
`;

const navNodeCss = css`
  padding: ${p => p.theme.spacing(1)} ${p => p.theme.spacing(2)};
  text-decoration: none;
  border-bottom: 3px solid transparent;
  color: ${p => p.theme.palette.text.primary};

  ${p => p.theme.breakpoints.down('md')} {
    width: 100%;
  }

  transition: all 0.3s;

  &.active {
    border-bottom: 3px solid ${p => p.theme.palette.secondary.main};
  }

  ${p => p.theme.breakpoints.down('md')} {
    &.active {
      border-bottom: none;
      color: ${p => p.theme.palette.secondary.main};
    }
  }

  &:hover {
    color: ${p => p.theme.palette.text.secondary};
  }
`;
export const ButtonLink = styled.button`
  border: none;
  background: none;
  font-size: 16px;
  cursor: pointer;
  font-family: inherit;
  text-align: left;
  ${navNodeCss}
`;

export const ExternalNavigationNode = styled.a`
  ${navNodeCss}
`;

export const NavigationNode = styled(NavLink)`
  ${navNodeCss}
`;
