import { t } from 'i18next';
import { orderBy } from 'lodash-es';
import create from 'zustand';
import { Order } from '../models/Order';
import confirm from '../services/confirm/confirm';
import { deleteOrder, getOrders } from '../services/http/orders';
import { useCompaniesStore } from './companiesStore';
import { useNotificationStore } from './notificationStore';

interface OrdersStoreValues {
  orders: Order[];
  isLoading: boolean;
}

const initialValues: OrdersStoreValues = {
  orders: [],
  isLoading: false,
};
interface OrdersStoreActions {
  loadOrders: (companyId?: string) => void;
  deleteOrder: (orderId: number) => void;
}

export const useOrdersStore = create<OrdersStoreValues & OrdersStoreActions>((set, get) => ({
  ...initialValues,
  loadOrders: companyId => {
    set({ isLoading: true });
    getOrders(companyId)
      .then(orders => set({ orders: orderBy(orders, 'lastChange', 'desc') }))
      .finally(() => set({ isLoading: false }));
  },

  deleteOrder: async orderId => {
    if (
      await confirm(t('orders.deleteConfirmation'), {
        checkboxConfirmationMessage: t('orders.confirmDeleteCheckbox'),
        yesLabel: t('common.delete'),
        noLabel: t('common.cancel'),
      })
    ) {
      deleteOrder(orderId).then(() => {
        useNotificationStore.getState().addNotification({ color: 'success', message: t('orders.deleteSuccess') });
        get().loadOrders(useCompaniesStore.getState().selectedCompany?.companyId);
      });
    }
  },
}));
