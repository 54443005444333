import { DoneOutline } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';
import { OrderConfirmedContainer } from './OrderConfirmed.styles';

const OrderConfirmed: React.FunctionComponent = props => {
  const { t } = useTranslation();
  return (
    <OrderConfirmedContainer>
      <DoneOutline />
      <h3>{t('orders.orderConfirmed')}</h3>
      <div>{t('orders.makeChangesToConfirmedOrder')}</div>
    </OrderConfirmedContainer>
  );
};

export default OrderConfirmed;
