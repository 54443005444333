import { Box, FormControlLabel, Grow, Switch } from '@mui/material';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import shallow from 'zustand/shallow';
import { tintTypes } from '../../../../constants/tintTypes';
import { useOrderWizardStore } from '../../../../stores/orderWizardStore';
import { useUserStore } from '../../../../stores/userStore';
import { Button } from '../../../core/button/Button';
import { ReactiveText } from '../../../core/Core.styles';
import { WizzardNavigationButtonsContainer } from '../../OrderWizardPage.styles';
import ProductList from '../selectProducts/products/productList/ProductList';
import {
  QuoteByRoomContainer,
  QuoteByRoomContainerTitle,
  QuoteByRoomControlContainer,
} from './GetQuoteWizardStep.styles';
import { DownloadOrderButton } from '../common/DownloadOrderButton';

interface ISendOrderWizardStepProps {}

export const GetQuoteWizardStep: React.FC<ISendOrderWizardStepProps> = props => {
  const { t } = useTranslation();
  const [goToNextStep, goToPreviousStep, rooms, products] = useOrderWizardStore(
    s => [s.goToNextStep, s.goToPreviousStep, s.rooms, s.products],
    shallow,
  );

  const [showQuoteByRoom, setShowQuoteByRoom] = useState(false);

  const isMinSales = useUserStore(s => s.isAdmin || s.isSales);

  return (
    <Grow in>
      <div>
        <QuoteByRoomControlContainer>
          <FormControlLabel
            sx={{ marginLeft: 'auto' }}
            control={<Switch value={showQuoteByRoom} onChange={e => setShowQuoteByRoom(e.target.checked)} />}
            label={<ReactiveText isActive={showQuoteByRoom}>{t('orders.showQuoteByRooms')}</ReactiveText>}
            labelPlacement="end"
          />
        </QuoteByRoomControlContainer>

        {showQuoteByRoom ? (
          <>
            {rooms.map(r => (
              <QuoteByRoomContainer key={r.uiId}>
                <QuoteByRoomContainerTitle>{[r.name, r.type].join(', ')}</QuoteByRoomContainerTitle>
                <ProductList
                  showAdditionalProjectDiscount={isMinSales}
                  roomId={r.uiId}
                  readOnly
                  showColorConfirmation
                />
              </QuoteByRoomContainer>
            ))}
            {products.some(p => !p.roomUiId) && (
              <QuoteByRoomContainer key="otherProducts">
                <QuoteByRoomContainerTitle>{t('product.otherProducts')}</QuoteByRoomContainerTitle>
                <ProductList
                  saveOrderOnProjectDiscountChange
                  showAdditionalProjectDiscount={isMinSales}
                  readOnly
                  showColorConfirmation
                />
              </QuoteByRoomContainer>
            )}
          </>
        ) : (
          <ProductList
            saveOrderOnProjectDiscountChange
            showAdditionalProjectDiscount={isMinSales}
            showColorConfirmation
            readOnly
            showAllProducts
          />
        )}

        <WizzardNavigationButtonsContainer>
          <Button onClick={goToPreviousStep}>{t('common.previous')}</Button>
          <Box sx={{ marginLeft: 'auto' }}>
            <DownloadOrderButton />
          </Box>
          <Button
            disabled={
              !products.length ||
              products.some(
                p => p.tintType === tintTypes.tintedWithoutConfirmation && !p.isTintWithoutConfirmationApproved,
              )
            }
            type="submit"
            onClick={goToNextStep}
          >
            {t('orders.addDeliveryDetails')}
          </Button>
        </WizzardNavigationButtonsContainer>
      </div>
    </Grow>
  );
};
