import { FormControlLabel, Stack, Switch, Tab, Tabs, TextField } from '@mui/material';
import { Field, FormikProvider, useFormik } from 'formik';
import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from '../../../../core/button/Button';
import * as yup from 'yup';
import { Check } from '@mui/icons-material';
import { useNotificationStore } from '../../../../../stores/notificationStore';
import { TabContext, TabPanel } from '@mui/lab';
import { UploadedFilesPicker } from './UploadedFilesPicker';
import { CircularLoader } from '../../../../core/loaders/CircularLoader';
import { useOrdersStore } from '../../../../../stores/ordersStore';
import { useCompaniesStore } from '../../../../../stores/companiesStore';
import shallow from 'zustand/shallow';
import { useProjectsStore } from '../../../../../stores/projectsStore';

interface Props {
  onUpload: (file: File, colorCode: string, adjacentCeiling: boolean, comments: string) => void;
  onPickFile: (fileUrl: string, colorCode: string, adjacentCeiling: boolean, comments: string) => void;
  setFullScreen: (isFS: boolean) => void;
}

export const ColorAgreementForm: React.FC<Props> = ({ onUpload, setFullScreen, onPickFile }) => {
  const { t } = useTranslation();

  const inputRef = useRef<HTMLInputElement | null>(null);

  const addNotification = useNotificationStore(s => s.addNotification);

  const validationSchema = yup.object({
    file: yup.mixed().required(t('validations.required')),
    colorCode: yup.string().required(t('validations.required')),
    adjacentCeiling: yup.bool(),
    comments: yup.string(),
  });

  const [activeTab, setActiveTab] = useState('new');

  const isSelectUploadedFile = activeTab !== 'new';

  const [loadOrders, isLoadingOrders] = useOrdersStore(s => [s.loadOrders, s.isLoading], shallow);
  const [currentCompany, loadCompanyColorConfirmations, isLoadingColorConfirmations] = useCompaniesStore(
    s => [s.selectedCompany, s.loadCompanyColorConfirmations, s.isLoadingColorConfirmations],
    shallow,
  );

  const [loadProjects, projectsLoaded] = useProjectsStore(s => [s.loadProjects, s.projects, s.projectsLoaded]);

  const formik = useFormik<{ file: any; colorCode: string; adjacentCeiling: boolean; comments: string }>({
    initialValues: {
      colorCode: '',
      file: null,
      adjacentCeiling: false,
      comments: '',
    },
    validationSchema: validationSchema,
    onSubmit: values => {
      const { file, colorCode, adjacentCeiling, comments } = values;
      if (isSelectUploadedFile) {
        onPickFile(file, colorCode, adjacentCeiling, comments);
      } else {
        onUpload(file, colorCode, adjacentCeiling, comments);
      }
    },
  });

  const hasFile = !!formik.values.file;

  const isLoading = isLoadingOrders || !projectsLoaded || isLoadingColorConfirmations;

  const handleFilePick = (url: string, colorCode: string) => {
    formik.setFieldValue('file', url);
    formik.setFieldValue('colorCode', colorCode);
  };

  useEffect(() => {
    setFullScreen(isSelectUploadedFile && !isLoading);
  }, [isLoading, isSelectUploadedFile, setFullScreen]);

  const handleChangeTab = (event: React.SyntheticEvent<Element, Event>, tab: string) => {
    formik.resetForm();
    setActiveTab(tab);
  };

  useEffect(() => {
    if (currentCompany && isSelectUploadedFile) {
      loadCompanyColorConfirmations(currentCompany.companyId);
      loadOrders(currentCompany.companyId);
      loadProjects(currentCompany.companyId);
    }
  }, [currentCompany, isSelectUploadedFile, loadCompanyColorConfirmations, loadOrders, loadProjects]);

  return (
    <form id="uploadColorAgreementForm" onSubmit={formik.handleSubmit}>
      <CircularLoader isLoading={isLoading}>
        <Stack spacing={2}>
          <FormikProvider value={formik}>
            <Tabs onChange={handleChangeTab} value={activeTab} variant="fullWidth">
              <Tab value="new" label={t('common.upload')} />
              <Tab value="previous" label={t('common.existing')} />
            </Tabs>
            <TabContext value={activeTab}>
              <TabPanel sx={{ padding: 0, marginBottom: 2 }} value="new">
                <div>
                  <Button
                    sx={{ maxWidth: 350 }}
                    fullWidth
                    endIcon={hasFile && <Check />}
                    color={
                      hasFile ? 'success' : formik.touched.file && Boolean(formik.errors.file) ? 'error' : 'primary'
                    }
                    onClick={() => inputRef.current?.click()}
                    variant="outlined"
                  >
                    {(formik.values.file as File)?.name || t('common.chooseFile')}
                  </Button>
                </div>
              </TabPanel>
              <TabPanel sx={{ padding: 0 }} value="previous">
                <div>
                  <UploadedFilesPicker onFilePick={handleFilePick} />
                </div>
              </TabPanel>
            </TabContext>
            <Field name="file">
              {() => (
                <>
                  <input
                    onChange={e => {
                      const file = e?.target?.files && e.target.files[0];
                      if (file?.type === 'application/pdf') {
                        formik.setFieldValue('file', file);
                      } else {
                        addNotification({ message: t('product.invalidFileType'), color: 'warning' });
                      }
                    }}
                    ref={inputRef}
                    hidden
                    id="file"
                    type="file"
                    accept="application/pdf"
                  ></input>
                </>
              )}
            </Field>
          </FormikProvider>
          <div>
            <Stack sx={{ maxWidth: 350, margin: 'auto' }} spacing={2}>
              <TextField
                fullWidth
                id="colorCode"
                name="colorCode"
                label={t('rooms.colorCode')}
                value={formik.values.colorCode}
                onChange={formik.handleChange}
                error={formik.touched.colorCode && Boolean(formik.errors.colorCode)}
                helperText={formik.touched.colorCode && formik.errors.colorCode}
              />

              <FormControlLabel
                sx={{ marginLeft: 'auto' }}
                control={
                  <Switch
                    checked={formik.values.adjacentCeiling}
                    onChange={e => {
                      formik.setFieldValue('adjacentCeiling', e.target.checked);
                    }}
                  />
                }
                label={t('rooms.adjacentCeiling') as any}
                labelPlacement="end"
              />

              <TextField
                multiline
                rows={3}
                fullWidth
                id="comments"
                name="comments"
                label={t('common.comments')}
                value={formik.values.comments}
                onChange={formik.handleChange}
                error={formik.touched.comments && Boolean(formik.errors.comments)}
                helperText={formik.touched.comments && formik.errors.comments}
              />
            </Stack>
          </div>
        </Stack>
      </CircularLoader>
    </form>
  );
};
