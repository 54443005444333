import styled from 'styled-components';
import { getAdditionalNavigationOffset } from '../../../services/utils/uiUtils';

export const PageContainer = styled.div``;

export const PageContent = styled.div<{ isTraining: boolean; isDraftMode: boolean }>`
  margin: ${p => p.theme.spacing(4)} auto;
  padding: 0 ${p => p.theme.spacing(2)};
  max-width: 1300px;
  margin-top: calc(120px + ${p => getAdditionalNavigationOffset(p.isTraining, p.isDraftMode)}px);
  ${p => p.theme.breakpoints.down('md')} {
    margin-top: calc(70px + ${p => getAdditionalNavigationOffset(p.isTraining, p.isDraftMode)}px);
  }
`;
