import shallow from 'zustand/shallow';
import { AppLanguage, languages } from '../../../constants/languages';
import { useUserStore } from '../../../stores/userStore';
import { Dropdown } from '../../core/dropdown/Dropdown';
import { LanguageControlContainer } from './LanguageControl.styles';

export const LanguageControl: React.FC = () => {
  const [language, setLanguage] = useUserStore(s => [s.language, s.setLanguage], shallow);
  return (
    <LanguageControlContainer>
      <Dropdown
        value={language}
        onChange={e => setLanguage(e.target.value as AppLanguage)}
        size="small"
        options={languages.map(l => ({ value: l, label: l }))}
      />
    </LanguageControlContainer>
  );
};
