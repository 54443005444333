import create from 'zustand';
import { OrderProduct } from '../models/OrderProduct';
import { Room } from '../models/Room';
import { RoomOptionPanel } from '../models/RoomOptionPanel';
import { getComputedRoomOrderProducts, getRoomsOptions } from '../services/http/rooms';
import { useAppStore } from './appStore';
import { useOrderWizardStore } from './orderWizardStore';
import { useProductsStore } from './productStore';

interface RoomsStoreValues {
  roomOptions: RoomOptionPanel[];
  roomOptionsLoaded: boolean;
  isComputingProducts: boolean;
}

interface RoomsStoreActions {
  loadRoomOptions: () => void;
  computeRoomOrderProducts: (room: Room, replaceProducts: boolean) => void;
}

const initialValues: RoomsStoreValues = {
  roomOptions: [],
  roomOptionsLoaded: false,
  isComputingProducts: false,
};

export const useRoomsStore = create<RoomsStoreValues & RoomsStoreActions>(set => ({
  ...initialValues,

  loadRoomOptions: () => {
    getRoomsOptions().then(roomOptions => set({ roomOptions, roomOptionsLoaded: true }));
  },

  computeRoomOrderProducts: (room, replaceProducts) => {
    set({ isComputingProducts: true });
    useAppStore.getState().setAppLoading(true);
    getComputedRoomOrderProducts(room)
      .then(orderProducts => {
        const orderWizardStoreState = useOrderWizardStore.getState();
        const productsStore = useProductsStore.getState();
        const newProducts: OrderProduct[] = [];
        orderProducts.forEach(op => {
          const product = productsStore.productsMap[op.articleNo];
          if (product) {
            const orderProduct: OrderProduct = {
              ...op,
              roomId: room.id,
              roomUiId: room.uiId,
              productId: product.itemNo,
              quantitySqm: op.consumptionPerSqm,
              quantityLm: op.consumptionPerLm,
              price: product.price,
              colorAgreementUrl: product.tinted ? room.colorAgreementUrl : undefined,
              colorCode: product.tinted ? room.colorCode : undefined,
              tintType: product.tinted ? room.tintType : undefined,
              adjacentCeiling: product.tinted ? room.adjacentCeiling : undefined,
            };
            newProducts.push(orderProduct);
          }
        });
        newProducts.sort((a, b) =>
          productsStore.productsMap[a.productId].category < productsStore.productsMap[b.productId].category ? -1 : 1,
        );
        if (replaceProducts) {
          orderWizardStoreState.setProducts([
            ...orderWizardStoreState.products.filter(p => p.roomUiId !== room.uiId),
            ...newProducts,
          ]);
        } else {
          orderWizardStoreState.setProducts([...orderWizardStoreState.products, ...newProducts]);
        }
        orderWizardStoreState.saveOrder();
      })
      .finally(() => {
        set({ isComputingProducts: false });
      })
      .catch(() => {
        useAppStore.getState().setAppLoading(false);
      });
  },
}));
