import { Routes, Route, unstable_HistoryRouter as HistoryRouter } from 'react-router-dom';
import { Page } from './components/core/page/Page';
import { HomePage } from './components/home/HomePage';
import { OrderWizardPage } from './components/orderWizard/OrderWizardPage';
import { useOrderWizardStore } from './stores/orderWizardStore';
import history from './services/history/history';
import { useUserStore } from './stores/userStore';
import { useEffect } from 'react';
import { useCompaniesStore } from './stores/companiesStore';
import { PageNotFound } from './components/systemPages/PageNotFound';
import { UnauthorizedPage } from './components/systemPages/UnauthorizedPage';

function App() {
  const orderWizardSteps = useOrderWizardStore(s => s.steps);

  const loadUserInfo = useUserStore(s => s.loadUserInfo);
  const loadCompanies = useCompaniesStore(s => s.loadCompanies);

  useEffect(() => {
    loadUserInfo();
    loadCompanies();
  }, [loadUserInfo, loadCompanies]);

  return (
    <HistoryRouter history={history}>
      <Routes>
        <Route path="/" element={<Page />}>
          <Route path="/" element={<HomePage />} />
          <Route path="/order" element={<OrderWizardPage />}>
            {orderWizardSteps.map(({ name, component: Component, suitableStatuses }, i) => (
              <Route key={name}>
                {/* only the first step can be loaded without an order id */}
                {!i && <Route path={`${name}`} element={<Component step={i} suitableStatuses={suitableStatuses} />} />}
                <Route path={`:orderId/${name}`} element={<Component step={i} suitableStatuses={suitableStatuses} />} />
              </Route>
            ))}
          </Route>
          <Route path="/unauthorized" element={<UnauthorizedPage />} />
          <Route path="*" element={<PageNotFound />} />
        </Route>
      </Routes>
    </HistoryRouter>
  );
}

export default App;
