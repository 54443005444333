import { Locale } from 'date-fns';
import { enGB, de, fr } from 'date-fns/locale';

export type AppLanguage = 'EN' | 'DE' | 'FR';

export const languages: AppLanguage[] = ['EN', 'DE', 'FR'];

export const dateLocales: { [key in AppLanguage]: Locale } = {
  EN: enGB,
  DE: de,
  FR: fr,
};
