import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import { AppLanguage } from '../../constants/languages';
import en from './resources/en.json';
import de from './resources/de.json';
import fr from './resources/fr.json';

// the translations
// (tip move them in a JSON file and import them,
// or even better, manage them separated from your code: https://react.i18next.com/guides/multiple-translation-files)
const resources: { [key in AppLanguage]: any } = {
  EN: { translation: en },
  DE: { translation: de },
  FR: { translation: fr },
};

i18n.use(initReactI18next).init({
  resources,
});

export default i18n;
