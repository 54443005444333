import { useTranslation } from 'react-i18next';
import { useOrderWizardStore } from '../../../../stores/orderWizardStore';
import { Button } from '../../../core/button/Button';
import { Order } from '../../../../models/Order';

export const SaveButton: React.FC<{ onClick: () => void; onSaved?: (order: Order) => void }> = ({
  onClick,
  onSaved,
}) => {
  const saveOrder = useOrderWizardStore(s => s.saveOrder);
  const { t } = useTranslation();

  const save = () => {
    onClick();
    saveOrder().then(order => onSaved && onSaved(order));
  };

  return <Button onClick={save}>{t('common.save')}</Button>;
};
