import styled, { css } from 'styled-components';
import { Tab as MuiTab } from '@mui/material';
import { Link } from 'react-router-dom';

export const ReactiveText = styled.span<{ isActive: boolean }>`
  color: ${p => (p.isActive ? p.theme.palette.text.primary : p.theme.palette.text.secondary)};
  transition: all 0.3s;
`;

export const AppFormStyles = css`
  & > *:not(:first-child) {
    margin-top: ${p => p.theme.spacing(2)};
  }
`;

export const FormSection = styled.div`
  ${AppFormStyles}

  flex: 1;

  &:not(:last-child) {
    margin-right: ${p => p.theme.spacing(1)};
  }
`;

export const AppLink = styled(Link)`
  color: ${p => p.theme.palette.text.primary};
`;

export const AppForm = styled.form`
  ${AppFormStyles}
`;

export const Tab = styled(MuiTab)`
  border-color: ${p => p.theme.palette.secondary.main};
  color: ${p => p.theme.palette.text.primary};
  &:hover {
    color: ${p => p.theme.palette.text.secondary};
    transition: all 0.3s;
  }
`;
