import { Button as MuiButton, ButtonProps } from '@mui/material';
import styled, { css } from 'styled-components';

interface Props extends ButtonProps {}

export const StyledButton = styled(MuiButton)<Props>`
  &:hover {
    background: ${p => p.theme.palette.secondary.main};
    color: ${p => p.theme.palette.secondary.contrastText};
  }

  font-weight: 500;

  margin-right: ${p => p.theme.spacing(1)};

  ${p =>
    p.variant &&
    p.variant === 'text' &&
    css`
      padding: 0;
      /* margin: 0; */
      margin-right: ${p => p.theme.spacing(1)};
      min-width: 0;
      color: ${p => p.theme.palette.primary.main};

      &:hover {
        background: inherit;
        color: ${p => p.theme.palette.secondary.main};
      }
    `};
`;

export const Button: React.FC<Props> = props => {
  return <StyledButton size="small" variant="contained" color="primary" {...props} />;
};
