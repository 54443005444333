import Grow from '@mui/material/Grow';
import { useOrderWizardStore } from '../../../../stores/orderWizardStore';
import { useUserStore } from '../../../../stores/userStore';
import { WizzardNavigationButtonsContainer } from '../../OrderWizardPage.styles';
import { EditOrderButton } from '../common/EditOrderButton';
import ProductList from '../selectProducts/products/productList/ProductList';
import { ActualDeliveryInfo } from './actualDeliveryInfo/DeliveryInfo';
import OrderClosed from './orderConfirmed/OrderClosed';
import OrderConfirmed from './orderConfirmed/OrderConfirmed';
import { DownloadOrderButton } from '../common/DownloadOrderButton';
import { Box } from '@mui/material';
import { differenceInHours } from 'date-fns';

interface Props {}

const OrderStatusWizardStep: React.FunctionComponent<Props> = props => {
  const order = useOrderWizardStore(s => s.order);
  const isClosed = order.status === 'Closed';
  const isLessThan3Days = order.actualDeliveryDate
    ? differenceInHours(new Date(order.actualDeliveryDate), new Date()) < 72
    : true;
  const isAdmin = useUserStore(s => s.isAdmin);
  return (
    <Grow in>
      <div>
        {isClosed ? <OrderClosed /> : <OrderConfirmed />}
        <ActualDeliveryInfo />
        <ProductList readOnly showAllProducts showAdditionalProjectDiscount readonlyAdditionalProjectDiscount />
        <WizzardNavigationButtonsContainer>
          {((!isClosed && !isLessThan3Days) || isAdmin) && <EditOrderButton />}
          <Box sx={{ marginLeft: 'auto' }}>
            <DownloadOrderButton />
          </Box>
        </WizzardNavigationButtonsContainer>
      </div>
    </Grow>
  );
};

export default OrderStatusWizardStep;
