import Link from '@mui/material/Link';
import { useTranslation } from 'react-i18next';
import { termsUrls } from '../../../../constants/termsUrls';
import { useUserStore } from '../../../../stores/userStore';

export const GeneralTermsAndConditionsLink: React.FC = () => {
  const { t } = useTranslation();
  const language = useUserStore(s => s.language);
  return (
    <Link target="_blank" rel="noopener noreferrer" href={termsUrls[language]}>
      {t('orders.generalTermsAndConditions')}
    </Link>
  );
};
