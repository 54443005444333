import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useOrdersStore } from '../../../stores/ordersStore';
import { useProjectsStore } from '../../../stores/projectsStore';
import { Project } from '../../../models/Project';
import { EllipsableSpan } from '../ordersOverview/OrdersOverview.styles';
import Avatar from '@mui/material/Avatar';
import InfoButton from '../../core/infoButton/InfoButton';
import { Button } from '../../core/button/Button';
import { statusColors } from '../../../services/http/orders';
import { useNavigate } from 'react-router-dom';
import { OrderStatus } from '../../../constants/orderStatus';
import { Order } from '../../../models/Order';
import { formatAddress, formatDate, formatTime } from '../../../services/utils/format';
import { useCompaniesStore } from '../../../stores/companiesStore';
import shallow from 'zustand/shallow';
import { Box, TextField } from '@mui/material';
import { Company } from '../../../models/Company';
import { AppLink } from '../../core/Core.styles';
import { Search } from '@mui/icons-material';
import { ResponsiveDataGrid } from '../../core/responsiveDataGrid/ResponsiveDataGrid';
import { ResponsiveGridColumns } from '../../../models/ResponsiveGridColumn';
import { OrderActionsWrapper } from './OrdersGrid.styles';
import { Guard } from '../../guards/Guard';

const EllipsisValue: React.FC<{ value?: string | null }> = ({ value }) => (
  <EllipsableSpan title={value || ''}>{value || '-'}</EllipsableSpan>
);

const Ellipsis: React.FC<{ formattedValue: string }> = ({ formattedValue }) => <EllipsisValue value={formattedValue} />;

const deleteRolesByStatus: { [key in OrderStatus]: string[] } = {
  Open: ['user', 'sales', 'admin'],
  Submitted: ['user', 'sales', 'admin'],
  NotYetSigned: ['user', 'sales', 'admin'],
  Signed: ['admin'],
  Closed: ['admin'],
};

const statusesWizardLinks: { [key in OrderStatus]: string } = {
  Open: 'select-products',
  Submitted: 'review',
  NotYetSigned: 'confirm-order',
  Signed: 'order-status',
  Closed: 'order-status',
};

export const OrdersGrid: React.FC<{
  projectId?: number;
  excludedStatus?: OrderStatus;
  excludedOrderId?: number;
  onImportClick?: (orderId: number) => void;
}> = ({ projectId, excludedStatus, onImportClick, excludedOrderId }) => {
  const { t } = useTranslation();

  const [searchTerm, setSearchTerm] = useState('');

  const [selectedCompany, companies] = useCompaniesStore(s => [s.selectedCompany, s.companies], shallow);

  const navigate = useNavigate();

  const [orders, deleteOrder] = useOrdersStore(
    s => [projectId ? s.orders.filter(o => o.projectId === projectId) : s.orders, s.deleteOrder],
    shallow,
  );

  const projectsById = useProjectsStore(s => {
    const projectsById: { [key: number]: Project } = {};
    s.projects.forEach(p => (projectsById[p.id!] = p));
    return projectsById;
  });

  const companiesById = useMemo(() => {
    const companiesById: { [key: string]: Company } = {};
    companies.forEach(p => (companiesById[p.companyId] = p));
    return companiesById;
  }, [companies]);

  const extendedOrders = useMemo(
    () =>
      orders.map(o => ({
        ...o,
        project: o.projectId ? projectsById[o.projectId]?.name : '',
        company: o.companyId ? companiesById[o.companyId]?.name : '',
        formattedAddress: formatAddress(o.shippingAddress)
      })),
    [companiesById, orders, projectsById],
  );

  const rows = useMemo(
    () =>
      extendedOrders.filter(
        o =>
          (excludedOrderId ? o.id !== excludedOrderId : true) &&
          (excludedStatus ? o.status !== excludedStatus : true) &&
          (searchTerm
            ? o.id?.toString().includes(searchTerm.toLowerCase()) ||
              o.company?.toLocaleLowerCase().includes(searchTerm.toLowerCase()) ||
              o.formattedAddress.toLocaleLowerCase().includes(searchTerm.toLowerCase()) ||
              o.project?.includes(searchTerm.toLowerCase())
            : true),
      ),
    [extendedOrders, excludedOrderId, excludedStatus, searchTerm],
  );

  const columnVisibilityModel = {
    project: !!selectedCompany,
    company: !selectedCompany,
  };

  const columns = useMemo(() => {
    const commonProps = {
      flex: 1,
      renderCell: Ellipsis,
    };
    const columns: ResponsiveGridColumns = [
      {
        mobileLocation: 'title',
        field: 'id',
        headerName: t('common.orderNumber'),
        ...commonProps,
        renderCell: ({ formattedValue, value, row }) =>
          !onImportClick ? (
            <AppLink to={`/order/${value}/${statusesWizardLinks[row.status as OrderStatus]}`}>
              <span>{formattedValue}</span>
            </AppLink>
          ) : (
            <span>{formattedValue}</span>
          ),
      },
      {
        mobileLocation: 'content',
        field: 'project',
        headerName: t('common.project'),
        type: 'string',
        ...commonProps,
      },
      {
        mobileLocation: 'topRightDetail',
        field: 'company',
        headerName: t('common.company'),
        type: 'string',
        ...commonProps,
      },
      {
        mobileLocation: 'content',
        field: 'requestedDeliveryDate',
        headerName: t('common.shippingDate'),
        type: 'date',
        ...commonProps,
        renderCell: ({ row }: { row: Order }) => {
          let date = row.actualDeliveryDate || row.requestedDeliveryDate;
          date = date && formatDate(date);
          return <EllipsisValue value={date} />;
        },
      },
      {
        mobileLocation: 'content',
        field: 'formattedAddress',
        headerName: t('common.shipTo'),
        type: 'string',
        ...commonProps,
        renderMobileCell: ({ id, formattedValue }) => {
          const deliveryDetails = rows.find(r => r.id === id)!.deliveryDetails;
          return (
            <>
              <EllipsisValue value={formattedValue} />
              {deliveryDetails && <EllipsisValue value={deliveryDetails} />}
            </>
          );
        },
        renderCell: ({ id, formattedValue }) => {
          const deliveryDetails = rows.find(r => r.id === id)!.deliveryDetails;
          return (
            <>
              {deliveryDetails && <InfoButton marginRight content={deliveryDetails} />}
              <EllipsisValue value={formattedValue} />
            </>
          );
        },
      },
      {
        mobileLocation: 'topRightDetail',
        field: 'lastChange',
        headerName: t('common.lastModified'),
        type: 'date',
        ...commonProps,
        renderMobileCell: ({ value }) => {
          const date = value && formatDate(value);
          const time = value && formatTime(value);
          return (
            <div>
              {date}, {time}
            </div>
          );
        },
        renderCell: ({ value }) => {
          const date = value && formatDate(value);
          const time = value && formatTime(value);
          return (
            <div>
              <div>
                <EllipsisValue value={date} />
              </div>
              <div>
                <EllipsisValue value={time} />
              </div>
            </div>
          );
        },
      },
      {
        mobileLocation: 'subtitle',
        field: 'status',
        headerName: t('common.status'),
        type: 'string',
        ...commonProps,
        renderCell: ({ formattedValue }) => (
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Avatar
              sx={{
                marginRight: '8px',
                height: '14px',
                width: '14px',
                bgcolor: statusColors[formattedValue as OrderStatus],
              }}
            >
              {' '}
            </Avatar>
            {t(`orders.statuses.${formattedValue}`)}
          </Box>
        ),
      },
      {
        mobileLocation: 'inlineActions',
        field: 'actions',
        type: 'actions',
        headerName: '',
        width: 120,
        renderCell: ({ row }) => (
          <OrderActionsWrapper>
            {onImportClick ? (
              <Button variant="text" onClick={() => onImportClick(row.id)}>
                {t('common.select')}
              </Button>
            ) : (
              <>
                <Button
                  variant="text"
                  onClick={() => navigate(`/order/${row.id}/${statusesWizardLinks[row.status as OrderStatus]}`)}
                >
                  {t('common.open')}
                </Button>
                <Guard roles={deleteRolesByStatus[row.status as OrderStatus]}>
                  <Button sx={{ marginLeft: 2 }} variant="text" onClick={() => deleteOrder(row.id)}>
                    {t('common.delete')}
                  </Button>
                </Guard>
              </>
            )}
          </OrderActionsWrapper>
        ),
      },
    ];
    return columns;
  }, [t, onImportClick, rows, navigate, deleteOrder]);

  return (
    <>
      <TextField
        value={searchTerm}
        onChange={e => setSearchTerm(e.target.value)}
        fullWidth
        size="small"
        placeholder={t('orders.searchOrders')}
        InputProps={{ startAdornment: <Search sx={{ marginRight: 2 }} color="action" /> }}
      />
      <ResponsiveDataGrid columnVisibilityModel={columnVisibilityModel} autoHeight rows={rows} columns={columns} />
    </>
  );
};
