import Tabs from '@mui/material/Tabs';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { OrdersGrid } from '../ordersGrid/OrdersGrid';
import { MyProjects } from '../myProjects/MyProjects';
import { TabViews } from './OrdersOverview.styles';
import { Tab } from '../../core/Core.styles';
import { useOrdersStore } from '../../../stores/ordersStore';
import { useCompaniesStore } from '../../../stores/companiesStore';
import { LinearLoader } from '../../core/loaders/LinearLoader';
import shallow from 'zustand/shallow';
import { useProjectsStore } from '../../../stores/projectsStore';

const openOrdersTabId = 'openOrders';
const allOrdersTabId = 'allOrders';
const projectsTabId = 'projects';

const TabView: React.FC<{ tabId: string; activeTab: string; children: JSX.Element }> = ({
  tabId,
  activeTab,
  children,
}) => (tabId === activeTab ? children : null);

export const OrdersOverview: React.FC = () => {
  const { t } = useTranslation();

  const [activeTab, setActiveTab] = useState(openOrdersTabId);

  const [loadOrders, isLoading] = useOrdersStore(s => [s.loadOrders, s.isLoading], shallow);
  const [loadProjects] = useProjectsStore(s => [s.loadProjects], shallow);

  const selectedCompany = useCompaniesStore(s => s.selectedCompany);

  useEffect(() => {
    loadOrders(selectedCompany?.companyId);
    if (selectedCompany) {
      loadProjects(selectedCompany.companyId);
    }
  }, [loadOrders, loadProjects, selectedCompany]);

  useEffect(() => {
    if (!selectedCompany && activeTab === projectsTabId) {
      setActiveTab(openOrdersTabId);
    }
  }, [activeTab, selectedCompany]);

  return (
    <>
      <LinearLoader isLoading={isLoading}>
        <Tabs
          textColor="primary"
          indicatorColor="secondary"
          variant="fullWidth"
          value={activeTab}
          onChange={(_, tab) => setActiveTab(tab)}
        >
          <Tab key={openOrdersTabId} value={openOrdersTabId} label={t('home.openOrders')} />
          <Tab key={allOrdersTabId} value={allOrdersTabId} label={t('home.allOrders')} />
          {selectedCompany && <Tab key={projectsTabId} value={projectsTabId} label={t('home.projects')} />}
        </Tabs>
        <TabViews>
          <TabView tabId={openOrdersTabId} activeTab={activeTab}>
            <OrdersGrid excludedStatus="Closed" />
          </TabView>
          <TabView tabId={allOrdersTabId} activeTab={activeTab}>
            <OrdersGrid />
          </TabView>
          <TabView tabId={projectsTabId} activeTab={activeTab}>
            <MyProjects />
          </TabView>
        </TabViews>
      </LinearLoader>
    </>
  );
};
