import { Calculate } from '@mui/icons-material';
import { Box, Button, IconButton, Popover, Stack, TextField, Tooltip } from '@mui/material';
import { useFormik } from 'formik';
import { t } from 'i18next';
import { useState } from 'react';

const computeQuantity = (
  consumption: number = 1,
  workingSteps: number = 1,
  roomSurface: number = 1,
  packSize: number = 1,
) => {
  return Math.ceil((consumption * roomSurface * workingSteps) / packSize);
};

export const CalculatePopover: React.FC<{
  hideConsumption: boolean;
  workingSteps?: number;
  consumptionPerLm?: number;
  consumptionPerSqm?: number;
  sqm?: number;
  lm?: number;
  packSize?: number;
  onCalculate: (quantity: number) => void;
}> = ({ workingSteps, consumptionPerLm, consumptionPerSqm, sqm, lm, onCalculate, packSize, hideConsumption }) => {
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      consumptionPerLm: consumptionPerLm || 0,
      workingSteps: workingSteps || 1,
      consumptionPerSqm: consumptionPerSqm || 0,
      sqm: sqm || 0,
      lm: lm || 0,
      packSize: packSize || 1,
    },
    onSubmit: val => {
      onCalculate(
        computeQuantity(
          val.consumptionPerSqm || val.consumptionPerLm,
          val.workingSteps,
          val.sqm || val.lm,
          val.packSize,
        ),
      );
      setAnchorEl(null);
    },
  });
  const nothingToCalculate = (workingSteps || consumptionPerLm || consumptionPerSqm) == null;

  return nothingToCalculate ? null : (
    <>
      <Tooltip title={t('product.calculateQuantity') as string}>
        <IconButton sx={{ padding: 0, marginLeft: -1 }} onClick={e => setAnchorEl(e.currentTarget)}>
          <Calculate />
        </IconButton>
      </Tooltip>

      {anchorEl && (
        <Popover anchorEl={anchorEl} open={!!anchorEl} onClose={() => setAnchorEl(null)}>
          <form onSubmit={formik.handleSubmit}>
            <Stack sx={{ width: '100%', padding: 2 }} spacing={1}>
              {workingSteps != null && (
                <TextField
                  type="number"
                  value={formik.values.workingSteps}
                  label={t('orders.workingSteps')}
                  size="small"
                  name="workingSteps"
                  fullWidth
                  onChange={formik.handleChange}
                />
              )}

              {consumptionPerLm != null && !hideConsumption && (
                <TextField
                  onChange={formik.handleChange}
                  value={formik.values.consumptionPerLm}
                  type="number"
                  label={t('orders.consumptionLm')}
                  name="consumptionPerLm"
                  size="small"
                  fullWidth
                />
              )}

              {consumptionPerLm != null && (
                <TextField
                  onChange={formik.handleChange}
                  value={formik.values.lm}
                  type="number"
                  label={t('orders.lm')}
                  name="lm"
                  size="small"
                  fullWidth
                />
              )}

              {consumptionPerSqm != null && !hideConsumption && (
                <TextField
                  name="consumptionPerSqm"
                  onChange={formik.handleChange}
                  value={formik.values.consumptionPerSqm}
                  type="number"
                  label={t('orders.consumptionSqm')}
                  size="small"
                  fullWidth
                />
              )}

              {consumptionPerSqm != null && (
                <TextField
                  name="sqm"
                  onChange={formik.handleChange}
                  value={formik.values.sqm}
                  type="number"
                  label={t('orders.sqm')}
                  size="small"
                  fullWidth
                />
              )}
              <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                <Button onClick={() => setAnchorEl(null)} variant="contained">
                  {t('common.cancel')}
                </Button>
                <Button variant="contained" type="submit">
                  {t('product.calculate')}
                </Button>
              </Box>
            </Stack>
          </form>
        </Popover>
      )}
    </>
  );
};
