import styled from 'styled-components';
import { ProductListRow } from '../productListRow/ProductListRow.styles';

export const ProductListContainer = styled.div``;

export const ProductListHeaderRow = styled.div`
  display: flex;
  border-bottom: 1px solid ${p => p.theme.palette.divider};
  font-weight: 500;
  font-size: 14px;
  padding: ${p => p.theme.spacing(2)} ${p => p.theme.spacing(1)};
  ${p => p.theme.breakpoints.down('md')} {
    display: none;
  }
`;

export const ProductListHeaderCell = styled.div<{ $maxWidth?: string }>`
  flex: 1;
  max-width: ${p => p.$maxWidth || 'unset'};
`;

export const AddProductContainer = styled.div`
  margin: ${p => p.theme.spacing(2)};
  text-align: center;
`;

export const TotalContainer = styled(ProductListRow)`
  border-top: 3px solid ${p => p.theme.palette.primary.main};
  border-bottom: none;
`;
