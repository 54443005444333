import { Edit } from '@mui/icons-material';
import { IconButton } from '@mui/material';
import Tabs from '@mui/material/Tabs/Tabs';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useIsTablet } from '../../../hooks/useIsMobile';
import { useProjectsStore } from '../../../stores/projectsStore';
import { Dropdown } from '../../core/dropdown/Dropdown';
import { OrdersGrid } from '../ordersGrid/OrdersGrid';
import {
  ContactInfoContainer,
  NoProjects,
  ProjectDetail,
  ProjectDetailsContainer,
  ProjectOrdersContainer,
  ProjectsContainer,
  ProjectTab,
  ProjectTitle,
  ProjectView,
} from './MyProjects.styles';
import { ProjectEditorDialog } from './ProjectEditorDialog';
import { formatAddress } from '../../../services/utils/format';

export const MyProjects: React.FC = () => {
  const { t } = useTranslation();
  const projects = useProjectsStore(s => s.projects);
  const [activeTab, setActiveTab] = useState(projects[0]?.id);
  const activeProject = projects.find(p => p.id === activeTab);

  const isTablet = useIsTablet();

  const [isProjectsEditorOpen, setIsProjectsEditorOpen] = useState(false);

  return !!projects.length ? (
    <ProjectsContainer>
      {isTablet ? (
        <Dropdown
          sx={{ marginBottom: 2 }}
          fullWidth
          options={projects.map(p => ({ label: p.name, value: p.id! }))}
          value={activeTab}
          onChange={v => setActiveTab(+v.target.value)}
        />
      ) : (
        <Tabs
          orientation={'vertical'}
          indicatorColor="secondary"
          value={activeTab}
          onChange={(_, tab) => setActiveTab(tab)}
        >
          {projects.map(p => (
            <ProjectTab key={p.id} value={p.id} label={p.name} />
          ))}
        </Tabs>
      )}

      {activeProject && (
        <ProjectView>
          <ProjectDetailsContainer>
            <div>
              <ProjectTitle>
                {activeProject.name}
                <IconButton size="small" sx={{ marginLeft: 2 }} onClick={() => setIsProjectsEditorOpen(true)}>
                  <Edit fontSize="small" />
                </IconButton>
              </ProjectTitle>
              <ProjectDetail>{activeProject.buildingType}</ProjectDetail>
              <ProjectDetail>{formatAddress(activeProject.address)}</ProjectDetail>
            </div>
            <ContactInfoContainer>
              <div>
                <ProjectDetail>
                  {activeProject.contactFirstName} {activeProject.contactLastName}
                </ProjectDetail>
                <ProjectDetail>{activeProject.contactEmail}</ProjectDetail>
                <ProjectDetail>{activeProject.phoneNumber}</ProjectDetail>
              </div>
            </ContactInfoContainer>
          </ProjectDetailsContainer>

          <ProjectOrdersContainer>
            <ProjectTitle>{t('common.orders')}</ProjectTitle>
            <OrdersGrid projectId={activeProject.id} />
          </ProjectOrdersContainer>
        </ProjectView>
      )}
      {isProjectsEditorOpen && activeProject && (
        <ProjectEditorDialog project={activeProject} onClose={() => setIsProjectsEditorOpen(false)} />
      )}
    </ProjectsContainer>
  ) : (
    <NoProjects>{t('project.noProjectsYet')}</NoProjects>
  );
};
