import { Backdrop, CircularProgress } from '@mui/material';
import { Outlet } from 'react-router-dom';
import { useCompaniesStore } from '../../../stores/companiesStore';
import { useUserStore } from '../../../stores/userStore';
import { Greeter } from '../../home/greeter/Greeter';
import { Navigation } from '../../navigation/Navigation';
import { PageContainer, PageContent } from './Page.styles';
import { useAppStore } from '../../../stores/appStore';
import shallow from 'zustand/shallow';

export const Page: React.FC = () => {
  const companiesLoaded = useCompaniesStore(s => s.companiesLoaded);
  const userInfoLoaded = useUserStore(s => s.userInfoLoaded);
  const [isTraining, isDraftMode] = useAppStore(s => [s.isTraining, s.isInDraftMode], shallow);

  const appProps = { isTraining, isDraftMode };

  return !companiesLoaded || !userInfoLoaded ? (
    <Backdrop open>
      <CircularProgress color="secondary" />
    </Backdrop>
  ) : (
    <PageContainer>
      <Greeter />
      <Navigation />
      <PageContent {...appProps}>
        <Outlet />
      </PageContent>
    </PageContainer>
  );
};
