import { useTranslation } from 'react-i18next';
import confirm from '../../../../services/confirm/confirm';
import { useOrderWizardStore } from '../../../../stores/orderWizardStore';
import { Button } from '../../../core/button/Button';

export const EditOrderButton: React.FC = () => {
  const saveOrder = useOrderWizardStore(s => s.saveOrder);
  const { t } = useTranslation();

  return (
    <Button
      onClick={async () => {
        if (
          await confirm(t('orders.editSubmittedOrderWarning'), {
            checkboxConfirmationMessage: t('orders.confirmReopenOrder'),
          })
        )
          saveOrder('Open');
      }}
    >
      {t('orders.editOrder')}
    </Button>
  );
};
