import { Link } from '@mui/material';
import styled from 'styled-components';

export const ConfirmedContainer = styled.div`
  display: flex;
  align-items: center;
`;

export const ConfirmedInfoContainer = styled(Link)`
  flex: 1;
`;
