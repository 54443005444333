import { AxiosRequestConfig, AxiosResponse, AxiosError, AxiosInstance } from 'axios';
import { t } from 'i18next';
import { useNotificationStore } from '../../stores/notificationStore';
import { getAuthToken } from './auth';
import { useAppStore } from '../../stores/appStore';
import appHistory from '../history/history';

const requestInterceptor = async (config: AxiosRequestConfig) => {
  const authToken = await getAuthToken();
  const isInDraftMode = useAppStore.getState().isInDraftMode;

  let draftModeHeaders = {};

  if (isInDraftMode) {
    draftModeHeaders = { 'Baswa-Oc-Is-Draft-Mode': true };
  }

  if (authToken) {
    config.headers = { ...config.headers, Authorization: `Bearer ${authToken}`, ...draftModeHeaders };
  }

  return config;
};

const requestErrorInterceptor = (error: any) => {
  // create error notification
  Promise.reject(error);
};

const responseInterceptor = (response: AxiosResponse) => response;

const responseErrorInterceptor = (error: AxiosError) => {
  if (error && error.response && (error.response.status === 401 || error.response.status === 403)) {
    appHistory.push('/unauthorized');
  }

  const details = error.response?.data.errors?.[0]?.uiMessage;

  useNotificationStore.getState().addNotification({
    color: 'error',
    message: t('common.somethingWentWrong'),
    persist: !!details,
    details: details,
  });

  return Promise.reject(error);
};

const registerAuthInterceptor = (http: AxiosInstance) => {
  http.interceptors.request.use(requestInterceptor, requestErrorInterceptor);
  http.interceptors.response.use(responseInterceptor, responseErrorInterceptor);

  return http;
};

export default registerAuthInterceptor;
