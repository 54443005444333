import styled from 'styled-components';

export const ProductListRow = styled.form<{ noBorder?: boolean }>`
  display: flex;
  padding: ${p => p.theme.spacing(1)};
  align-items: flex-start;
  &:not(:first-child) {
    border-top: ${p => (p.noBorder ? 'none' : `1px solid ${p.theme.palette.divider}`)};
  }
  ${p => p.theme.breakpoints.down('md')} {
    flex-direction: column;
    &:not(:first-child) {
      border-top: ${p => (p.noBorder ? 'none' : `1px solid ${p.theme.palette.divider}`)};
    }
  }
`;

export const NoColorWarning = styled.div`
  color: ${p => p.theme.palette.error.main};
`;

interface ProductListCellProps {
  $maxWidth?: string;
  justifyContent?: string;
  direction?: 'row' | 'column';
  alignItems?: string;
}

export const UnitOfMeasureContainer = styled.div`
  white-space: nowrap;

  margin: 0 ${p => p.theme.spacing(1)};
  ${p => p.theme.breakpoints.down('md')} {
    margin-right: 0;
  }
  display: flex;
  align-items: flex-end;
  color: darkgray;
  font-size: 0.75rem;
  min-width: 25px;
  padding-bottom: 2px;
`;

export const ProductListCell = styled.div<ProductListCellProps>`
  flex: 1;
  flex-direction: ${p => p.direction || 'row'};
  align-items: ${p => p.alignItems || 'initial'};
  max-width: ${p => p.$maxWidth || 'unset'};
  padding-right: ${p => p.theme.spacing(1)};
  min-width: 0;
  display: flex;
  justify-content: ${p => p.justifyContent || 'left'};
  ${p => p.theme.breakpoints.down('md')} {
    width: 100%;
    margin-bottom: ${p => p.theme.spacing(1)};
    max-width: unset;
  }
`;

export const ConfirmationContainer = styled.div`
  padding-bottom: ${p => p.theme.spacing(2)};
`;

export const ProductQuantityWrapper = styled.div`
  ${p => p.theme.breakpoints.down('md')} {
    margin-left: auto;
  }
`;

export const ProductSelectItemContainer = styled.div`
  display: flex;
  width: 100%;

  & > * {
    padding: ${p => p.theme.spacing(0.5)};
  }
`;

export const ProductCode = styled.div`
  font-size: 0.7rem;
`;

export const ProductDescription = styled.div`
  white-space: break-spaces;
`;

export const ConfigurationContainer = styled.div``;

export const ConfigurationEntryContainer = styled.div``;
