import create from 'zustand';
import { AppLanguage, dateLocales } from '../constants/languages';
import i18n from '../services/i18n/i18n';
import { getAuthenticationInfo } from '../services/auth/auth';
import jwtDecode from 'jwt-decode';
import { useProductsStore } from './productStore';
import { msalInstance } from '../services/msal/msalInstance';
import { useCompaniesStore } from './companiesStore';
import { getAuthRedirectURL } from '../services/environment/environment';
import { setDefaultOptions } from 'date-fns';

const userPreferencesKey = 'userPreferences';

export const defaultUserPreferences = JSON.parse(localStorage.getItem(userPreferencesKey) || '{}');

const defaultLanguage: AppLanguage = 'EN';

const language: AppLanguage = defaultUserPreferences.language || defaultLanguage;

i18n.changeLanguage(language);

setDefaultOptions({ locale: dateLocales[language] });

interface UserStoreValues {
  roles: string[];
  name?: string;
  username?: string;
  userInfoLoaded: boolean;
  isAdmin: boolean;
  isSales: boolean;
}

interface UserStoreActions {
  language: AppLanguage;
  setLanguage: (language: AppLanguage) => void;
  loadUserInfo: () => void;
  logout: () => void;
}

const defaultUserValues: UserStoreValues = {
  roles: [],
  name: '',
  username: '',
  isAdmin: false,
  isSales: false,
  userInfoLoaded: false,
};

export const useUserStore = create<UserStoreActions & UserStoreValues>(set => ({
  language,
  ...defaultUserValues,
  loadUserInfo: () => {
    getAuthenticationInfo().then(info => {
      const decoded = jwtDecode(info.accessToken) as any;
      set({
        roles: decoded.roles,
        isAdmin: decoded.roles.includes('admin'),
        isSales: decoded.roles.includes('sales'),
        name: info.account?.name,
        username: info.account?.username,
        userInfoLoaded: true,
      });
    });
  },
  setLanguage: language => {
    i18n.changeLanguage(language);
    localStorage.setItem(userPreferencesKey, JSON.stringify({ language }));
    setDefaultOptions({ locale: dateLocales[language] });
    set({ language });
    const selectedCompany = useCompaniesStore.getState().selectedCompany;
    if (selectedCompany) {
      useProductsStore.getState().loadProducts(selectedCompany.companyId);
    }
  },
  logout: () => {
    const account = msalInstance.getActiveAccount();
    msalInstance.logoutPopup({ account }).then(() => {
      window.location.href = getAuthRedirectURL() || window.location.href;
    });
  },
}));
