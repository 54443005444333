import Alert from '@mui/material/Alert';
import Snackbar from '@mui/material/Snackbar';
import * as React from 'react';
import shallow from 'zustand/shallow';
import { useNotificationStore } from '../../../stores/notificationStore';
import { Button } from '@mui/material';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

const Notification: React.FunctionComponent = () => {
  const [notification, removeNotification] = useNotificationStore(s => [s.notification, s.removeNotification], shallow);

  const [detailsOpen, setDetailsOpen] = useState(false);

  const { t } = useTranslation();

  return (
    <Snackbar
      autoHideDuration={notification?.persist ? undefined : 6000}
      anchorOrigin={{ horizontal: 'center', vertical: 'top' }}
      open={!!notification}
      onClose={removeNotification}
    >
      <Alert onClose={removeNotification} severity={notification?.color} sx={{ width: '100%' }}>
        <div>{notification?.message}</div>
        {notification?.details && (
          <>
            <Button onClick={() => setDetailsOpen(!detailsOpen)} sx={{ padding: 0, marginTop: 1 }} variant="text">
              {detailsOpen ? t('common.hideErrorDetails') : t('common.seeErrorDetails')}
            </Button>
            {detailsOpen && <div>{notification?.details}</div>}
          </>
        )}
      </Alert>
    </Snackbar>
  );
};

export default Notification;
