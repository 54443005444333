import { Box, CircularProgress } from '@mui/material';

export const CircularLoader: React.FC<{ isLoading: boolean }> = ({ isLoading, children }) => {
  return (
    <>
      {isLoading ? (
        <Box sx={{ minHeight: 100, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <CircularProgress />
        </Box>
      ) : (
        children
      )}
    </>
  );
};
