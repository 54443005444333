import { Product } from '../../../../../../models/Product';
import { ProductCode, ProductDescription, ProductSelectItemContainer } from './ProductListRow.styles';

export const ProductSelectItem: React.FC<{ product: Product; noPicture?: boolean }> = ({ product, noPicture }) => {
  return (
    <ProductSelectItemContainer>
      {!noPicture && (
        <div>
         {product.thumbnail && <img
            width="70px"
            alt="Product"
            src={product.thumbnail}
          />}
        </div>
      )}

      <div>
        <ProductCode>{product.itemNo}</ProductCode>
        <ProductDescription>{product.name}</ProductDescription>
        <ProductDescription>{product.description}</ProductDescription>
      </div>
    </ProductSelectItemContainer>
  );
};
