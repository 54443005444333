import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import { OrderWizardPageContainer, OrderWizardStepContainer } from './OrderWizardPage.styles';
import { useTranslation } from 'react-i18next';

import { useOrderWizardStore } from '../../stores/orderWizardStore';
import { Outlet, useParams } from 'react-router-dom';
import shallow from 'zustand/shallow';
import { useEffect } from 'react';
import { useProductsStore } from '../../stores/productStore';
import { useCompaniesStore } from '../../stores/companiesStore';
import { LinearLoader } from '../core/loaders/LinearLoader';
import { Box } from '@mui/material';
import { useIsTablet } from '../../hooks/useIsMobile';

export const OrderWizardPage: React.FC = () => {
  const { t } = useTranslation();

  const { orderId } = useParams();

  const isTablet = useIsTablet();

  const [steps, activeStepIndex, loadOrder, resetWizardStore, isLoadingOrder] = useOrderWizardStore(
    s => [s.steps, s.activeStep, s.loadOrder, s.reset, s.isLoadingOrder],
    shallow,
  );

  const [loadProducts, productsLoaded] = useProductsStore(s => [s.loadProducts, s.productsLoaded], shallow);

  const [currentCompany, companiesLoaded] = useCompaniesStore(s => [s.selectedCompany, s.companiesLoaded], shallow);

  useEffect(() => {
    if (currentCompany) {
      loadProducts(currentCompany.companyId);
    }
  }, [loadProducts, currentCompany]);

  useEffect(() => () => resetWizardStore(), [resetWizardStore]);

  useEffect(() => {
    if (orderId) {
      loadOrder(+orderId);
    }
  }, [orderId, loadOrder]);

  return (
    <OrderWizardPageContainer>
      <Stepper activeStep={activeStepIndex}>
        {steps.map((step, index) => (
          <Step key={index}>
            <StepLabel>{isTablet ? '' : t(`orderWizard.${step.name}.title`)}</StepLabel>
          </Step>
        ))}
      </Stepper>
      {isTablet && activeStepIndex > -1 && (
        <Box sx={{ textAlign: 'center', marginTop: 1 }}>{t(`orderWizard.${steps[activeStepIndex].name}.title`)}</Box>
      )}

      <OrderWizardStepContainer>
        {!currentCompany && companiesLoaded && !isLoadingOrder ? (
          <Box sx={{ textAlign: 'center' }}>{t('customer.noCustomerSelected')}</Box>
        ) : (
          <LinearLoader isLoading={!productsLoaded}>
            <Outlet />
          </LinearLoader>
        )}
      </OrderWizardStepContainer>
    </OrderWizardPageContainer>
  );
};
