import { ArrowBack } from '@mui/icons-material';
import { Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import shallow from 'zustand/shallow';
import { tintTypes } from '../../../../../constants/tintTypes';
import { OrderProduct } from '../../../../../models/OrderProduct';
import { getOrder } from '../../../../../services/http/orders';
import { useCompaniesStore } from '../../../../../stores/companiesStore';
import { useOrdersStore } from '../../../../../stores/ordersStore';
import { useOrderWizardStore } from '../../../../../stores/orderWizardStore';
import { useProductsStore } from '../../../../../stores/productStore';
import { Button } from '../../../../core/button/Button';
import { OrdersGrid } from '../../../../home/ordersGrid/OrdersGrid';
import ProductList from '../products/productList/ProductList';

export const ImportProductsDialog: React.FC<{
  onClose: () => void;
  roomUiId?: string;
}> = ({ onClose, roomUiId }) => {
  const { t } = useTranslation();

  const [orderId, setOrderId] = useState<number>();

  const selectedCompany = useCompaniesStore(s => s.selectedCompany);

  const [products, setProducts] = useState<OrderProduct[]>([]);

  const [selectedProducts, setSelectedProducts] = useState<OrderProduct[]>([]);

  const [loadOrders] = useOrdersStore(s => [s.loadOrders]);

  const [addProduct, order, saveOrder] = useOrderWizardStore(s => [s.addProduct, s.order, s.saveOrder], shallow);

  useEffect(() => {
    loadOrders(selectedCompany?.companyId);
  }, [loadOrders, selectedCompany?.companyId]);

  const productsMap = useProductsStore(s => s.productsMap);

  useEffect(() => {
    if (orderId) {
      getOrder(orderId).then(o => setProducts(o.products));
    }
  }, [orderId]);

  const handleSelectProduct = useCallback(
    (orderProduct: OrderProduct) => {
      if (selectedProducts.includes(orderProduct)) {
        setSelectedProducts(selectedProducts.filter(p => p !== orderProduct));
      } else {
        setSelectedProducts([...selectedProducts, orderProduct]);
      }
    },
    [selectedProducts],
  );

  const handleImportProducts = () => {
    selectedProducts.forEach(p => {
      const product = productsMap[p.productId];
      addProduct({
        ...p,
        roomUiId: roomUiId,
        orderId: order.id,
        id: undefined,
        roomId: undefined,
        price: productsMap[p.productId]?.price,
        colorCode: product.tinted ? '' : undefined,
        colorAgreementUrl: product.tinted ? '' : undefined,
        tintType: product.tinted ? tintTypes.tintedWithConfirmation : undefined,
        isTintWithoutConfirmationApproved: undefined,
      });
    });
    onClose();
    saveOrder();
  };

  return (
    <Dialog fullScreen open>
      <DialogTitle>{t('orders.importDialogTitle')}</DialogTitle>
      <DialogContent>
        {!orderId && <OrdersGrid excludedOrderId={order.id} onImportClick={setOrderId} />}
        {orderId && (
          <div>
            <Button
              onClick={() => {
                setOrderId(undefined);
                setProducts([]);
                setSelectedProducts([]);
              }}
              startIcon={<ArrowBack />}
            >
              {t('common.goBack')}
            </Button>
            <ProductList showAllProducts onSelect={handleSelectProduct} products={products} />
          </div>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>{t('common.cancel')}</Button>
        <Button disabled={!selectedProducts.length} onClick={handleImportProducts}>
          {t('orders.import')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
